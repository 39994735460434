import styled from "@emotion/styled";
import { Button } from "antd";
import { colors } from "@unlikelyai-magic/ui/variables";
import { buttonStyles } from "../../styles";
import { ButtonProps } from "../../types";

export type TransparentButtonProps = ButtonProps;

export const TransparentButton = styled(
  ({ size, ...props }: TransparentButtonProps) => (
    <Button {...props} type="text" />
  )
)`
  ${({ size = "md" }) => buttonStyles[size]};
  background: none !important;

  &,
  &:hover,
  &:active,
  &:disabled,
  &:disabled:hover,
  &.ant-btn-loading {
    color: ${colors.gray["900"]};
  }
`;
