import { css } from "@emotion/react";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";

export const cardPaddingStyles = css`
  padding: 1rem;

  @media (min-width: ${screenBreakpoints.sm}) {
    padding: 1.5rem;
  }
`;
