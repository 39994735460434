import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";
import indefinite from "indefinite";
import {
  ChatProvider,
  MessageAuthor,
  YesNoChatWidget,
  useChatMessages,
} from "@jobe/ui/features/chat";
import {
  JobRequirementsWidget,
  JobSpecificationWidget,
  SelectExperienceWidget,
  SelectLocationWidget,
  SelectRoleWidget,
} from "../components";
import { JobPostingFormData } from "../types";

export interface CreateJobOpeningChatContext {
  formData: JobPostingFormData;
  setFormData: Dispatch<SetStateAction<JobPostingFormData>>;
  onSubmit: () => Promise<void>;
}

const defaultChatContext: CreateJobOpeningChatContext = {
  formData: {
    role: undefined,
    requirements: [],
    requirementSpecTextId: "",
  },
  setFormData: () => null,
  onSubmit: () => Promise.resolve(),
};

export const CreateJobOpeningChatContext =
  createContext<CreateJobOpeningChatContext>(defaultChatContext);

export interface CreateJobOpeningChatProviderProps {
  onSubmit: (formData: JobPostingFormData) => Promise<void>;
  children?: ReactNode;
}

export const CreateJobOpeningChatProvider = ({
  onSubmit,
  children,
}: CreateJobOpeningChatProviderProps) => {
  const state = useChatMessages();
  const [formData, setFormData] = useState<JobPostingFormData>(
    defaultChatContext.formData
  );

  useEffect(() => {
    // Timeout is here because in React strict mode, hooks are executed twice
    // The timeout ensures this is only executed once
    const timeout = setTimeout(() => {
      state.pushMessage({
        author: MessageAuthor.User,
        text: "I want to create a job opening.",
      });
      state.pushMessage({
        author: MessageAuthor.Bot,
        text: "Let's create your new opening. What role are you looking to hire for?",
        widget: <SelectRoleWidget />,
      });
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (formData.role === undefined) return;
    state.pushMessage({
      author: MessageAuthor.User,
      text: formData.role
        ? `I'm looking to hire ${indefinite(formData.role || "")}.`
        : "I know what I'm looking for.",
    });
    state.pushMessage({
      author: MessageAuthor.Bot,
      text: formData.role
        ? `Where should the ${formData.role} candidates be based?`
        : "Tell me your job requirements",
      widget: formData.role ? (
        <SelectLocationWidget />
      ) : (
        <JobRequirementsWidget />
      ),
    });
  }, [formData.role]);

  useEffect(() => {
    if (formData.role === undefined) return;
    if (formData.location === undefined) return;
    state.pushMessage({
      author: MessageAuthor.User,
      text: formData.location
        ? `The candidate should be based in ${formData.location}.`
        : "Any location is OK.",
    });
    state.pushMessage({
      author: MessageAuthor.Bot,
      text: "Great, do you already have a job specification written?",
      widget: (
        <YesNoChatWidget
          onSubmit={(value) => {
            state.pushMessage({
              author: MessageAuthor.User,
              text: value ? "Yes" : "No",
            });
            if (!value) {
              state.pushMessage({
                author: MessageAuthor.Bot,
                text: "No problem, we can create one together. What level of experience are you looking for?",
                widget: <SelectExperienceWidget />,
              });
            } else {
              state.pushMessage({
                author: MessageAuthor.Bot,
                text: "Great! Please paste your job specification below",
                widget: <JobSpecificationWidget />,
              });
            }
          }}
        />
      ),
    });
  }, [formData.location]);

  const handleSubmit = async (): Promise<void> => {
    return onSubmit(formData);
  };

  return (
    <CreateJobOpeningChatContext.Provider
      value={{
        formData,
        setFormData,
        onSubmit: handleSubmit,
      }}
    >
      <ChatProvider state={state}>{children}</ChatProvider>
    </CreateJobOpeningChatContext.Provider>
  );
};
