import { useContext } from "react";
import { SlideOverContext } from "./SlideOverProvider";

export const useSlideOver = () => {
  const context = useContext(SlideOverContext);
  if (context === undefined) {
    throw new Error("useSlideOver must be used within a SlideOverContext");
  }
  return context;
};
