import loki from "lokijs";

export type MockDatabaseOptions = {
  name?: string;
  persistenceMethod?: LokiConfigOptions["persistenceMethod"];
  env?: LokiConstructorOptions["env"];
};

/**
 * A database for storing and retrieving mock data for the MSW-based request interceptor.
 * It is based on LokiJS, a lightweight in-memory database, that uses a NoSQL-like syntax.
 * This database can also be used during testing.
 *
 * @example
 * const usersCollection = mocksDatabase.addCollection("users")
 * usersCollection.insert({ name: "Odysseus", age: 32 })
 */
export const createMockDatabase = (options?: MockDatabaseOptions) => {
  const name = options?.name ?? "mocks.db";
  const mergedOptions: MockDatabaseOptions = {
    persistenceMethod: "memory",
    env: "BROWSER",
    ...options,
  };
  return new loki(name, mergedOptions);
};
