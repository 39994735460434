import React, {
  ComponentProps,
  LegacyRef,
  forwardRef,
  useEffect,
  useState,
} from "react";
import styled from "@emotion/styled";
import { Avatar as AntdAvatar } from "antd";

export type AvatarSize = "xs" | "sm" | "md" | "lg";

export const avatarSizes: Record<AvatarSize, string> = {
  xs: "1.5rem",
  sm: "2.5rem",
  md: "3.5rem",
  lg: "4.5rem",
};

type InitialsAvatarProps = Omit<ComponentProps<typeof AntdAvatar>, "size"> & {
  alt: string;
  size: string;
};

type ImageAvatarProps = ComponentProps<"img"> & {
  alt: string;
  size: string;
  bordered?: boolean;
};

type AvatarProps = Omit<InitialsAvatarProps, "size"> &
  Omit<ImageAvatarProps, "size"> & { size: AvatarSize };

const InitialsAvatar = styled(AntdAvatar)`
  flex-shrink: 0;
  user-select: none;
`;

const BorderedInitialsAvatar = styled(
  forwardRef(({ alt, size, ...props }: InitialsAvatarProps, ref) => {
    return (
      <AntdAvatar {...props} ref={ref as LegacyRef<HTMLSpanElement>}>
        {alt}
      </AntdAvatar>
    );
  })
)`
  flex-shrink: 0;
  user-select: none;
  border: 0.125rem solid white;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  padding: 0.125rem;
`;

const ImageAvatar = styled(
  forwardRef(({ alt, size, bordered, ...props }: ImageAvatarProps, ref) => {
    return (
      <img
        {...props}
        alt={alt}
        ref={ref as LegacyRef<HTMLImageElement>}
        referrerPolicy="no-referrer"
      />
    );
  })
)`
  flex-shrink: 0;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
  object-fit: cover;
  border: ${({ bordered }) => (bordered ? "0.125rem solid white" : "inherit")};
`;

/**
 * A component that displays an avatar for a match.
 * If no src is given, it defaults to a grey circle containing alt text
 * @param src : string - The source of the image, if undefined it defaults to using alt text
 * @param size : AvatarSize - The size of the avatar
 * @param bordered : boolean - Whether the avatar should have a border
 * @param props - Any other props to pass to the avatar
 */
export const Avatar = forwardRef(
  ({ size, bordered, ...props }: AvatarProps, ref) => {
    const [fallback, setFallback] = useState<boolean>(!props.src);

    useEffect(() => {
      if (props.src && fallback) {
        setFallback(false);
      }
    }, [props.src]);

    if (fallback) {
      return bordered ? (
        <BorderedInitialsAvatar {...props} size={avatarSizes[size]} ref={ref} />
      ) : (
        <InitialsAvatar
          {...props}
          size={parseFloat(avatarSizes[size]) * 16}
          ref={ref as LegacyRef<HTMLSpanElement>}
        >
          {props.alt}
        </InitialsAvatar>
      );
    }

    return (
      <ImageAvatar
        {...props}
        size={avatarSizes[size]}
        ref={ref}
        onError={() => setFallback(true)}
      />
    );
  }
);
