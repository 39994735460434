import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "antd";
import {
  buttonSizeStyles,
  buttonTextStyles,
} from "@unlikelyai-magic/ui/buttons";
import {
  LikedProfileIcon,
  NeutralProfileIcon,
  SeenProfileIcon,
} from "@unlikelyai-magic/ui/icons";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Input } from "@jobe/ui/inputs";
import { AppNavbar } from "@jobe/ui/navbars";
import { Paragraph } from "@jobe/ui/typography";
import { useJobPosting, useUpdateJobPostingMutation } from "../../job-postings";

const Links = styled(Spacing)`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const NavLinkButton = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  color: ${({ theme }) => theme.colors.text.tertiary};
  ${buttonTextStyles.xl};
  ${buttonSizeStyles.xl};
  border-radius: 1.5rem;
  padding: 0.675rem 1rem;
  white-space: nowrap;
  transition: background-color 0.2s ease-in-out;

  &.active,
  &:focus,
  &:hover {
    background: ${({ theme }) => theme.colors.background.primary};
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const JobOpeningNameInput = styled(Input)`
  border-radius: 1rem;
  font-size: ${({ theme }) => theme.fontSizes.md};
  line-height: ${({ theme }) => theme.fontSizes.xxl};
  font-weight: 600;
  padding-left: 2.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const TitleInputContainer = styled.div`
  position: relative;
  width: fit-content;
`;

const InputIconContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
  font-size: 1rem;
  width: 1em;
  z-index: 1;
  pointer-events: none;
`;

const EditIcon = styled(Icon)<{ isFocused: boolean }>`
  color: ${({ theme, isFocused }) =>
    isFocused ? theme.colors.brand.primary : theme.colors.text.primary};
`;

export const DiscoverMatchesNavbar = () => {
  const { jobPosting } = useJobPosting();
  const [title, setTitle] = useState(jobPosting?.name || "");
  const [isTextInputFocused, setIsTextInputFocused] = useState(false);
  const [updateJobPosting] = useUpdateJobPostingMutation();

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        isTextInputFocused &&
        (event.key === "ArrowLeft" || event.key === "ArrowRight")
      ) {
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, true);

    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [isTextInputFocused]);

  const handleSaveName = () => {
    setIsTextInputFocused(false);
    if (!title || !jobPosting || title === jobPosting?.name) return;
    updateJobPosting({ id: jobPosting.id, name: title });
  };

  if (!jobPosting) return null;

  return (
    <AppNavbar
      leftContent={
        <TitleInputContainer>
          <InputIconContainer>
            <EditIcon icon={PencilIcon} isFocused={isTextInputFocused} />
          </InputIconContainer>
          <Tooltip title={title}>
            <JobOpeningNameInput
              value={title}
              onChange={(event) => setTitle(event.target.value)}
              placeholder="Enter opening name..."
              onFocus={() => setIsTextInputFocused(true)}
              onBlur={handleSaveName}
              onPressEnter={handleSaveName}
            />
          </Tooltip>
        </TitleInputContainer>
      }
      centerContent={
        <Links gap="xs" direction="horizontal">
          <NavLinkButton to="matches">
            <NeutralProfileIcon />
            <Paragraph>Matches</Paragraph>
          </NavLinkButton>
          <NavLinkButton to="seen">
            <SeenProfileIcon />
            <Paragraph>Seen</Paragraph>
          </NavLinkButton>
          <NavLinkButton to="likes">
            <LikedProfileIcon />
            <Paragraph>Liked</Paragraph>
          </NavLinkButton>
        </Links>
      }
    />
  );
};
