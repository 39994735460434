import { ComponentProps } from "react";
import styled from "@emotion/styled";

type StarsProps = ComponentProps<"svg"> & { size?: string };

export const StarsNoBackground = styled(
  ({ size = "1rem", ...props }: StarsProps) => {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M1.92032 10.4454C4.97454 10.4454 7.45047 7.95855 7.45047 4.89083C7.45047 7.95855 9.92639 10.4454 12.9806 10.4454C9.92639 10.4454 7.45047 12.9323 7.45047 16C7.45047 12.9323 4.97454 10.4454 1.92032 10.4454Z"
          fill="currentColor"
        />
        <path
          d="M0 5.62794C1.65143 5.10457 2.56819 3.33698 2.04765 1.67993C2.56819 3.33698 4.32893 4.25601 5.98036 3.73264C4.32893 4.25601 3.41216 6.0236 3.9327 7.68065C3.41216 6.0236 1.65143 5.10457 0 5.62794Z"
          fill="currentColor"
        />
        <path
          d="M7.86013 2.67171C9.97389 3.31712 12.2078 2.12095 12.8497 0C12.2078 2.12095 13.401 4.36353 15.5147 5.00894C13.401 4.36353 11.1671 5.55969 10.5251 7.68065C11.1671 5.55969 9.97389 3.31712 7.86013 2.67171Z"
          fill="currentColor"
        />
      </svg>
    );
  }
)``;
