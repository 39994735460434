import { ComponentProps } from "react";

export const LikedProfileIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2943_6773)">
        <path
          d="M0.96875 11.2812C0.969225 10.5838 1.14253 9.89733 1.47316 9.28324C1.80379 8.66915 2.28145 8.14657 2.86343 7.76222C3.44541 7.37787 4.11356 7.14372 4.80815 7.08073C5.50274 7.01773 6.20212 7.12785 6.84375 7.40123"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.60938 3.54688C2.60938 4.23064 2.881 4.88639 3.36449 5.36988C3.84798 5.85338 4.50374 6.125 5.1875 6.125C5.87126 6.125 6.52702 5.85338 7.01051 5.36988C7.494 4.88639 7.76563 4.23064 7.76562 3.54688C7.76563 2.86311 7.494 2.20736 7.01051 1.72387C6.52702 1.24037 5.87126 0.96875 5.1875 0.96875C4.50374 0.96875 3.84798 1.24037 3.36449 1.72387C2.881 2.20736 2.60938 2.86311 2.60938 3.54688Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.2814 15.0312L8.08519 11.6969C7.80454 11.4163 7.61929 11.0545 7.55569 10.6628C7.49209 10.2711 7.55336 9.86931 7.73081 9.51435C7.8648 9.24632 8.06057 9.01396 8.30197 8.83643C8.54338 8.65889 8.82351 8.54126 9.1193 8.49322C9.41508 8.44519 9.71804 8.46812 10.0032 8.56014C10.2884 8.65216 10.5476 8.81062 10.7596 9.02248L11.2814 9.54436L11.8033 9.02248C12.0152 8.81062 12.2744 8.65215 12.5595 8.56013C12.8446 8.46811 13.1476 8.44517 13.4433 8.49321C13.739 8.54125 14.0191 8.65889 14.2605 8.83643C14.5018 9.01397 14.6975 9.24633 14.8314 9.51435C15.0088 9.86929 15.07 10.271 15.0066 10.6627C14.9431 11.0543 14.7581 11.4161 14.4777 11.6969L11.2814 15.0312Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2943_6773">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
