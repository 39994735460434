import { SerializedStyles, css } from "@emotion/react";
import { ButtonSize } from "../types";

export const buttonSizeStyles: Record<ButtonSize, SerializedStyles> = {
  xs: css`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  `,
  sm: css`
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
  `,
  md: css`
    padding: 0.375rem 0.625rem;
    border-radius: 0.375rem;
  `,
  lg: css`
    padding: 0.5rem 0.75rem;
    border-radius: 0.375rem;
  `,
  xl: css`
    padding: 0.625rem 0.875rem;
    border-radius: 0.375rem;
  `,
};
