import { logos } from "@unlikelyai-magic/ui/illustrations";

const schools: Record<string, string> = {
  "University Of Oxford": logos.schools.oxford,
  "University Of Cambridge": logos.schools.cambridge,
  "Imperial College London": logos.schools.imperial,
  "Massachusetts Institute Of Technology": logos.schools.mit,
  "Stanford University": logos.schools.stanford,
  "Harvard University": logos.schools.harvard,
  "Yale University": logos.schools.yale,
  "Princeton University": logos.schools.princeton,
  "Columbia University": logos.schools.columbia,
  "University College London": logos.schools.ucl,
  UCL: logos.schools.ucl,
  "University Of Edinburgh": logos.schools.edinburgh,
  "University Of St Andrews": logos.schools.stAndrews,
  "University of St. Andrews": logos.schools.stAndrews,
  "Durham University": logos.schools.durham,
  "University Of California": logos.schools.ucBerkeley,
  "University Of Chicago": logos.schools.chicago,
};

/**
 * Resolves school logos for top 10 universities
 * @param schoolName
 */
export const resolveFallbackSchoolImage = (
  schoolName: string
): string | undefined => {
  // Check if any keys exist in the institution name
  const institution = schoolName?.toLowerCase() || "";
  const keys = Object.keys(schools);
  for (const key of keys) {
    if (institution.includes(key.toLowerCase())) {
      return schools[key];
    }
  }
  return undefined;
};
