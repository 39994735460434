import { createContext, useContext } from "react";
import { Dict } from "mixpanel-browser";
import { UIAction, UXFlow } from "../actions";

export interface MetricsContext {
  identify: (id?: string) => void;
  track: <F extends UXFlow, A extends UIAction<F>>(
    flow: F,
    action: A,
    properties?: Dict
  ) => void;
  people: { set: (props: Dict) => void };
}

export const Context = createContext<MetricsContext | undefined>(undefined);

export const useMetrics = (): MetricsContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useMetrics must be used within a MetricsProvider");
  }
  return context;
};
