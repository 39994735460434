import { useContext } from "react";
import { JobPostingProviderContext } from "../providers";

export const useJobPosting = (): JobPostingProviderContext => {
  const value = useContext<JobPostingProviderContext>(
    JobPostingProviderContext
  );
  if (!value)
    throw new Error("useJobPosting must be used within a JobPostingProvider");
  return value;
};
