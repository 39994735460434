import Selector, { Props, components } from "react-select";
import styled from "@emotion/styled";
import { ChevronDownIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { colors } from "@unlikelyai-magic/ui/variables";
import { inputStyles } from "../../styles/input.styles";

const Icon = styled.svg`
  width: 1.25em;
  height: 1.25em;
`;

const { ClearIndicator, DropdownIndicator } = components;

export type SelectOption = {
  label: string;
  value: string;
};

// Using `any` here because react-select types are not compatible emotion styled API.
type SelectProps<Value = any> = Omit<Props, "onChange"> & {
  onChange: (value: Value) => void;
};

export const Select = styled((props: SelectProps) => {
  return (
    <Selector
      {...props}
      classNamePrefix="react-select"
      components={{
        ClearIndicator: (props) => (
          <ClearIndicator {...props}>
            <Icon as={XMarkIcon} />
          </ClearIndicator>
        ),
        DropdownIndicator: (props) => (
          <DropdownIndicator {...props}>
            <Icon as={ChevronDownIcon} />
          </DropdownIndicator>
        ),
      }}
    />
  );
})`
  ${inputStyles.text};

  .react-select__control {
    ${inputStyles.border};
    min-height: unset;

    &.react-select__control--is-focused {
      ${inputStyles["border:focus"]};
    }
  }

  .react-select__placeholder {
    ${inputStyles.placeholder};
  }

  .react-select__value-container {
    padding: 0.1875rem 0.5rem;
  }

  .react-select__value-container--is-multi {
    padding: 0.1875rem 0.25rem;
    gap: 0.25rem;
  }

  .react-select__indicator {
    padding: 0.25rem;
    color: ${colors.gray["500"]};
  }

  .react-select__multi-value {
    border-radius: 0.25rem;
    background: ${colors.gray["100"]};
    margin: 0;
  }

  .react-select__multi-value__label {
    font-size: 1em;
  }

  .react-select__option--is-focused {
    background: ${colors.gray["100"]};
  }

  .react-select__option--is-selected {
    background: ${colors.primary};
    color: ${colors.white};
  }

  .react-select__indicator-separator {
    display: none;
  }
`;
