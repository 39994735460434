import { ReactElement } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { WaitlistStatus } from "@jobe/data-access/api-types";
import { useGetUserProfileQuery } from "@jobe/ui/features/users";
import { LoadingPage } from "@jobe/ui/loading";

/**
 * RestrictedWaitlistOnly will redirect the user to the waitlist page if the user is not approved.
 * @usage <Route path="/secure/route" element={<RestrictedWaitlistOnly />} />
 */
export const RestrictedWaitlistOnly = (): ReactElement => {
  const { data: userProfile, isFetching, error } = useGetUserProfileQuery();

  if (error) {
    return <Navigate replace to="/waitlist" />;
  }

  if (isFetching) {
    return <LoadingPage />;
  }

  return userProfile?.data.waitlistStatus === WaitlistStatus.Approved ? (
    <Outlet />
  ) : (
    <Navigate replace to="/waitlist" />
  );
};
