import styled from "@emotion/styled";
import { Button as AntdButton, ButtonProps as AntdButtonProps } from "antd";
import { buttonStyles } from "../../styles/button.styles";
import { ButtonProps } from "../../types/buttonProps.types";

export const Button = styled(
  ({
    size,
    type = "default",
    ...props
  }: ButtonProps & { type?: AntdButtonProps["type"] }) => (
    <AntdButton {...props} size="middle" type={type} />
  )
)`
  ${({ size = "md" }) => buttonStyles[size]};
  border-radius: 1.5rem;
  border: none;
  box-shadow: none;
  padding: 0.75rem 1rem;

  &.ant-btn-icon-only {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border-radius: 50%;
  }
`;
