import { logos } from "@unlikelyai-magic/ui/illustrations";

const companies: Record<string, string> = {
  Accenture: logos.companies.accenture,
  Amazon: logos.companies.amazon,
  Apple: logos.companies.apple,
  Facebook: logos.companies.facebook,
  Google: logos.companies.google,
  IBM: logos.companies.ibm,
  Microsoft: logos.companies.microsoft,
  Oracle: logos.companies.oracle,
  Salesforce: logos.companies.salesforce,
};

/**
 * Resolves company images for top tech companies
 * @param companyName
 */
export const resolveFallbackCompanyImage = (
  companyName: string
): string | undefined => {
  // Check if any keys exist in the institution name
  const institution = companyName?.toLowerCase() || "";
  const keys = Object.keys(companies);
  for (const key of keys) {
    if (institution.includes(key.toLowerCase())) {
      return companies[key];
    }
  }
  return undefined;
};
