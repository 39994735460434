// Note: entries are given in lowercase and without spaces, this is to help categorise skills when candidates have used unexpected syntax
export const languages = [
  "abap",
  "abcl",
  "awk",
  "actionscript",
  "ada",
  "algol",
  "alice",
  "apex",
  "assembly",
  "assemblylanguage",
  "basic",
  "bcpl",
  "beta",
  "bash",
  "c",
  "c#",
  "c++",
  "cobol",
  "ceylon",
  "chapel",
  "clipper",
  "clojure",
  "coffeescript",
  "coldfusion",
  "commonlisp",
  "concurrentpascal",
  "crystal",
  "css",
  "curl",
  "dart",
  "delphi",
  "eiffel",
  "elixir",
  "elm",
  "erlang",
  "f#",
  "f*",
  "factor",
  "fantom",
  "forth",
  "fortran",
  "foxpro",
  "glsl",
  "gml",
  "go",
  "golang",
  "groovy",
  "hlsl",
  "hack",
  "harbour",
  "haskell",
  "haxe",
  "html",
  "icon",
  "idris",
  "io",
  "j",
  "j#",
  "java",
  "javascript",
  "julia",
  "kajona",
  "kixtart",
  "kotlin",
  "linc",
  "lisa",
  "labview",
  "ladderlogic",
  "lasso",
  "limbo",
  "limnor",
  "linoleum",
  "lisp",
  "livescript",
  "logo",
  "lua",
  "m2001",
  "mad",
  "masmmicrosoftassemblyx86",
  "matlab",
  "mdl",
  "mivascript",
  "ml",
  "moo",
  "mpd",
  "msl",
  "mumps",
  "magik",
  "maple",
  "mary",
  "mathematica",
  "max/msp",
  "maxima",
  "maya(mel)",
  "mercury",
  "miranda",
  "model204",
  "modula",
  "modula-2",
  "modula-3",
  "mohol",
  "monkey",
  "mortran",
  "mouse",
  "natural",
  "nesl",
  "newp",
  "npl",
  "nsis",
  "nxt-g",
  "native",
  "nemerle",
  "net.data",
  "netlogo",
  "netrexx",
  "newlisp",
  "newspeak",
  "newtonscript",
  "nial",
  "nice",
  "nickle",
  "nim",
  "nu",
  "obj2",
  "ocaml",
  "opl",
  "ops5",
  "orca/modula-2",
  "oberon",
  "obix",
  "objectlisp",
  "objectpascal",
  "objectrexx",
  "objectlogo",
  "objective-c",
  "objective-vl",
  "obliq",
  "ocaml",
  "octave",
  "omnimark",
  "onyx",
  "opa",
  "opal",
  "opencl",
  "openedgeabl",
  "optimj",
  "orc",
  "oriel",
  "orwell",
  "oxygene",
  "oz",
  "p#",
  "pari/gp",
  "pcastl",
  "pcf",
  "pearl",
  "php",
  "pl/sql",
  "pascal",
  "perl",
  "pike",
  "postscript",
  "powershell",
  "pro*c",
  "progress4gl",
  "prolog",
  "puredata",
  "python",
  "q",
  "q#",
  "r",
  "rexx",
  "rpg(ibm)",
  "racket",
  "reasonml",
  "rexx",
  "ring",
  "ruby",
  "rust",
  "s-lang",
  "sas",
  "spark",
  "sql",
  "scala",
  "scheme",
  "scratch",
  "seed7",
  "shell",
  "simula",
  "smalltalk",
  "solidity",
  "squeak",
  "swift",
  "tcl",
  "transact-sql",
  "typescript",
  "unicon",
  "vba",
  "vhdl",
  "vala",
  "verilog",
  "visualbasic",
  "visualdataflex",
  "webdna",
  "wolframlanguage",
  "x++",
  "xquery",
  "xojo",
  "zpl",
  "zeno",
  "occam",
  "occam-π",
  "xbase++",
];
