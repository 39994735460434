import {
  GetUserProfileResponse,
  UpdateUserProfileRequest,
  UpdateUserProfileResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const userApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getUserProfile: build.query<GetUserProfileResponse, void>({
      query: () => ({
        url: "/user/profile",
      }),
    }),
    updateUserProfile: build.mutation<
      UpdateUserProfileResponse,
      UpdateUserProfileRequest
    >({
      query: (body) => ({
        url: "/user/profile",
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
  useLazyGetUserProfileQuery,
} = userApi;
