import styled from "@emotion/styled";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useJobPosting } from "../../job-postings";
import { RequirementLevel, Skill, categoriseSkills } from "../utils";

const SkillSectionContainer = styled(Spacing)`
  flex-wrap: wrap;
`;

const SkillHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.gray["500"]};
  font-weight: 400;
`;

const SkillCard = styled.div`
  background-color: ${({ theme }) => theme.colors.background.primary};
  width: fit-content;
  height: fit-content;
  padding: 0.125rem 0.375rem;
  border-radius: 0.5rem;
`;

const HighlightedSkillCard = styled(SkillCard)`
  background-color: ${({ theme }) => theme.colors.text.highlightBackground};
  color: ${({ theme }) => theme.colors.text.highlight};
`;

type MatchSkillCategoriesProps = SpacingProps & {
  skills: string[];
};

const SkillSection = ({ skills }: { skills: Skill[] }) => {
  return (
    <SkillSectionContainer gap="sm" direction="horizontal">
      {skills.map(({ skill, requirementLevel }) =>
        requirementLevel === RequirementLevel.MUST_HAVE ||
        requirementLevel === RequirementLevel.NICE_TO_HAVE ? (
          <HighlightedSkillCard key={skill}>
            <Paragraph>{skill}</Paragraph>
          </HighlightedSkillCard>
        ) : (
          <SkillCard key={skill}>
            <Paragraph>{skill}</Paragraph>
          </SkillCard>
        )
      )}
    </SkillSectionContainer>
  );
};

export const MatchSkillCategories = ({
  skills,
  className,
}: MatchSkillCategoriesProps) => {
  const { jobPosting } = useJobPosting();
  const requirements = jobPosting?.specification?.requirements || [];
  const { languages, frameworks, databases, others } = categoriseSkills({
    skills,
    requirements,
  });
  return (
    <Spacing gap="sm" direction="vertical" className={className}>
      {languages.length !== 0 && (
        <Spacing gap="sm">
          <SkillHeading level="h6">Languages</SkillHeading>
          <SkillSection skills={languages} />
        </Spacing>
      )}
      {frameworks.length !== 0 && (
        <Spacing gap="sm">
          <SkillHeading level="h6">Frameworks</SkillHeading>
          <SkillSection skills={frameworks} />
        </Spacing>
      )}
      {databases.length !== 0 && (
        <Spacing gap="sm">
          <SkillHeading level="h6">Databases</SkillHeading>
          <SkillSection skills={databases} />
        </Spacing>
      )}
      {others.length !== 0 && (
        <Spacing gap="sm">
          {/*only show 'Other' heading if this is not the only section we are displaying*/}
          {languages.length + frameworks.length + databases.length > 0 && (
            <SkillHeading level="h6">Other</SkillHeading>
          )}
          <SkillSection skills={others} />
        </Spacing>
      )}
    </Spacing>
  );
};
