import {
  colors,
  fontSizes,
  hexAlphaValue,
  lineHeights,
  screenBreakpoints,
  shadows,
  spacings,
} from "@unlikelyai-magic/ui/variables";

export const theme = {
  colors: {
    ...colors,
    brand: {
      primary: colors.mili.tangerine,
      secondary: colors.mili.pearl,
      accent: colors.mili.violet,
    },
    buttons: {
      primary: {
        default: colors.mili.tangerine,
        focus: "#FF8064",
        disabled: colors.mili.tangerine + hexAlphaValue(50),
      },
      secondary: {
        default: colors.mili.pearl,
        focus: colors.gray[200],
        disabled: colors.mili.pearl + hexAlphaValue(90),
      },
    },
    background: {
      primary: colors.mili.pearl,
      secondary: colors.white,
    },
    text: {
      primary: colors.mili.blackcurrant,
      secondary: colors.mili.blackcurrant + hexAlphaValue(80),
      tertiary: colors.mili.blackcurrant + hexAlphaValue(50),
      highlight: colors.mili.tangerine,
      highlightBackground: colors.mili.tangerine + hexAlphaValue(10),
      dark: colors.mili.pearl, // text for when the BACKGROUND is dark... lol
    },
    architecture: {
      grey: "#E7E5E8",
    },
  },
  shadows,
  spacings,
  lineHeights,
  screenBreakpoints,
  fontSizes,
};

export type MiliTheme = typeof theme;
