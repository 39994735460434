import { useState } from "react";
import styled from "@emotion/styled";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { SecondaryButton } from "@jobe/ui/buttons";
import { Heading, Note } from "@jobe/ui/typography";
import { ChatPrimaryButton } from "./ChatPrimaryButton";

type MinMaxResponseProps = {
  onSubmit: (range: [number, number]) => void;
};

const ExperienceCard = styled(Spacing)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  width: fit-content;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
`;

const TextContainer = styled(Spacing)`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.background.secondary};
  width: 8rem;
  border-radius: 1rem;
  gap: 0;
`;

const ChangeValueButton = styled(SecondaryButton)`
  border-radius: 0.5rem;
`;

const Label = styled(Note)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const MinMaxResponse = ({ onSubmit }: MinMaxResponseProps) => {
  const [answered, setAnswered] = useState<boolean>(false);
  const [minYears, setMinYears] = useState(3);
  const [maxYears, setMaxYears] = useState(5);

  const handleMinChange = (value: number) => {
    const newValue = Math.min(Math.max(value, 0), 20);
    setMinYears(newValue);
    if (newValue > maxYears) {
      setMaxYears(newValue);
    }
  };

  const handleMaxChange = (value: number) => {
    const newValue = Math.min(Math.max(value, 0), 20);
    setMaxYears(newValue);
    if (newValue < minYears) {
      setMinYears(newValue);
    }
  };

  const handleConfirm = () => {
    setAnswered(true);
    onSubmit([minYears, maxYears]);
  };

  return (
    <Spacing direction="vertical">
      {!answered ? (
        <Spacing>
          <ExperienceCard direction="horizontal">
            <ChangeValueButton
              icon={<MinusIcon />}
              onClick={() => handleMinChange(minYears - 1)}
            />
            <TextContainer direction="vertical">
              <Heading level="h3">{minYears}</Heading>
              <Label>Minimum experience</Label>
            </TextContainer>
            <ChangeValueButton
              icon={<PlusIcon />}
              onClick={() => handleMinChange(minYears + 1)}
            />
          </ExperienceCard>
          <ExperienceCard direction="horizontal">
            <ChangeValueButton
              icon={<MinusIcon />}
              onClick={() => handleMaxChange(maxYears - 1)}
            />
            <TextContainer direction="vertical">
              <Heading level="h3">{maxYears}</Heading>
              <Label>Maximum experience</Label>
            </TextContainer>
            <ChangeValueButton
              icon={<PlusIcon />}
              onClick={() => handleMaxChange(maxYears + 1)}
            />
          </ExperienceCard>
          <ChatPrimaryButton onClick={handleConfirm}>
            Confirm experience
          </ChatPrimaryButton>
        </Spacing>
      ) : null}
    </Spacing>
  );
};
