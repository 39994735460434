import React from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { MilisTake } from "./MilisTake";
import { TalentHighlights } from "./TalentHighlights";

const InsightsContainer = styled(Spacing)`
  flex-direction: column;
  gap: 2.5rem;
  width: 100%;

  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}) {
    justify-content: space-between;
    flex-direction: row;
    gap: 1rem;
  }
`;

const ResponsiveMilisTake = styled(MilisTake)`
  @media (min-width: ${({ theme }) => theme.screenBreakpoints.lg}) {
    max-width: 22rem;
  }
`;

export const MatchCardTalentInsightTab = () => {
  return (
    <InsightsContainer direction="horizontal">
      <TalentHighlights />
      <ResponsiveMilisTake />
    </InsightsContainer>
  );
};
