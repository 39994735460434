import React, { MouseEvent, useState } from "react";
import styled from "@emotion/styled";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import { PrimaryButton } from "@jobe/ui/buttons";
import { RequirementInput, RequirementList } from "../../job-requirements";
import { useCreateJobOpeningChat } from "../hooks";

const InputContainer = styled.div`
  position: relative;
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.75rem;
  width: 100%;
`;

export const JobRequirementsWidget = () => {
  const { track } = useMetrics();
  const { formData, setFormData, onSubmit } = useCreateJobOpeningChat();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleChangeRequirements = (
    requirements: JobSpecificationRequirement[]
  ) => {
    setFormData((prev) => ({ ...prev, requirements }));
  };

  const handleSubmit = async (event: MouseEvent<HTMLElement>) => {
    try {
      event.stopPropagation();
      event.preventDefault();
      track("JobOpeningCreated", "job_opening_create_button", {
        ...formData,
      });
      setSubmitting(true);
      await onSubmit();
    } catch (err) {
      setSubmitting(false);
    }
  };

  return (
    <ListContainer>
      <RequirementList
        mode="combined"
        value={formData.requirements}
        onChange={handleChangeRequirements}
      />
      <InputContainer>
        <RequirementInput
          placement="top-start"
          value={formData.requirements}
          submitButton={
            <PrimaryButton
              disabled={submitting || formData.requirements.length === 0}
              loading={submitting}
              onClick={handleSubmit}
            >
              View matches
            </PrimaryButton>
          }
          onCreate={(value) =>
            handleChangeRequirements([...formData.requirements, value])
          }
        />
      </InputContainer>
    </ListContainer>
  );
};
