import React from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { spacings } from "@unlikelyai-magic/ui/variables";
import { PageLayout } from "@jobe/ui/layouts";
import { LoadingPage } from "@jobe/ui/loading";
import { theme } from "@jobe/ui/theme";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useJobPosting } from "../../job-postings";
import { MatchAvatarList, MatchCard } from "../components";
import { useMatches } from "../hooks";

const CardHeader = styled(Spacing)`
  max-width: 64rem;
  width: 100%;
`;

const MatchProfileContainer = styled(Spacing)`
  display: flex;
  padding: ${spacings.lg} ${spacings.lg} 5rem;
  width: 100%;
  align-items: center;
`;

const EmptyPageContainer = styled(Spacing)`
  text-align: center;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 75vh;
`;

type MatchCategoryPageProps = {
  matchCategory: string;
};

export const MatchCategoryPage = ({
  matchCategory,
}: MatchCategoryPageProps) => {
  const { jobPosting } = useJobPosting();
  const { matches, fetching } = useMatches();

  if (fetching) {
    return <LoadingPage />;
  }

  if (!jobPosting) {
    return (
      <EmptyPageContainer gap="xl">
        <Heading level="h3">Oops! Something went wrong.</Heading>
        <Paragraph>
          We couldn't load your job opening. Please try again later.
        </Paragraph>
      </EmptyPageContainer>
    );
  }

  if (matches?.length === 0) {
    return (
      <EmptyPageContainer>
        <Spacing gap="xl">
          <Heading level="h3">Nothing to see here...</Heading>
          <Paragraph color={theme.colors.text.secondary}>
            You don't have any {matchCategory} matches yet.
          </Paragraph>
        </Spacing>
      </EmptyPageContainer>
    );
  }

  return (
    <PageLayout>
      <MatchProfileContainer gap="lg">
        <CardHeader
          direction="horizontal"
          items="center"
          justify="space-between"
        >
          <MatchAvatarList isLoading={fetching} />
        </CardHeader>
        <MatchCard />
      </MatchProfileContainer>
    </PageLayout>
  );
};
