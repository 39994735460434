import { FunctionComponent, MouseEvent } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { colors } from "@unlikelyai-magic/ui/variables";

const ButtonsContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: -1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.15rem;
`;

const StyledButton = styled.button`
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0.2rem;
  color: ${colors.primary};
  background: ${colors.gray["200"]};
  line-height: 0.75rem;
  transition: background-color 0.2s ease;

  :hover {
    color: ${colors.primary};
    background: ${colors.primary}44;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }

  :focus {
    color: ${colors.primary};
  }
`;

const StyledCheckIcon = styled(CheckOutlined)`
  font-size: 0.75rem;
`;

const StyledCloseIcon = styled(CloseOutlined)`
  font-size: 0.75rem;
`;

/**
 * @typedef InputActionButtonsProps
 * @property {() => void} onCancel A callback that will be called when the cancel button is clicked.
 * @property {() => void} onSubmit A callback that will be called when the submit button is clicked.
 */
type InputActionButtonsProps = {
  onCancel: () => void;
  disableCancelButton?: boolean;
  onSubmit: () => void;
  disableSubmitButton?: boolean;
};

/**
 * A set of buttons that can be used to submit or cancel an input.
 * @param props {InputActionButtonsProps} The props for the component.
 */
export const InputActionButtons: FunctionComponent<InputActionButtonsProps> = (
  props: InputActionButtonsProps
) => {
  const handleSubmit = (event: MouseEvent) => {
    event.stopPropagation();
    props.onSubmit();
  };

  const handleCancel = (event: MouseEvent) => {
    event.stopPropagation();
    props.onCancel();
  };

  return (
    <ButtonsContainer>
      <StyledButton
        onClick={handleSubmit}
        disabled={props.disableSubmitButton}
        onMouseDown={(e) => e.preventDefault()}
      >
        <StyledCheckIcon />
      </StyledButton>
      <StyledButton
        onClick={handleCancel}
        disabled={props.disableCancelButton}
        onMouseDown={(e) => e.preventDefault()}
      >
        <StyledCloseIcon />
      </StyledButton>
    </ButtonsContainer>
  );
};
