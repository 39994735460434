import { SerializedStyles, css } from "@emotion/react";
import { ButtonSize } from "../types";
import { buttonIconStyles } from "./buttonIcon.styles";
import { buttonSizeStyles } from "./buttonSize.styles";
import { buttonTextStyles } from "./buttonText.styles";

const baseStyle = css`
  display: flex;
  height: unset;
  transition-duration: 100ms;
  border-color: transparent;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    border-color: transparent;
  }

  &:disabled,
  &:disabled:hover {
    border-color: transparent;
  }

  &:disabled,
  &:disabled:hover {
    cursor: auto;
  }
`;

export const buttonStyles: Record<ButtonSize, SerializedStyles> = {
  xs: css`
    ${baseStyle};
    ${buttonTextStyles.xs};
    ${buttonSizeStyles.xs};
    ${buttonIconStyles.xs};
  `,
  sm: css`
    ${baseStyle};
    ${buttonTextStyles.sm};
    ${buttonSizeStyles.sm};
    ${buttonIconStyles.sm};
  `,
  md: css`
    ${baseStyle};
    ${buttonTextStyles.md};
    ${buttonSizeStyles.md};
    ${buttonIconStyles.md};
  `,
  lg: css`
    ${baseStyle};
    ${buttonTextStyles.lg};
    ${buttonSizeStyles.lg};
    ${buttonIconStyles.lg};
  `,
  xl: css`
    ${baseStyle};
    ${buttonTextStyles.xl};
    ${buttonSizeStyles.xl};
    ${buttonIconStyles.xl};
  `,
};
