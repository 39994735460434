import styled from "@emotion/styled";
import { fontSizes, lineHeights } from "@unlikelyai-magic/ui/variables";
import { Paragraph, ParagraphProps } from "./Paragraph";

export type NoteProps = Omit<ParagraphProps, "small">;

export const Note = styled((props: NoteProps) => {
  return <Paragraph {...props} />;
})`
  font-size: ${fontSizes.xs};
  line-height: ${lineHeights.xs};
`;
