import { useState } from "react";
import styled from "@emotion/styled";
import { SparklesIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import { PlumButton } from "@jobe/ui/buttons";
import { useSlideOver } from "@jobe/ui/slide-overs";
import { RequirementList } from "../components";

const SpaceContentVertical = styled(Spacing)`
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

const Scrollable = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 1rem;
`;

const SaveButton = styled(PlumButton)`
  margin-top: auto;
`;

type EditRequirementsContentProps = {
  onSubmit: (requirements: JobSpecificationRequirement[]) => Promise<void>;
  requirements: JobSpecificationRequirement[];
  setRequirements: (requirements: JobSpecificationRequirement[]) => void;
};

export const EditRequirementsContent = ({
  requirements,
  setRequirements,
  onSubmit,
}: EditRequirementsContentProps) => {
  const { closeSlideOver } = useSlideOver();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      await onSubmit(requirements);
      closeSlideOver();
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <SpaceContentVertical>
      <Scrollable>
        <RequirementList
          mode="split"
          value={requirements}
          onChange={setRequirements}
        />
      </Scrollable>
      <SaveButton
        loading={submitting}
        icon={<SparklesIcon />}
        onClick={handleSubmit}
      >
        Save and view matches
      </SaveButton>
    </SpaceContentVertical>
  );
};
