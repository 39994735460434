import styled from "@emotion/styled";
import { Button } from "antd";
import { colors } from "@unlikelyai-magic/ui/variables";
import { buttonStyles } from "../../styles";
import { ButtonProps } from "../../types";

export type GrayButtonProps = ButtonProps;

export const GrayButton = styled(({ size, ...props }: GrayButtonProps) => (
  <Button {...props} size="middle" type="default" />
))`
  ${({ size = "md" }) => buttonStyles[size]};
  color: ${colors.white};
  background: ${colors.gray["800"]};
  --antd-wave-shadow-color: ${colors.gray["800"]};

  &:hover,
  &:focus,
  &.ant-btn-loading {
    color: ${colors.white};
    background: ${colors.gray["600"]};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.gray["100"]};
    background: ${colors.gray["400"]};
  }
`;
