import styled from "@emotion/styled";
import { Button } from "antd";
import { colors } from "@unlikelyai-magic/ui/variables";
import { buttonStyles } from "../../styles";
import { ButtonProps } from "../../types";

export type PrimaryButtonProps = ButtonProps;

export const PrimaryButton = styled(
  ({ size, ...props }: PrimaryButtonProps) => (
    <Button {...props} size="middle" type="primary" />
  )
)`
  ${({ size = "md" }) => buttonStyles[size]};
  background: ${colors.blue["500"]};
  --antd-wave-shadow-color: ${colors.blue["500"]};

  &:hover,
  &:focus,
  &.ant-btn-loading {
    background: ${colors.blue["700"]};
  }
`;
