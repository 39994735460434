import { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { LoadingIcon } from "@unlikelyai-magic/ui/icons";
import { colors } from "@unlikelyai-magic/ui/variables";

const LoadingContainer = styled.div`
  border-radius: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${colors.neutral["500"]}33;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
`;

const Loading = styled(LoadingIcon)`
  height: 1rem;
  width: 1rem;
  color: ${colors.primary};
`;

/**
 * A loading overlay that can be used to indicate that a component is loading. Uses absolute
 * positioning and 100% width and height to cover the component it is used in.
 */
export const LoadingOverlay: FunctionComponent = () => {
  return (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  );
};
