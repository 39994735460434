export const spacings = {
  none: "0",
  "3xs": "0.125rem", // 2px
  "2xs": "0.25rem", // 4px
  xs: "0.375rem", // 6px
  sm: "0.5rem", // 8px
  md: "0.75rem", // 12px
  lg: "1rem", // 16px
  xl: "1.5rem", // 24px
  "2xl": "2rem", // 32px
  "3xl": "2.5rem", // 40px
  "4xl": "3rem", // 48px
  "5xl": "3.5rem", // 56px
};
