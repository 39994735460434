import { SerializedStyles, css } from "@emotion/react";
import { colors, fontSizes, lineHeights } from "@unlikelyai-magic/ui/variables";

const styles = {
  text: css`
    font-size: ${fontSizes.sm};
    line-height: ${lineHeights.sm};
    font-weight: 400;
  `,
  border: css`
    border: 1px solid ${colors.gray["200"]};
    border-radius: 0.375rem;
  `,
  "border:focus": css`
    border-color: ${colors.blue["600"]};
    box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.2);
  `,
  placeholder: css`
    font-weight: 400;
    color: ${colors.gray["400"]};
  `,
};

export const inputStyles: Record<keyof typeof styles, SerializedStyles> =
  styles;
