import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { SecondaryButton } from "@unlikelyai-magic/ui/buttons";
import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import { Paragraph } from "@jobe/ui/typography";

const ListItem = styled.li<{ mode: RequirementListItemProps["mode"] }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0;
  gap: 0.5rem;
  background: ${({ theme }) => theme.colors.white};

  ${({ mode, theme }) =>
    mode === "split"
      ? css`
          padding: 0.25rem 0;
          border-radius: 0.25rem;
        `
      : css`
          padding: 0.5rem 0.5rem 0.5rem 1.25rem;
          border-radius: 7.5rem;
          margin-bottom: 0.5rem;
        `};

  ${SecondaryButton} {
    box-shadow: none !important;
    padding: 0.625rem;

    ${({ mode, theme }) =>
      mode === "combined" &&
      css`
        border-radius: 50%;
      `};
  }
`;

type RequirementListItemProps = {
  mode?: "combined" | "split";
  value: JobSpecificationRequirement;
  index: number;
  onRemove: () => void;
  disabled?: boolean;
};

export const RequirementListItem = ({
  mode = "combined",
  value,
  index,
  onRemove,
  disabled = false,
}: RequirementListItemProps) => {
  const { track } = useMetrics();
  const handleRemove = () => {
    track("JobSpecSetRequirements", "remove_requirement_button_clicked", {
      ...value,
    });
    onRemove();
  };

  return (
    <Draggable draggableId={value.id} index={index} isDragDisabled={disabled}>
      {(provided) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          mode={mode}
        >
          <Paragraph>{value.text}</Paragraph>
          <div>
            <SecondaryButton
              icon={<TrashIcon />}
              onClick={handleRemove}
              disabled={disabled}
            />
          </div>
        </ListItem>
      )}
    </Draggable>
  );
};
