import styled from "@emotion/styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { RedButton, SecondaryButton } from "@jobe/ui/buttons";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useModal } from "./useModal";

const ModalContainer = styled.div`
  width: 25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ModalHeader = styled(Spacing)`
  justify-content: space-between;
  align-items: center;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const CloseButton = styled(SecondaryButton)`
  &.ant-btn-icon-only {
    color: ${({ theme }) => theme.colors.gray["400"]};
    background: none;
    border: none;
    box-shadow: none !important;
    padding: 0.25rem;
    font-size: 1.5rem;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.gray["600"]};
      background: none;
    }
  }
`;

type ConfirmDeleteModalProps = {
  description: string;
  title?: string;
  handleConfirm: () => void;
};

export const ConfirmDeleteModal = ({
  description,
  title = "Are you sure?",
  handleConfirm,
}: ConfirmDeleteModalProps) => {
  const { closeModal, isOpen } = useModal();

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <ModalHeader direction="horizontal">
        <Heading level="h4">{title}</Heading>
        <div>
          <CloseButton icon={<XMarkIcon />} onClick={closeModal} />
        </div>
      </ModalHeader>
      <Paragraph>{description}</Paragraph>
      <ModalFooter>
        <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
        <RedButton onClick={handleConfirm}>Delete</RedButton>
      </ModalFooter>
    </ModalContainer>
  );
};
