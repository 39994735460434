import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Message,
  MessageAuthor,
  MessageType,
  PushMessage,
  UpdateMessage,
} from "../types";

export type ChatMessagesState = {
  messages: Message[];
  pushMessage: (message: PushMessage) => string;
  updateMessage: (message: UpdateMessage) => void;
  latestBotMessageId: string;
};

export const useChatMessages = (): ChatMessagesState => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [latestBotMessageId, setLatestBotMessageId] = useState<string>("");

  const pushMessage = useCallback(
    (message: PushMessage): string => {
      const id = uuidv4();
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          ...message,
          id,
          type: MessageType.Text,
          sentAt: new Date().toISOString(),
        },
      ]);
      if (message.author === MessageAuthor.Bot) {
        setLatestBotMessageId(id);
      }
      return id;
    },
    [setMessages, setLatestBotMessageId]
  );

  const updateMessage = useCallback(
    ({ id, text, widget }: UpdateMessage) => {
      setMessages((prevMessages) => {
        const indexToUpdate = prevMessages.findIndex(
          (message) => message.id === id
        );
        if (indexToUpdate === -1) return prevMessages;
        return [
          ...prevMessages.slice(0, indexToUpdate),
          {
            ...prevMessages[indexToUpdate],
            text,
            widget,
          },
          ...prevMessages.slice(indexToUpdate + 1),
        ];
      });
    },
    [setMessages]
  );

  return {
    messages,
    pushMessage,
    updateMessage,
    latestBotMessageId,
  };
};
