import React from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { message } from "antd";
// Substitute for the error icon
import styled, { createGlobalStyle } from "styled-components";
import { colors } from "@unlikelyai-magic/ui/variables";

const ErrorMessageContainer = styled.div`
  display: inline-flex;
  align-items: center;
  color: white;
`;

const ErrorIcon = styled(XMarkIcon)`
  height: 1.5rem;
  margin-right: 0.5rem;
  color: white;
`;

const GlobalErrorMessageStyle = createGlobalStyle`
  .ant-message-notice-content {
    background-color: ${colors.red[600]};
    border-radius: 0.25rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.15);
    padding: 0.5rem 0.75rem 0.125rem;
  }
`;

export const ErrorMessage = (text: string, duration?: number) => {
  message.error({
    icon: <></>, // Removes the default icon
    duration: duration ?? 2,
    content: (
      <>
        <GlobalErrorMessageStyle />
        <ErrorMessageContainer>
          <ErrorIcon />
          {text}
        </ErrorMessageContainer>
      </>
    ),
  });
};
