import React, { ComponentProps, useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Tabs, TabsProps } from "antd";
import { Avatar, AvatarSize, avatarSizes } from "@unlikelyai-magic/ui/avatars";
import { spacings } from "@unlikelyai-magic/ui/variables";
import { sleep, useComponentSize } from "@unlikelyai-magic/utils";
import { Match } from "@jobe/data-access/api-types";
import { WhiteButton } from "@jobe/ui/buttons";
import { LoadingIcon } from "@jobe/ui/loading";
import { useMatches } from "../hooks";

type AvatarsContainerProps = ComponentProps<"div"> & {
  shouldBlurRight: boolean;
  shouldBlurLeft: boolean;
};

interface AvatarListProps extends TabsProps {
  shiftAmount: number;
}

const ScrollableTabs = styled(Tabs)`
  .ant-tabs-nav-wrap {
    overflow-x: auto !important;
  }
  .ant-tabs-nav {
    width: max-content !important;
    display: flex !important;
    flex-wrap: nowrap !important;
  }
  .ant-tabs-tab {
    flex: 0 0 auto !important;
    padding: 0.5rem 0;
  }
`;

const AvatarList = styled(({ shiftAmount, ...props }: AvatarListProps) => (
  <ScrollableTabs {...props} />
))`
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  transform: translateX(${({ shiftAmount }) => -shiftAmount}rem);
  transition: transform 0.3s ease;

  .ant-tabs-nav .ant-tabs-ink-bar {
    width: 0.5rem !important;
    margin-left: 1rem !important;
    background-color: ${({ theme }) => theme.colors.brand.primary} !important;
  }

  .ant-tabs-nav:before {
    border-bottom: none;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  cursor: default;
`;

const NavigationButton = styled(WhiteButton)`
  border: none;
  &.ant-btn-icon-only {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;

    & svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const AvatarButton = styled(Avatar)`
  cursor: pointer;
`;

const ListContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  flex: 1;
`;

const AvatarsContainer = styled.div<AvatarsContainerProps>`
  position: relative;
  overflow: hidden;
  flex: 1;
  height: 3.5rem;

  ${({ theme, shouldBlurRight, shouldBlurLeft }) => css`
    &::before,
    &::after {
      content: "";
      display: flex;
      position: absolute;
      width: 2rem;
      height: 100%;
      top: 0;
      z-index: 1;
      pointer-events: none;
    }

    ${shouldBlurLeft &&
    css`
      &::before {
        left: -0.25rem;
        background: linear-gradient(
          to right,
          ${theme.colors.background.primary} 0,
          transparent 100%
        );
      }
    `}

    ${shouldBlurRight &&
    css`
      &::after {
        right: 0;
        background: linear-gradient(
          to left,
          ${theme.colors.background.primary} 0,
          transparent 100%
        );
      }
    `}
  `}
`;

const calculateShift = (
  avatarSize: AvatarSize,
  paddingSize: string,
  listWidth: number,
  avatars: { key: string; label: EmotionJSX.Element }[],
  currentMatch?: Match
): {
  shiftAmount: number;
  shouldBlurLeft: boolean;
  shouldBlurRight: boolean;
} => {
  const paddedAvatarWidth =
    parseFloat(avatarSizes[avatarSize]) + parseFloat(paddingSize);
  const selectedIndex =
    avatars.findIndex((avatar) => avatar.key === currentMatch?.id) || 0;
  // The calculated shift is the index of the selected avatar multiplied by the width of each avatar, with a minimum of 0
  const calculatedShift = Math.max((selectedIndex - 1) * paddedAvatarWidth, 0);
  // The maximum the list should shift is the length of the full list of avatars minus the list width (so that once we reach the end we don't keep shifting), with a minimum of 0
  const maxShift = Math.max(
    avatars.length * paddedAvatarWidth -
      listWidth / 16 -
      parseFloat(paddingSize),
    0
  );
  const shiftAmount = Math.min(calculatedShift, maxShift);
  // we should blur the right hand edge of the list if the list has shifted at all (as that means there are further avatars to the left)
  const shouldBlurLeft = calculatedShift !== 0;
  // we should blur the left hand edge of the list whenever the last avatar is a loading icon (as there are more matches to come),
  // or if the list can shift but has not shifted the max amount yet (as that means there are further avatars to the right)
  const shouldBlurRight =
    avatars[avatars.length - 1].key === "loadingIcon" ||
    (shiftAmount !== maxShift && maxShift !== 0);

  return { shiftAmount, shouldBlurLeft, shouldBlurRight };
};

interface MatchAvatarListProps extends ComponentProps<"div"> {
  isLoading: boolean;
}

export const MatchAvatarList = (props: MatchAvatarListProps) => {
  const { ref, width: listWidth } = useComponentSize();
  const {
    matches,
    allMatchesFound,
    currentMatch,
    currentMatchIndex,
    setCurrentMatch,
    nextMatch,
    previousMatch,
  } = useMatches();
  const [moveInDirection, setMoveInDirection] = useState<
    "left" | "right" | null
  >(null);
  const [holdTimeout, setHoldTimeout] = useState<NodeJS.Timeout | null>(null);
  const avatarSize = "sm";
  const paddingSize = spacings.sm;
  const hasPreviousMatch = currentMatchIndex !== 0;
  const hasNextMatch = currentMatchIndex !== (matches?.length || 0) - 1;

  const handleAvatarClick = (match: Match) => {
    setCurrentMatch(match.id);
  };

  const handleKeyDown = useCallback(
    (event: { keyCode: number }) => {
      const direction =
        event.keyCode === 37 ? "left" : event.keyCode === 39 ? "right" : null;
      if (direction && !holdTimeout) {
        setHoldTimeout(
          setTimeout(() => {
            setMoveInDirection(direction);
          }, 500)
        );
        // Immediate action on key press
        if (direction === "left") {
          previousMatch?.();
        } else if (direction === "right") {
          nextMatch?.();
        }
      }
    },
    [holdTimeout, previousMatch, nextMatch]
  );

  const handleKeyUp = useCallback(() => {
    if (holdTimeout) {
      clearTimeout(holdTimeout);
      setHoldTimeout(null);
    }
    setMoveInDirection(null);
  }, [holdTimeout]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  useEffect(() => {
    const moveList = async () => {
      if (moveInDirection) {
        await sleep(100);
        if (moveInDirection === "left" && hasPreviousMatch) {
          previousMatch?.();
        } else if (moveInDirection === "right" && hasNextMatch) {
          nextMatch?.();
        }
      }
    };

    if (moveInDirection) {
      moveList();
    }
  }, [
    moveInDirection,
    hasPreviousMatch,
    hasNextMatch,
    previousMatch,
    nextMatch,
  ]);

  const handleMouseDown = (direction: "left" | "right") => {
    const action = direction === "left" ? previousMatch : nextMatch;
    action?.(); // Immediate action on click

    const timeoutId = setTimeout(() => {
      setMoveInDirection(direction);
    }, 500); // Wait half a second before continuously shifting the list

    setHoldTimeout(timeoutId);
  };

  const handleMouseUp = (event: React.MouseEvent<any, MouseEvent>) => {
    if (holdTimeout) {
      clearTimeout(holdTimeout);
      setHoldTimeout(null);
    }
    setMoveInDirection(null);
    event.currentTarget.blur();
  };

  const matchAvatars =
    matches?.map((match) => ({
      key: match.id,
      label: (
        <AvatarButton
          src={match.resume.profileImageUrl || undefined}
          // alt text is the match's initials
          alt={match.resume.name
            .split(" ")
            .slice(0, 2)
            .map((part) => part[0].toUpperCase())
            .join("")}
          size={avatarSize}
          bordered={match.id !== currentMatch?.id}
          onClick={() => handleAvatarClick(match)}
        />
      ),
    })) ?? [];

  if (!allMatchesFound) {
    matchAvatars.push({
      key: "loadingIcon",
      label: (
        <LoadingContainer>
          <LoadingIcon />
        </LoadingContainer>
      ),
    });
  }

  const { shiftAmount, shouldBlurLeft, shouldBlurRight } = calculateShift(
    avatarSize,
    paddingSize,
    listWidth,
    matchAvatars,
    currentMatch
  );

  if (!Array.isArray(matches)) {
    return null;
  }

  return (
    <ListContainer {...props}>
      <NavigationButton
        onMouseDown={() => handleMouseDown("left")}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        size={avatarSize}
        icon={<ChevronLeftIcon />}
        disabled={!hasPreviousMatch}
      />
      <AvatarsContainer
        ref={ref}
        shouldBlurRight={shouldBlurRight}
        shouldBlurLeft={shouldBlurLeft}
      >
        <AvatarList
          activeKey={currentMatch?.id}
          items={matchAvatars}
          shiftAmount={shiftAmount}
          tabBarGutter={parseFloat(paddingSize) * 16}
        />
      </AvatarsContainer>
      <NavigationButton
        onMouseDown={() => handleMouseDown("right")}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
        size={avatarSize}
        icon={<ChevronRightIcon />}
        disabled={!hasNextMatch}
      />
    </ListContainer>
  );
};
