import { CardBody } from "./CardBody";
import { CardContainer, CardContainerProps } from "./CardContainer";
import { CardFooter } from "./CardFooter";
import { CardHeader } from "./CardHeader";

export type CardProps = CardContainerProps;

/**
 * A generic card component
 */
export const Card = Object.assign(CardContainer, {
  Header: CardHeader,
  Body: CardBody,
  Footer: CardFooter,
});
