import { ComponentProps } from "react";
import styled from "@emotion/styled";

export type SlideOverContentProps = ComponentProps<"div">;

export const SlideOverContent = styled((props: SlideOverContentProps) => {
  return <div {...props} />;
})`
  display: flex;
  position: relative;
  padding: 2rem;
  flex: 1;
`;
