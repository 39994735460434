import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@jobe/ui/theme";

type LoadingIconProps = {
  size?: string;
};

type LoaderProps = {
  angle: string;
  thickness: string;
  firstColor: string;
  secondColor: string;
};

const LoaderContainer = styled.div<LoadingIconProps>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`;

const Loader = styled.div<LoaderProps>`
  transform: rotateZ(${({ angle }) => angle});
  border-radius: 50%;
  width: 100%;
  height: 100%;
  color: ${({ firstColor }) => firstColor};
  position: absolute;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    border-radius: 50%;
    transform: rotateX(70deg);
    animation: ${({ thickness }) => keyframes`
      0%, 100% {
        box-shadow: ${thickness} 0 0 0 currentcolor;
      }
      12% {
        box-shadow: ${thickness} ${thickness} 0 0 currentcolor;
      }
      25% {
        box-shadow: 0 ${thickness} 0 0 currentcolor;
      }
      37% {
        box-shadow: -${thickness} ${thickness} 0 0 currentcolor;
      }
      50% {
        box-shadow: -${thickness} 0 0 0 currentcolor;
      }
      62% {
        box-shadow: -${thickness} -${thickness} 0 0 currentcolor;
      }
      75% {
        box-shadow: 0 -${thickness} 0 0 currentcolor;
      }
      87% {
        box-shadow: ${thickness} -${thickness} 0 0 currentcolor;
      }
    `}
      1.25s linear infinite;
  }

  &::after {
    color: ${({ secondColor }) => secondColor};
    transform: rotateY(70deg);
    animation-delay: 0.6s;
  }
`;

export const LoadingIcon = ({ size = "2rem" }: LoadingIconProps) => {
  const numericSize = parseInt(size);
  const sizeUnit = size.replace(numericSize.toString(), "");
  return (
    <LoaderContainer size={size} className="LoadingIcon">
      <Loader
        angle="45deg"
        thickness={numericSize / 10 + sizeUnit}
        firstColor={theme.colors.mili.plum}
        secondColor={theme.colors.mili.plum}
      />
      <Loader
        angle="180deg"
        thickness={numericSize / 10 + sizeUnit}
        firstColor={theme.colors.mili.tangerine}
        secondColor={theme.colors.mili.tangerine}
      />
    </LoaderContainer>
  );
};
