import React, { ComponentProps } from "react";
import { LinkedinFilled } from "@ant-design/icons";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { HeartIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { HeartIcon as HeartIconFilled } from "@heroicons/react/24/solid";
import { Avatar } from "@unlikelyai-magic/ui/avatars";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Match } from "@jobe/data-access/api-types";
import { PrimaryButton, SecondaryButton } from "@jobe/ui/buttons";
import { theme } from "@jobe/ui/theme";
import { Heading, Paragraph } from "@jobe/ui/typography";

const LinkedInIcon = styled(Icon)`
  width: 1.5rem;
  height: 1.5rem;
  color: #0a66c2;
`;

const CurrentEmploymentContainer = styled(Spacing)`
  flex-wrap: wrap;
  align-items: flex-start;
  display: inline;
`;

const CurrentEmployment = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.primary};
  font-weight: 400;
  white-space: normal;
  display: inline;
`;

const EmploymentConnector = styled(Paragraph)`
  color: ${theme.colors.text.tertiary};
  white-space: normal;
  display: inline;
`;

type MatchHeaderProps = ComponentProps<"header"> & {
  match: Match;
  onLike: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onMessage: () => void;
};

export const MatchHeader = styled(
  ({ match, onLike, onMessage, ...otherProps }: MatchHeaderProps) => {
    const { name, employmentHistory, profileImageUrl, linkedInUrl } =
      match.resume;
    const currentEmployment = employmentHistory.length
      ? employmentHistory[0]
      : undefined;
    const location = match.resume.location?.readableAddress;
    return (
      <header {...otherProps}>
        <Spacing direction="horizontal">
          <Avatar
            size="md"
            src={profileImageUrl || ""}
            alt={name
              .split(" ")
              .slice(0, 2)
              .map((part) => part[0].toUpperCase())
              .join("")}
          />
          <Spacing gap="3xs">
            <Spacing gap="lg" direction="horizontal" items="center">
              <Heading level="h2">
                {name}{" "}
                {linkedInUrl && (
                  <a href={linkedInUrl} target="_blank" rel="noreferrer">
                    <LinkedInIcon icon={LinkedinFilled} />
                  </a>
                )}
              </Heading>
            </Spacing>
            {currentEmployment && (
              <CurrentEmploymentContainer direction="horizontal">
                <CurrentEmployment small>
                  {currentEmployment.title}
                </CurrentEmployment>
                <EmploymentConnector small>{" at "}</EmploymentConnector>
                <CurrentEmployment small>
                  {currentEmployment.company}
                </CurrentEmployment>
                {location && (
                  <>
                    <EmploymentConnector small>
                      {" based in "}
                    </EmploymentConnector>
                    <CurrentEmployment small>{location}</CurrentEmployment>
                  </>
                )}
              </CurrentEmploymentContainer>
            )}
          </Spacing>
        </Spacing>
        <Spacing gap="md" direction="horizontal" items="flex-start">
          <SecondaryButton icon={<PaperAirplaneIcon />} onClick={onMessage}>
            Message
          </SecondaryButton>
          <PrimaryButton
            icon={match.likedAt ? <HeartIconFilled /> : <HeartIcon />}
            onClick={onLike}
          >
            {match.likedAt ? "Liked" : "Like"}
          </PrimaryButton>
        </Spacing>
      </header>
    );
  }
)`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${theme.screenBreakpoints.md}) {
    flex-direction: row;
  }
`;
