import React from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { JobPosting } from "@jobe/data-access/api-types";
import { UUID } from "@jobe/data-access/shared-types";
import { useModal } from "@jobe/ui/modals";
import { useDeleteJobPostingMutation } from "../apis";
import { JobPostingsGridItem } from "./JobPostingsGridItem";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
`;

type JobPostingsGridProps = {
  jobPostings: JobPosting[];
  onDelete?: (jobPostingId: UUID) => void;
};

export const JobPostingsGrid = ({
  jobPostings,
  onDelete,
}: JobPostingsGridProps) => {
  const [deleteJobPosting] = useDeleteJobPostingMutation();
  const { closeModal } = useModal();

  const handleDelete = async (jobPostingId: UUID) => {
    closeModal();
    await deleteJobPosting({ jobPostingId }).unwrap();
    onDelete?.(jobPostingId);
  };

  // this pattern allows any inner elements to save before navigation
  const handleLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    // Use window.location to navigate to the link after handling the save
    window.location.href = event.currentTarget.href;
  };

  return (
    <Grid>
      {jobPostings.map((item) => (
        <Link
          key={item.id}
          to={`/openings/${item.id}`}
          onClick={handleLinkClick}
        >
          <JobPostingsGridItem
            id={item.id}
            name={item.name}
            matchesLiked={item.matchesLiked ?? 0}
            createdAt={item.createdAt}
            handleDelete={async () => await handleDelete(item.id)}
            onLinkClick={() => (window.location.href = `/openings/${item.id}`)}
          />
        </Link>
      ))}
    </Grid>
  );
};
