import React, { ComponentProps } from "react";
import styled from "@emotion/styled";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { formatReasoning } from "@jobe/data-access/api-types";
import { PrimaryButton } from "@jobe/ui/buttons";
import { MiliLogo } from "@jobe/ui/mili-logos";
import { useSlideOver } from "@jobe/ui/slide-overs";
import { theme } from "@jobe/ui/theme";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useJobPosting } from "../../job-postings";
import { MatchAnalysisSlideOver } from "../components";
import { useMatches } from "../hooks";

const MilisTakeContainer = styled(Spacing)`
  background: ${({ theme }) => theme.colors.background.primary};
  padding: 1rem;
  border-radius: 1rem;
  flex-shrink: 0;
  height: fit-content;
`;

const MilisTakeTitleText = styled(Heading)`
  color: ${({ theme }) => theme.colors.brand.primary};
  padding-top: 0.875rem;
  margin-left: -0.875rem;
`;

const LogoContainer = styled.div`
  width: 3.5625rem;
  height: 2rem;
`;

const CheckIcon = styled(CheckCircleIcon)`
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.brand.primary};
`;

const Requirement = styled(Paragraph)`
  font-weight: 500;
  line-height: 1.25rem;
`;

const Explanation = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const FullAnalysisButton = styled(PrimaryButton)`
  width: fit-content;
`;

export const MilisTake = ({ className }: ComponentProps<"div">) => {
  const { openSlideOver } = useSlideOver();
  const { jobPosting } = useJobPosting();
  const { currentMatch } = useMatches();

  const showAnalysis = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!jobPosting || !currentMatch) return;
    openSlideOver(
      <MatchAnalysisSlideOver jobPosting={jobPosting} match={currentMatch} />
    );
    event.currentTarget.blur();
  };

  const fullExplanation = currentMatch?.explanations.find(
    (explanation) =>
      explanation.specificationId === jobPosting?.specification?.id
  );
  return (
    <MilisTakeContainer gap="xl" className={className}>
      <Spacing direction="horizontal">
        <LogoContainer>
          <MiliLogo color={theme.colors.brand.primary} />
        </LogoContainer>
        <MilisTakeTitleText level="h5">'s take</MilisTakeTitleText>
      </Spacing>
      <Spacing gap="xl">
        {jobPosting?.specification?.requirements.map((requirement) => {
          const explanation = fullExplanation?.requirements.find(
            (r) => r.requirementId === requirement.id
          );
          const reasons = formatReasoning(explanation?.reasoning || []);

          if (!explanation) {
            return null;
          }

          return (
            <Spacing gap="sm" direction="horizontal" key={requirement.id}>
              <CheckIcon />
              <Spacing gap="xs">
                <Requirement>{requirement.text}</Requirement>
                {reasons.length > 0 ? (
                  <Explanation small>{reasons}</Explanation>
                ) : null}
              </Spacing>
            </Spacing>
          );
        })}
      </Spacing>
      <FullAnalysisButton icon={<LightBulbIcon />} onClick={showAnalysis}>
        Full analysis
      </FullAnalysisButton>
    </MilisTakeContainer>
  );
};
