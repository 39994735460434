import { MutableRefObject } from "react";
import styled from "@emotion/styled";
import { TextAreaProps } from "antd/es/input";
import Input from "antd/lib/input";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";

export type TextBoxProps = TextAreaProps & { ref?: MutableRefObject<any> };

export const TextBox = styled((props: TextBoxProps) => (
  <Input.TextArea {...props} />
))`
  padding: 1rem;
  border-radius: 0.5rem;
  ::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
  ::selection {
    background-color: ${({ theme }) =>
      theme.colors.brand.primary + hexAlphaValue(80)};
    color: ${({ theme }) => theme.colors.text.dark};
  }
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand.primary} !important;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.brand.primary} !important;
    box-shadow: 0 0 0 2px ${({ theme }) =>
      theme.colors.brand.primary + hexAlphaValue(20)} !important;
    outline: none;
  }

}
`;
