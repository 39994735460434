import { FunctionComponent, ReactNode } from "react";
import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { LoadingIcon } from "@unlikelyai-magic/ui/icons";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors, screenBreakpoints } from "@unlikelyai-magic/ui/variables";

interface ContainerProps {
  containerWidth?: string;
  style?: SerializedStyles;
}

interface LabelProps {
  labelWidth?: string;
  style?: SerializedStyles;
}

interface ValueContainerProps {
  style?: SerializedStyles;
  labelVisible: boolean;
  valueWidth?: string;
}

const Container = styled.div<ContainerProps>`
  width: ${({ containerWidth }) => `${containerWidth ?? "300px"}`};
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media (min-width: ${screenBreakpoints.md}) {
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  ${({ style }) => style};
`;

const Label = styled(Paragraph)<LabelProps>`
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  flex: 1;
  width: 100%;
  padding: 0.25rem;
  height: 1.5rem;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelWidth }) => `${labelWidth ?? "50%"}`};
  }

  ${({ style }) => style};
`;

const ValueContainer = styled.div<ValueContainerProps>`
  padding: 2px;
  position: relative;
  width: 100%;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelVisible, valueWidth }) =>
      labelVisible ? valueWidth ?? "50%" : "100%"};
  }

  ${({ style }) => style};
`;

const LoadingContainer = styled.div`
  border-radius: 0.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: ${colors.neutral["500"]}33;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
`;

const Loading = styled(LoadingIcon)`
  height: 1rem;
  width: 1rem;
  color: ${colors.primary};
`;

/**
 * @typedef FormItemProps
 * @property {string} label The label to display for the input element. If an empty string is
 * passed. no label is displayed and the value container takes 100% of the available width
 * @property {string | number} value The value to display for the input element
 * @property {string} containerWidth The width of the container element. Defaults to 300px
 * @property {string} labelWidth The width of the label element. Defaults to 50%
 * @property {string} valueWidth The width of the value element. Defaults to 50%
 * @property {SerializedStyles} labelStyle Override styles for label container
 * @property {SerializedStyles} valueStyle Override styles for value container
 * @property {SerializedStyles} containerStyle Override styles for the parent container
 * @property {boolean} loading Whether the input element is in a loading state
 * @property {string} className The class name to apply to the container element
 * @property {(updatedValue: string | number | undefined) => void} onOk Callback function to
 * execute when the user clicks the OK button or presses enter on the keyboard
 */
type FormItemProps = {
  label?: string;
  value: ReactNode;
  containerWidth?: string;
  labelWidth?: string;
  valueWidth?: string;
  labelStyle?: SerializedStyles;
  valueStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  loading?: boolean;
  className?: string;
  onOk?: (updatedValue: string | number | undefined) => void;
};

/**
 * The component displays a form item similar to how editable inputs appear
 * @param props {FormItemProps}
 */
export const FormItem: FunctionComponent<FormItemProps> = (
  props: FormItemProps
) => {
  // Define the loading overlay element. This element is shown when the component is in a loading
  // state
  const LoadingOverlay = (
    <LoadingContainer>
      <Loading />
    </LoadingContainer>
  );

  return (
    <Container
      className={props.className}
      containerWidth={props.containerWidth}
      style={props.containerStyle}
    >
      {props.label && (
        <Label
          color={colors.neutral["400"]}
          labelWidth={props.labelWidth}
          style={props.labelStyle}
        >
          {props.label}
        </Label>
      )}
      <ValueContainer
        labelVisible={!!props.label}
        valueWidth={props.valueWidth}
        style={props.valueStyle}
      >
        {props.loading ? LoadingOverlay : null}
        {props.value}
      </ValueContainer>
    </Container>
  );
};
