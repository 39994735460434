import { ComponentProps, ReactNode } from "react";
import styled from "@emotion/styled";
import { spacings } from "@unlikelyai-magic/ui/variables";

export type SpacingProps = ComponentProps<"div"> & {
  gap?: keyof typeof spacings;
  direction?: "vertical" | "horizontal";
  justify?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "stretch"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "right"
    | "left";
  items?: "center" | "flex-start" | "flex-end" | "stretch";
  className?: string;
  children?: ReactNode | ReactNode[];
};

export const Spacing = styled((props: SpacingProps) => {
  return <div {...props} />;
})`
  display: flex;
  ${({ direction = "vertical", gap = "md", items, justify }: SpacingProps) => ({
    flexDirection: direction === "vertical" ? "column" : "row",
    gap: spacings[gap],
    justifyContent: justify,
    alignItems: items,
  })};
`;
