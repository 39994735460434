import { ReactNode, createContext, useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { colors } from "@unlikelyai-magic/ui/variables";

const dialogClassName = css`
  position: relative;
  z-index: 1010;
`;

const Overlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: ${colors.gray["500"]};
  opacity: 0.75;
  inset: 0;
  z-index: 1000;
`;

const ModalContainer = styled(Dialog.Panel)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  padding: 1.5rem;
`;

export type ModalContext = {
  openModal: (component: ReactNode) => void;
  closeModal: () => void;
  isOpen: boolean;
};

export const ModalContext = createContext<ModalContext>({
  openModal: () => null,
  closeModal: () => null,
  isOpen: false,
});

type ModalProviderProps = {
  children: ReactNode;
};

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modal, setModal] = useState<ReactNode>();

  const isOpen = !!modal;

  const openModal = (modal: ReactNode): void => setModal(modal);

  const closeModal = (): void => setModal(undefined);

  return (
    <ModalContext.Provider
      value={{
        openModal,
        closeModal,
        isOpen,
      }}
    >
      {children}
      <Dialog open={isOpen} onClose={closeModal} className={dialogClassName}>
        <Overlay onClick={closeModal} />
        <ModalContainer>{modal}</ModalContainer>
      </Dialog>
    </ModalContext.Provider>
  );
};
