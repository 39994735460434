import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Select } from "antd";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";
import { ChatSecondaryButton } from "./ChatSecondaryButton";

export type DropdownOption = {
  value: string;
  label: string;
  subLabel?: string;
  disabled: boolean;
};

type DropdownResponseProps = {
  submitText?: string;
  cancelText?: string;
  onSubmit: (option?: string) => void;
  fetchDropdownOptions: (query: string) => Promise<DropdownOption[]>;
};

const SelectComponent = styled(Select)`
  width: 27rem;
  .ant-select-selector {
    border-radius: 1.5rem !important;
    height: 2.5rem !important;
    padding: 0.25rem 1rem !important;
  }
  .ant-select-selection-search {
    height: 2.5rem !important;
    padding: 0.2rem 0.25rem !important;
  }
  .ant-select-arrow {
    padding: 0.25rem !important;
  }
  .ant-select-selector:hover,
  .ant-select-selector:focus-within {
    border: 1px solid ${({ theme }) => theme.colors.mili.plum} !important;
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.colors.mili.plum + hexAlphaValue(20)} !important;
  }
  .ant-select-selection-placeholder {
    ::selection {
      background-color: ${({ theme }) =>
        theme.colors.brand.primary + hexAlphaValue(80)};
      color: ${({ theme }) => theme.colors.text.dark};
    }
  }
`;

const CountryText = styled.div`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export const DropdownResponse = ({
  cancelText,
  onSubmit,
  submitText,
  fetchDropdownOptions,
}: DropdownResponseProps) => {
  const [selectedOption, setSelectedOption] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [options, setOptions] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (!searchValue) {
      setOptions([
        {
          value: "start-typing",
          label: "Start typing a location to see options",
          disabled: true,
        },
      ]);
      return;
    }

    fetchDropdownOptions(searchValue).then((fetchedOptions) => {
      setOptions(
        fetchedOptions.length > 0
          ? fetchedOptions
          : [
              {
                value: "No results found",
                label: "No results found",
                disabled: true,
              },
            ]
      );
    });
  }, [searchValue, fetchDropdownOptions]);

  return (
    <Spacing direction="vertical">
      <SelectComponent
        showSearch
        placeholder="Select a location"
        onChange={(value) => setSelectedOption(value as string)}
        onSearch={(value) => setSearchValue(value)}
        filterOption={false}
        dropdownStyle={{ borderRadius: "1rem" }}
      >
        {options.map((option) => (
          <Select.Option
            key={option.value}
            disabled={option.disabled}
            value={option.value}
          >
            <Spacing gap="xs" direction="horizontal">
              {option.label}
              {option.subLabel && (
                <CountryText>({option.subLabel})</CountryText>
              )}
            </Spacing>
          </Select.Option>
        ))}
      </SelectComponent>
      <Spacing direction="horizontal">
        <ChatSecondaryButton
          onClick={() => selectedOption && onSubmit(selectedOption)}
          disabled={
            !selectedOption ||
            selectedOption === "No results found" ||
            selectedOption === "Error fetching data"
          }
        >
          {submitText}
        </ChatSecondaryButton>
        {cancelText && onSubmit ? (
          <ChatSecondaryButton onClick={() => onSubmit()}>
            {cancelText}
          </ChatSecondaryButton>
        ) : null}
      </Spacing>
    </Spacing>
  );
};
