import { useContext } from "react";
import { CreateJobOpeningChatContext } from "../providers";

export const useCreateJobOpeningChat = (): CreateJobOpeningChatContext => {
  const value = useContext<CreateJobOpeningChatContext>(
    CreateJobOpeningChatContext
  );
  if (!value)
    throw new Error(
      "useCreateJobOpeningChat must be used within a CreateJobOpeningChatContext"
    );
  return value;
};
