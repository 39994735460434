import {
  AddMessageToMessageStyleRequest,
  AddMessageToMessageStyleResponse,
  CreateMessageStyleRequest,
  CreateMessageStyleResponse,
  GetMessageStyleRequest,
  GetMessageStyleResponse,
  ListMessageStylesResponse,
  UpdateMessageStyleRequest,
  UpdateMessageStyleResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const messageStylesApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    listMessageStyles: build.query<ListMessageStylesResponse, void>({
      query: () => ({
        url: "/message-styles",
      }),
    }),
    getMessageStyle: build.query<
      GetMessageStyleResponse,
      GetMessageStyleRequest
    >({
      query: ({ id }) => ({
        url: `/message-styles/${id}`,
      }),
    }),
    createMessageStyle: build.mutation<
      CreateMessageStyleResponse,
      CreateMessageStyleRequest
    >({
      query: (body) => ({
        url: "/message-styles",
        method: "POST",
        body,
      }),
    }),
    addMessageToMessageStyle: build.mutation<
      AddMessageToMessageStyleResponse,
      AddMessageToMessageStyleRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/message-styles/${id}/message`,
        method: "PUT",
        body,
      }),
    }),
    updateMessageStyle: build.mutation<
      UpdateMessageStyleResponse,
      UpdateMessageStyleRequest
    >({
      query: ({ id, ...body }) => ({
        url: `/message-styles/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteMessageStyle: build.mutation<void, { messageStyleId: string }>({
      query: (body) => ({
        url: `/message-styles/${body.messageStyleId}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useListMessageStylesQuery,
  useGetMessageStyleQuery,
  useCreateMessageStyleMutation,
  useAddMessageToMessageStyleMutation,
  useUpdateMessageStyleMutation,
  useDeleteMessageStyleMutation,
  useLazyGetMessageStyleQuery,
} = messageStylesApi;
