import React, { ComponentProps } from "react";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { SecondaryButton } from "@jobe/ui/buttons";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useSlideOver } from "./useSlideOver";

const Container = styled.div<{ textColor: string; backgroundColor: string }>`
  padding: 0 1rem;
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: ${({ textColor }) => textColor};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const Description = styled(Paragraph)<{ color: string }>`
  color: ${({ color }) => color};
`;

const CloseButton = styled(SecondaryButton)<{ color: string }>`
  background: none;
  border: none;
  box-shadow: none !important;
  padding: 0.25rem;
  font-size: 1.5rem;
  color: ${({ color }) => color};

  &:hover,
  &:focus,
  &:active {
    background: none;
    color: ${({ color }) => color};
  }
`;

export type SlideOverHeaderProps = ComponentProps<"div"> & {
  title: string;
  titleIcon?: React.ReactNode;
  description?: string;
  backgroundColor?: string;
  textColor?: string;
};

export const SlideOverHeader = styled(
  ({
    title,
    titleIcon,
    description,
    backgroundColor = `${colors.sky["500"]}`,
    textColor = `${colors.white}`,
    ...props
  }: SlideOverHeaderProps) => {
    const { closeSlideOver } = useSlideOver();
    return (
      <Container
        textColor={textColor}
        backgroundColor={backgroundColor}
        {...props}
      >
        <Spacing gap="2xs">
          <Spacing gap="sm" direction="horizontal">
            {titleIcon && titleIcon}
            <Dialog.Title as={Heading} level="h3">
              {title}
            </Dialog.Title>
          </Spacing>
          {description && (
            <Dialog.Description as={Description} color={textColor + "10"}>
              {description}
            </Dialog.Description>
          )}
        </Spacing>
        <div>
          <CloseButton
            icon={<XMarkIcon />}
            color={textColor}
            onClick={closeSlideOver}
          />
        </div>
      </Container>
    );
  }
)`
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  width: 100%;
`;
