import { ComponentProps, ReactNode } from "react";
import styled from "@emotion/styled";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { cardPaddingStyles } from "../styles/card.styles";

const CardTitle = styled(Heading)`
  font-size: ${({ theme }) => theme.fontSizes.md};
`;

const Description = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

export type CardHeaderProps = ComponentProps<"div"> & {
  title?: string;
  description?: string;
  children?: ReactNode | ReactNode[];
};

export const CardHeader = styled(
  ({ title, description, children, ...props }: CardHeaderProps) => {
    return (
      <div {...props}>
        {children ? (
          children
        ) : (
          <>
            <CardTitle level="h3">{title}</CardTitle>
            {description && <Description>{description}</Description>}
          </>
        )}
      </div>
    );
  }
)`
  ${cardPaddingStyles};
`;
