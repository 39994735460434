import { createContext, useContext } from "react";
import { ApplicationConfig } from "../types";

export const Context = createContext<ApplicationConfig>(
  {} as ApplicationConfig
);

export const useConfig = (): ApplicationConfig => {
  const config = useContext<ApplicationConfig>(Context);
  if (!config) throw new Error("useConfig must be used within a ConfigContext");
  return config;
};
