import { ReactNode, createContext, useEffect, useState } from "react";
import { css } from "@emotion/css";
import styled from "@emotion/styled";
import { Dialog } from "@headlessui/react";
import { colors } from "@unlikelyai-magic/ui/variables";

const dialogClassName = css`
  position: relative;
  z-index: 60;
`;

const Overlay = styled.div`
  position: fixed;
  background: ${colors.gray["500"]};
  opacity: 0.75;
  inset: 0;
`;

export type SlideOverContext = {
  openSlideOver: (component: ReactNode) => void;
  closeSlideOver: () => void;
};

export const SlideOverContext = createContext<SlideOverContext>({
  openSlideOver: () => null,
  closeSlideOver: () => null,
});

type SlideOverProviderProps = {
  children: ReactNode;
};

export const SlideOverProvider = ({ children }: SlideOverProviderProps) => {
  const [slideOver, setSlideOver] = useState<ReactNode>();

  const openSlideOver = (slideOver: ReactNode): void => setSlideOver(slideOver);

  const closeSlideOver = (): void => setSlideOver(undefined);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        !!slideOver &&
        (event.key === "ArrowLeft" || event.key === "ArrowRight")
      ) {
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, true);

    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [slideOver]);

  return (
    <SlideOverContext.Provider
      value={{
        openSlideOver,
        closeSlideOver,
      }}
    >
      {children}
      {slideOver && (
        <Dialog open onClose={closeSlideOver} className={dialogClassName}>
          <Overlay onClick={closeSlideOver} />
          <Dialog.Panel>{slideOver}</Dialog.Panel>
        </Dialog>
      )}
    </SlideOverContext.Provider>
  );
};
