import styled from "@emotion/styled";
import { WhiteButton, WhiteButtonProps } from "@jobe/ui/buttons";

export const ChatSecondaryButton = styled((props: WhiteButtonProps) => (
  <WhiteButton {...props} />
))`
  height: fit-content;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-width: 10rem;
  border: 1px solid ${({ theme }) => theme.colors.mili.plum};

  &:hover,
  &:focus,
  &:active,
  &.ant-btn-loading {
    color: ${({ theme }) => theme.colors.text.dark};
    background: ${({ theme }) => theme.colors.mili.plum};
    border: 1px solid ${({ theme }) => theme.colors.mili.plum};
  }
`;
