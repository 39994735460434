import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { cardPaddingStyles } from "../styles/card.styles";
import { CardHeader } from "./CardHeader";

export const CardBody = styled((props: ComponentProps<"div">) => {
  return <div {...props} />;
})`
  ${cardPaddingStyles};

  ${CardHeader} + & {
    padding-top: 0;
  }

  &:not(:last-child) {
    padding-bottom: 0;
  }
`;
