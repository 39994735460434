import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  JobPosting,
  JobSpecificationRequirement,
} from "@jobe/data-access/api-types";
import { StarsNoBackground } from "@jobe/ui/mili-logos";
import { SlideOver } from "@jobe/ui/slide-overs";
import { theme } from "@jobe/ui/theme";
import { EditRequirementsContent } from "./EditRequirementsContent";

const SlideOverContent = styled(SlideOver.Content)`
  overflow: hidden;
  height: 100%;
`;

type EditRequirementsSlideOverProps = {
  jobPosting: JobPosting;
  onSubmit: (requirements: JobSpecificationRequirement[]) => Promise<void>;
};

export const EditRequirementsSlideOver = ({
  jobPosting,
  onSubmit,
}: EditRequirementsSlideOverProps) => {
  const [requirements, setRequirements] = useState<
    JobSpecificationRequirement[]
  >([]);

  useEffect(() => {
    if (!jobPosting.specification) return;
    setRequirements(jobPosting.specification.requirements);
  }, [jobPosting.specification]);

  return (
    <SlideOver>
      <SlideOver.Header
        title="Edit requirements"
        titleIcon={<StarsNoBackground size="2rem" />}
        textColor={theme.colors.text.dark}
        backgroundColor={theme.colors.mili.plum}
      />
      <SlideOverContent>
        <EditRequirementsContent
          onSubmit={onSubmit}
          requirements={requirements}
          setRequirements={setRequirements}
        />
      </SlideOverContent>
    </SlideOver>
  );
};
