import { MutationTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from "@reduxjs/toolkit/query";
import {
  FindRequirementByNaturalLanguageOrCreateRequest,
  FindRequirementByNaturalLanguageOrCreateResponse,
  GetExtractedRequirementsRequest,
  GetExtractedRequirementsResponse,
  GetRequirementsSearchRequest,
  GetRequirementsSearchResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const jobRequirementApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    getRequirementsSearch: build.query<
      GetRequirementsSearchResponse,
      GetRequirementsSearchRequest
    >({
      query: (params) => ({
        url: `/requirements?query=${params.query}`,
      }),
    }),
    extractRequirements: build.mutation<
      GetExtractedRequirementsResponse,
      GetExtractedRequirementsRequest
    >({
      query: (body) => ({
        url: `/requirements:extract`,
        method: "POST",
        body: body,
      }),
    }),
    findRequirementByNaturalLanguageOrCreate: build.mutation<
      FindRequirementByNaturalLanguageOrCreateResponse,
      FindRequirementByNaturalLanguageOrCreateRequest
    >({
      query: (body) => ({
        url: `/requirements`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export type FindRequirementByNaturalLanguageOrCreate = MutationTrigger<
  MutationDefinition<
    FindRequirementByNaturalLanguageOrCreateRequest,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      object,
      FetchBaseQueryMeta
    >,
    "JobSpecification" | "Match",
    FindRequirementByNaturalLanguageOrCreateResponse,
    "jobeServiceApi"
  >
>;

export const {
  useGetRequirementsSearchQuery,
  useLazyGetRequirementsSearchQuery,
  useFindRequirementByNaturalLanguageOrCreateMutation,
  useExtractRequirementsMutation,
} = jobRequirementApi;
