import styled from "@emotion/styled";
import * as Headless from "@headlessui/react";
import { Icon, IconProps } from "@unlikelyai-magic/ui/icons";
import { colors } from "@unlikelyai-magic/ui/variables";

export const Thumb = styled.div<{ checked: SwitchProps["checked"] }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.gray["200"]};
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  transform: translateX(${({ checked }) => (checked ? 1.85 : 0.25)}rem);
  transition: all 0.2s ease-in-out;
`;

export type SwitchProps = Headless.SwitchProps<"input"> & {
  icon?: IconProps["icon"];
};

export const Switch = styled(({ checked, icon, ...props }: SwitchProps) => {
  return (
    <Headless.Switch {...props} checked={checked}>
      <Thumb checked={checked}>
        {icon && <Icon icon={icon} size="1.25rem" />}
      </Thumb>
    </Headless.Switch>
  );
})`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray["200"]};
  border-radius: 2rem;
  width: 4.25rem;
  height: 2.5rem;
  padding: 0;
  box-sizing: border-box;
  cursor: pointer;
`;
