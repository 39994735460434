import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "../../types/buttonProps.types";
import { Button } from "../Button/Button";

export type TransparentButton = ButtonProps;

export const TransparentButton = styled(
  ({ size, ...props }: TransparentButton) => <Button {...props} type="text" />
)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    background: none !important;

    &:hover,
    &:active,
    &.ant-btn-loading {
      color: ${theme.colors.brand.primary};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.tertiary};
    }
  `}
`;
