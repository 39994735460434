import React from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { JobPosting, Match } from "@jobe/data-access/api-types";
import { LoadingIcon } from "@jobe/ui/loading";
import { MiliLogo } from "@jobe/ui/mili-logos";
import { SlideOver } from "@jobe/ui/slide-overs";
import { theme } from "@jobe/ui/theme";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useGetMatchSummaryQuery } from "../apis";
import { MatchAnalysisList } from "./MatchAnalysisList";
import { MatchSummaryKeyPoints } from "./MatchSummaryKeyPoints";

type MatchAnalysisSlideOverProps = {
  jobPosting: JobPosting;
  match: Match;
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const LogoContainer = styled.div`
  width: 4.453125rem;
  height: 2.5rem;
  margin-top: -0.75rem;
`;

const TitleText = styled(Heading)`
  padding-top: 0.25rem;
  margin-left: -0.125rem;
`;

const SlideOverContent = styled(SlideOver.Content)`
  overflow-y: auto;
  margin-bottom: 1rem;
`;

export const MatchAnalysisSlideOver = ({
  jobPosting,
  match,
}: MatchAnalysisSlideOverProps) => {
  const requirements = jobPosting.specification?.requirements || [];
  const mustHaves = requirements.filter((r) => r.mustHave);
  const niceToHaves = requirements.filter((r) => !r.mustHave);
  const explanation = match.explanations.find(
    (explanation) =>
      explanation.specificationId === jobPosting.specification?.id
  );
  const { data, isFetching } = useGetMatchSummaryQuery({
    jobPostingId: jobPosting.id,
    matchId: match.id,
  });

  return (
    <SlideOver>
      <SlideOver.Header
        title=""
        titleIcon={
          <TitleContainer>
            <LogoContainer>
              <MiliLogo color={theme.colors.text.dark} />
            </LogoContainer>
            <TitleText level="h3">'s match analysis</TitleText>
          </TitleContainer>
        }
        textColor={theme.colors.text.dark}
        backgroundColor={theme.colors.brand.primary}
      />
      <SlideOverContent>
        {explanation && (
          <Spacing gap="lg">
            {isFetching ? (
              <Spacing
                gap="sm"
                direction="horizontal"
                items="center"
                justify="center"
              >
                <LoadingIcon />
                <Paragraph>Summarising Mili's analysis...</Paragraph>
              </Spacing>
            ) : (
              <MatchSummaryKeyPoints summary={data?.data || ""} />
            )}
            <Heading level="h5">Your must have requirements</Heading>
            <MatchAnalysisList
              explanation={explanation}
              requirements={mustHaves}
            />
            <Heading level="h5">Your nice to have requirements</Heading>
            <MatchAnalysisList
              explanation={explanation}
              requirements={niceToHaves}
            />
          </Spacing>
        )}
      </SlideOverContent>
    </SlideOver>
  );
};
