import { useEffect, useState } from "react";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { WhiteButtonProps } from "@jobe/ui/buttons";
import { ChatPrimaryButton } from "./ChatPrimaryButton";
import { ChatSecondaryButton } from "./ChatSecondaryButton";

const YesNoButton = ({
  active,
  ...props
}: WhiteButtonProps & { active?: boolean }) => {
  return active ? (
    <ChatPrimaryButton {...props} />
  ) : (
    <ChatSecondaryButton {...props} />
  );
};

type YesNoChatMessageProps = {
  onSubmit: (value: boolean) => void;
};

export const YesNoChatWidget = ({ onSubmit }: YesNoChatMessageProps) => {
  const [value, setValue] = useState<boolean>();

  useEffect(() => {
    if (value === undefined) return;
    onSubmit(value);
  }, [value]);

  return (
    <Spacing direction="horizontal" justify="left">
      <YesNoButton active={value} onClick={() => setValue(true)}>
        Yes
      </YesNoButton>
      <YesNoButton active={value === false} onClick={() => setValue(false)}>
        No
      </YesNoButton>
    </Spacing>
  );
};
