import { ReactElement, ReactNode, createContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Auth0Provider } from "@auth0/auth0-react";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { UserProfile } from "@jobe/data-access/api-types";
import { useConfig } from "@jobe/ui/features/configs";
import { useGetUserProfileQuery } from "@jobe/ui/features/users";
import { AuthTokenManager } from "../components";
import { selectAccessToken } from "../selectors";

export interface AuthContext {
  accessToken?: string;
  profile?: UserProfile;
}

export const Context = createContext<AuthContext>({});

type AuthProviderProps = {
  children?: ReactNode | ReactNode[];
};

export const AuthProvider = ({ children }: AuthProviderProps): ReactElement => {
  const config = useConfig();
  const accessToken = useSelector(selectAccessToken);
  const { data: userProfile } = useGetUserProfileQuery(undefined, {
    skip: !accessToken,
  });
  const { identify, people } = useMetrics();

  useEffect(() => {
    if (!userProfile?.data) return;
    identify(userProfile.data.id);
    people.set({
      name: userProfile.data.firstName,
      ...userProfile.data,
    });
  }, [userProfile?.data]);

  return (
    <Context.Provider value={{ accessToken, profile: userProfile?.data }}>
      <Auth0Provider
        useRefreshTokens
        cacheLocation="localstorage"
        domain={config.AUTH0_DOMAIN}
        clientId={config.AUTH0_CLIENT_ID}
        authorizationParams={{
          audience: config.AUTH0_API_IDENTIFIER,
          redirect_uri: `${window.location.origin}/sso/callback`,
          screen_hint: "signup",
        }}
      >
        <AuthTokenManager>{children}</AuthTokenManager>
      </Auth0Provider>
    </Context.Provider>
  );
};
