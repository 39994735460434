import { Navigate, Route, Routes } from "react-router-dom";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import {
  Auth0CallbackPage,
  LogoutPage,
  Restricted,
} from "@jobe/ui/features/authentication";
import { DashboardPage } from "@jobe/ui/features/dashboard";
import {
  DiscoverMatchesPage,
  JobPostingPageLayout,
  JobPostingProvider,
  MatchCategoryPage,
  MatchesProvider,
} from "@jobe/ui/features/jobPostings";
import {
  RestrictedWaitlistOnly,
  WaitlistSignupPage,
} from "@jobe/ui/features/waitlist";
import { AppLayout, AppNavbarLayout } from "@jobe/ui/layouts";
import { config } from "../config";

const AppRouter = () => {
  return (
    <Routes>
      <Route element={<AppLayout />}>
        <Route path="sso">
          <Route path="callback" element={<Auth0CallbackPage />} />
        </Route>
        <Route element={<Restricted />}>
          <Route path="waitlist" element={<WaitlistSignupPage />} />
          <Route path="logout" element={<LogoutPage />} />

          <Route element={<RestrictedWaitlistOnly />}>
            <Route element={<AppNavbarLayout />}>
              <Route path="" element={<DashboardPage />} />
            </Route>

            <Route path="openings">
              <Route path=":jobPostingId" element={<JobPostingProvider />}>
                <Route element={<MatchesProvider />}>
                  <Route element={<JobPostingPageLayout />}>
                    <Route path="matches/*" element={<DiscoverMatchesPage />} />
                    <Route
                      path="seen/*"
                      element={<MatchCategoryPage matchCategory="seen" />}
                    />
                    <Route
                      path="likes/*"
                      element={<MatchCategoryPage matchCategory="liked" />}
                    />
                    <Route index element={<Navigate replace to="matches" />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<Navigate replace to="/" />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default withLDProvider({
  clientSideID: config.DARKLY_CLIENT_TOKEN ?? "",
})(AppRouter);
