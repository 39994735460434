import { ComponentProps } from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";

const Main = styled.main`
  flex: 1;
`;

export const AppLayout = styled((props: ComponentProps<"div">) => {
  return (
    <div {...props}>
      <Main>
        <Outlet />
      </Main>
    </div>
  );
})`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background.primary};
`;
