import React, { useState } from "react";
import styled from "@emotion/styled";
import { Input, SelectOption } from "@unlikelyai-magic/ui/inputs";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { PrimaryButton } from "@jobe/ui/buttons";
import { CountrySelector, RoleSelector } from "@jobe/ui/inputs";
import { Paragraph } from "@jobe/ui/typography";

const ErrorMessage = styled(Paragraph)`
  margin-top: 0.5rem;
  color: ${colors.red["400"]};
`;

export interface WaitlistFormData {
  firstName: string;
  lastName: string;
  companyName: string;
  rolesOfInterest: SelectOption[];
  locationsOfInterest: SelectOption[];
}

interface FormErrors {
  [key: string]: string;
}
type WaitlistFormProps = {
  submitting?: boolean;
  initialFormData?: Partial<WaitlistFormData>;
  onSubmit: (data: WaitlistFormData) => void;
};

export const WaitlistForm = ({
  submitting,
  initialFormData,
  onSubmit,
}: WaitlistFormProps) => {
  const [waitlistFormData, setWaitlistFormData] = useState<WaitlistFormData>({
    firstName: "",
    lastName: "",
    companyName: "",
    rolesOfInterest: [],
    locationsOfInterest: [],
    ...initialFormData,
  });

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setWaitlistFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    // Clear errors for a particular field when it is changed
    if (formErrors[name]) {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const validateForm = () => {
    const errors: FormErrors = {};
    if (!waitlistFormData.firstName.trim())
      errors.firstName = "First name is required.";
    if (!waitlistFormData.lastName.trim())
      errors.lastName = "Last name is required.";
    if (!waitlistFormData.companyName.trim())
      errors.companyName = "Company name is required.";
    if (waitlistFormData.rolesOfInterest.length === 0)
      errors.rolesOfInterest = "Roles hiring for is required.";
    if (waitlistFormData.locationsOfInterest.length === 0)
      errors.locationsOfInterest = "A country is required.";

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      onSubmit(waitlistFormData);
    }
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <Spacing gap="xl">
        <Spacing gap="md">
          {!initialFormData?.firstName && (
            <label>
              First name
              <Input
                type="text"
                name="firstName"
                placeholder="Enter your first name"
                value={waitlistFormData.firstName}
                onChange={handleChange}
              />
              {formErrors.firstName && (
                <ErrorMessage>{formErrors.firstName}</ErrorMessage>
              )}
            </label>
          )}

          {!initialFormData?.lastName && (
            <label>
              Last name
              <Input
                type="text"
                name="lastName"
                placeholder="Enter your last name"
                value={waitlistFormData.lastName}
                onChange={handleChange}
              />
              {formErrors.lastName && (
                <ErrorMessage>{formErrors.lastName}</ErrorMessage>
              )}
            </label>
          )}

          <label>
            Company name
            <Input
              type="text"
              name="companyName"
              placeholder="What is your company called?"
              value={waitlistFormData.companyName}
              onChange={handleChange}
            />
            {formErrors.companyName && (
              <ErrorMessage>{formErrors.companyName}</ErrorMessage>
            )}
          </label>

          <label>
            Who are you hiring?
            <RoleSelector
              multi
              name="rolesOfInterest"
              value={waitlistFormData.rolesOfInterest}
              placeholder="Select all relevant roles"
              onChange={(value?: SelectOption[]) =>
                setWaitlistFormData((prevState) => ({
                  ...prevState,
                  rolesOfInterest: value || [],
                }))
              }
            />
            {formErrors.rolesOfInterest && (
              <ErrorMessage>{formErrors.rolesOfInterest}</ErrorMessage>
            )}
          </label>

          <label>
            Which country are you hiring into?
            <CountrySelector
              multi
              name="locationsOfInterest"
              value={waitlistFormData.locationsOfInterest}
              placeholder="Select all relevant countries"
              onChange={(value?: SelectOption[]) =>
                setWaitlistFormData((prevState) => ({
                  ...prevState,
                  locationsOfInterest: value || [],
                }))
              }
            />
            {formErrors.locationsOfInterest && (
              <ErrorMessage>{formErrors.locationsOfInterest}</ErrorMessage>
            )}
          </label>
        </Spacing>
        <PrimaryButton htmlType="submit" loading={submitting} size="xl">
          Join the waitlist
        </PrimaryButton>
      </Spacing>
    </form>
  );
};
