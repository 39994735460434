import { ComponentProps } from "react";

export const SeenProfileIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2943_6779)">
        <path
          d="M0.96875 11.2812C0.969346 10.6266 1.12211 9.98114 1.41497 9.39576C1.70783 8.81038 2.13278 8.30109 2.65625 7.90813C3.17972 7.51516 3.78738 7.24927 4.43124 7.13146C5.07511 7.01365 5.73755 7.04713 6.36625 7.22928"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.60938 3.54688C2.60938 4.23064 2.881 4.88639 3.36449 5.36988C3.84798 5.85338 4.50374 6.125 5.1875 6.125C5.87126 6.125 6.52702 5.85338 7.01051 5.36988C7.494 4.88639 7.76563 4.23064 7.76562 3.54688C7.76563 2.86311 7.494 2.20736 7.01051 1.72387C6.52702 1.24037 5.87126 0.96875 5.1875 0.96875C4.50374 0.96875 3.84798 1.24037 3.36449 1.72387C2.881 2.20736 2.60938 2.86311 2.60938 3.54688Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.0625 10.3438C7.0625 11.214 7.4082 12.0486 8.02356 12.6639C8.63891 13.2793 9.47351 13.625 10.3438 13.625C11.214 13.625 12.0486 13.2793 12.6639 12.6639C13.2793 12.0486 13.625 11.214 13.625 10.3438C13.625 9.47351 13.2793 8.63891 12.6639 8.02356C12.0486 7.4082 11.214 7.0625 10.3438 7.0625C9.47351 7.0625 8.63891 7.4082 8.02356 8.02356C7.4082 8.63891 7.0625 9.47351 7.0625 10.3438Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.0316 15.0311L12.6641 12.6636"
          stroke="#85808A"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2943_6779">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
