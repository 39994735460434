import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { SecondaryButton } from "@unlikelyai-magic/ui/buttons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { PageLayout } from "@jobe/ui/layouts";
import { GreetChatMessage } from "../components";

const Content = styled(Spacing)`
  padding: 3rem 1.5rem;
  width: 100%;

  ${SecondaryButton} {
    flex: 1;
    padding: 1rem;
  }
`;

export const DashboardPage = styled(({ className }: ComponentProps<"div">) => {
  return (
    <PageLayout className={className}>
      <Content gap="3xl">
        <GreetChatMessage />
      </Content>
    </PageLayout>
  );
})`
  display: flex;
  flex-direction: column;
`;
