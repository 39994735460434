import React from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@jobe/ui/typography";
import { LoadingIcon } from "./LoadingIcon";

const ContentContainer = styled(Spacing)`
  justify-content: center;
  align-items: center;
  flex: 1;
  min-height: 75vh;
`;

type LoadingPageProps = {
  message?: string;
};

export const LoadingPage = ({ message }: LoadingPageProps) => {
  return (
    <ContentContainer gap="md">
      <LoadingIcon size="4rem" />
      {message && <Paragraph>{message}</Paragraph>}
    </ContentContainer>
  );
};
