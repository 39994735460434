import { DragDropContext, DropResult } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import { StrictModeDroppable } from "@jobe/ui/layouts";
import { RequirementListItem } from "./RequirementListItem";
import { SplitList } from "./SplitList";

const SplitView = styled(Spacing)`
  position: relative;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`;

type RequirementListProps = {
  mode: "combined" | "split";
  value: JobSpecificationRequirement[];
  onChange: (value: JobSpecificationRequirement[]) => void;
  disabled?: boolean;
};

export const RequirementList = ({
  mode,
  value,
  onChange,
  disabled,
}: RequirementListProps) => {
  const handleDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      // Dragged to nowhere. Maybe we can delete?
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      const update = value.map((item) => {
        if (item.id === result.draggableId) {
          // Update the item, toggling the mustHave property
          return { ...item, mustHave: !item.mustHave };
        }
        return item;
      });
      onChange(update);
      return;
    }

    const element = value.find((item) => item.id === result.draggableId);
    if (!element) return;

    const update = [...value];
    const startIndex = source.index;
    const endIndex = destination.index;
    const [removed] = update.splice(startIndex, 1);
    update.splice(endIndex, 0, removed);
    onChange(update);
    return;
  };

  const handleCreate = (newValue: JobSpecificationRequirement) => {
    onChange([...value, newValue]);
  };

  const renderCombinedList = () => {
    return (
      <StrictModeDroppable droppableId="mustHave">
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {value.map((entity, index) => (
              <RequirementListItem
                key={entity.id}
                value={entity}
                index={index}
                onRemove={() => {
                  onChange(value.filter((item) => item.id !== entity.id));
                }}
                disabled={disabled}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </StrictModeDroppable>
    );
  };

  const renderSplitList = () => {
    return (
      <SplitView>
        <SplitList
          title="Must haves"
          droppableId="mustHave"
          requirements={value.filter((item) => item.mustHave)}
          onChange={(changed) =>
            onChange([...changed, ...value.filter((item) => !item.mustHave)])
          }
          onCreate={handleCreate}
          disabled={disabled}
        />
        <SplitList
          title="Nice to haves"
          droppableId="niceToHave"
          requirements={value.filter((item) => !item.mustHave)}
          onChange={(changed) =>
            onChange([...value.filter((item) => item.mustHave), ...changed])
          }
          onCreate={(newValue) =>
            handleCreate({ ...newValue, mustHave: false })
          }
          disabled={disabled}
        />
      </SplitView>
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {mode === "combined" && renderCombinedList()}
      {mode === "split" && renderSplitList()}
    </DragDropContext>
  );
};
