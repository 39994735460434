import React, { ComponentProps } from "react";
import styled from "@emotion/styled";
import { LogoPlaceholder } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { capitalizeFirstLetter } from "@unlikelyai-magic/utils";
import { JobExperience } from "@jobe/data-access/api-types";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { Employment } from "../types";
import { resolveFallbackCompanyImage } from "../utils/resolveFallbackCompanyImage";
import { MatchSkillCategories } from "./MatchSkillCategories";

const Title = styled(Heading)`
  line-height: 1;
`;

const DescriptionList = styled.ul`
  padding-left: 1rem;
  margin: 0;
`;

const CompanyLogo = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
`;

type JobExperienceListItemProps = ComponentProps<"div"> & {
  employment: Employment;
};

export const JobExperienceListItem = styled(
  ({ employment, className }: JobExperienceListItemProps) => {
    const isMultiplePositions = employment.positions.length > 1;
    const companyLogoUrl =
      employment.companyLogoUrl ||
      resolveFallbackCompanyImage(employment.company);

    const renderPosition = (position: JobExperience) => {
      if (isMultiplePositions) {
        return (
          <Spacing gap="3xs">
            <Title level="h5" color={colors.gray["600"]}>
              {position.title ?? "unknown title"}
            </Title>
            <Paragraph>{position.company ?? "unknown company"}</Paragraph>
            {position.fromYear ? (
              <Paragraph small color={colors.gray["400"]}>
                {`${position.fromYear} - ${position.toYear ?? "present"}`}
              </Paragraph>
            ) : null}
          </Spacing>
        );
      }

      return (
        <Spacing gap="3xs">
          <Title level="h5">{position.title ?? "unknown title"}</Title>
          <Paragraph>{position.company ?? "unknown company"}</Paragraph>
          {position.fromYear ? (
            <Paragraph small color={colors.gray["400"]}>
              {`${position.fromYear} - ${position.toYear ?? "present"}`}
            </Paragraph>
          ) : null}
        </Spacing>
      );
    };

    return (
      <Spacing gap="lg" direction="horizontal" className={className}>
        {companyLogoUrl ? (
          <CompanyLogo src={companyLogoUrl} alt={employment.company} />
        ) : (
          <LogoPlaceholder />
        )}
        <Spacing gap="sm">
          {isMultiplePositions && (
            <Title level="h5">{employment.company}</Title>
          )}
          {employment.positions.map((jobExperience) => (
            <Spacing gap="sm">
              {renderPosition(jobExperience)}
              {jobExperience.descriptions.length > 0 && (
                <DescriptionList>
                  {jobExperience.descriptions.map((description) => (
                    <li key={description}>
                      <Paragraph small>
                        {capitalizeFirstLetter(description)}
                      </Paragraph>
                    </li>
                  ))}
                </DescriptionList>
              )}
              {jobExperience.skills.length > 0 && (
                <MatchSkillCategories skills={jobExperience.skills} />
              )}
            </Spacing>
          ))}
        </Spacing>
      </Spacing>
    );
  }
)`
  padding: 0.375rem 0;

  ${LogoPlaceholder} {
    flex-shrink: 0;
  }
`;
