import { MessageStyle } from "@jobe/data-access/api-types";
import { SelectOption } from "../components/MessageStyleSelect";

/**
 * Get the default message style for the user by the following method:
 * 1. If the user has a preferred message style, return that
 * 2. If the user has no preferred message style, return the style of the most recently copied message
 * 3. If the user has no preferred message style and has not yet copied any messages, return "informal"
 */
export const getDefaultMessageStyle = (
  preferredStyleId: string | undefined,
  messageStyles: MessageStyle[]
): SelectOption => {
  const preferredStyle = preferredStyleId
    ? messageStyles.find((style) => style.id === preferredStyleId)
    : null;
  if (preferredStyle) {
    return {
      label: preferredStyle.name,
      value: preferredStyle.name,
      id: preferredStyle.id,
      description: preferredStyle.description,
    };
  }
  const latestCopy = messageStyles.reduce(
    (accLatestCopy, curStyle) => {
      if (curStyle.messages.length === 0) return accLatestCopy;
      const curStyleLatestCopiedAt = new Date(
        curStyle.messages[curStyle.messages.length - 1].copiedAt
      );
      if (curStyleLatestCopiedAt > accLatestCopy.latestCopiedAt) {
        return {
          styleName: curStyle.name,
          latestCopiedAt: curStyleLatestCopiedAt,
          id: curStyle.id,
          description: curStyle.description,
        };
      }
      return accLatestCopy;
    },
    {
      styleName: messageStyles[0].name,
      latestCopiedAt: new Date(1970, 1, 1),
      id: messageStyles[0].id,
      description: messageStyles[0].description,
    }
  );
  return {
    label: latestCopy.styleName,
    value: latestCopy.styleName,
    id: latestCopy.id,
    description: latestCopy.description,
  };
};
