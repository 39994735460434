import {
  ConfigureStoreOptions,
  PayloadAction,
  configureStore,
  createSlice,
} from "@reduxjs/toolkit";
import { jobeServiceApi } from "./jobeServiceApi";

export type AuthenticationSliceState = {
  accessToken?: string;
};

export type ConfigSliceState = {
  jobeApiBaseUrl: string;
};

const initialAuthenticationState: AuthenticationSliceState = {
  accessToken: undefined,
};

const initialConfigState: ConfigSliceState = {
  jobeApiBaseUrl: "",
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: initialAuthenticationState,
  reducers: {
    setAccessToken: (
      state: AuthenticationSliceState,
      action: PayloadAction<string | undefined>
    ) => {
      state.accessToken = action.payload;
      return state;
    },
  },
});

export const configSlice = createSlice({
  name: "config",
  initialState: initialConfigState,
  reducers: {
    setJobeApiBaseUrl: (
      state: ConfigSliceState,
      action: PayloadAction<string>
    ) => {
      state.jobeApiBaseUrl = action.payload;
      return state;
    },
  },
});

export const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      [configSlice.name]: configSlice.reducer,
      [authenticationSlice.name]: authenticationSlice.reducer,
      [jobeServiceApi.reducerPath]: jobeServiceApi.reducer,
    },
    devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(jobeServiceApi.middleware),
    ...options,
  });

export const store = createStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export const { setAccessToken } = authenticationSlice.actions;
export const { setJobeApiBaseUrl } = configSlice.actions;
