import { MutableRefObject, useEffect, useRef, useState } from "react";

/**
 * This is a helpful hook to get the dimensions of a component, in case it is needed for any styling logic
 * @returns {ref, width, height} - A ref to attach to the component, and the width and height of the component
 */
export const useComponentSize = (): {
  ref: MutableRefObject<null>;
  width: number;
  height: number;
} => {
  const ref = useRef(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observeTarget = ref.current;
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setSize({
          width: entry.contentRect.width,
          height: entry.contentRect.height,
        });
      });
    });

    if (observeTarget) {
      resizeObserver.observe(observeTarget);
    }

    return () => {
      if (observeTarget) {
        resizeObserver.unobserve(observeTarget);
      }
    };
  }, [ref]);

  return { ref, ...size };
};
