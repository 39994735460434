import { ReactElement } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Message, useChat } from "@unlikelyai-magic/ui/chat";
import { ChatContainer } from "./ChatContainer";
import { ChatMessage } from "./ChatMessage";

export const ChatMessages = () => {
  const { messages } = useChat();

  const animateMessage = (message: Message): ReactElement => {
    return (
      <motion.div
        key={message.id}
        initial={{ opacity: 0, translateY: -24 }}
        animate={{ opacity: 1, translateY: 0 }}
      >
        {<ChatMessage message={message} />}
      </motion.div>
    );
  };

  return (
    <ChatContainer>
      <AnimatePresence mode="popLayout">
        {messages.map(animateMessage)}
      </AnimatePresence>
    </ChatContainer>
  );
};
