import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import { ThemeProvider } from "@emotion/react";
import Hotjar from "@hotjar/browser";
import { MetricsProvider } from "@unlikelyai-magic/metrics/mixpanel";
import { AuthProvider } from "@jobe/ui/features/authentication";
import { ConfigProvider } from "@jobe/ui/features/configs";
import { ModalProvider } from "@jobe/ui/modals";
import { SlideOverProvider } from "@jobe/ui/slide-overs";
import { setJobeApiBaseUrl } from "@jobe/ui/store";
import { theme } from "@jobe/ui/theme";
import { config } from "./config";
import AppRouter from "./routers/AppRouter";

const HOTJAR_VERSION = 6;

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setJobeApiBaseUrl(config.JOBE_API_URL));
  }, [dispatch]);

  useEffect(() => {
    if (!config.HOTJAR_SITE_ID) return;
    Hotjar.init(config.HOTJAR_SITE_ID, HOTJAR_VERSION);
  }, []);

  useEffect(() => {
    if (!config.DATADOG_APPLICATION_ID || !config.DATADOG_CLIENT_TOKEN) return;
    datadogRum.init({
      applicationId: config.DATADOG_APPLICATION_ID,
      allowedTracingUrls: [config.JOBE_API_URL],
      clientToken: config.DATADOG_CLIENT_TOKEN,
      site: "datadoghq.eu",
      service: "mili-frontend",
      env: config.DATADOG_ENVIRONMENT,
      version: config.DATADOG_APP_VERSION || "unknown",
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
    });
  }, []);

  return (
    <ConfigProvider config={config}>
      <MetricsProvider mixpanelToken={config.MIXPANEL_TOKEN}>
        <ThemeProvider theme={theme}>
          <SlideOverProvider>
            <ModalProvider>
              <AuthProvider>
                <AppRouter />
              </AuthProvider>
            </ModalProvider>
          </SlideOverProvider>
        </ThemeProvider>
      </MetricsProvider>
    </ConfigProvider>
  );
};
