import { Spacing } from "@unlikelyai-magic/ui/layouts";
import {
  JobSpecificationRequirement,
  MatchExplanation,
} from "@jobe/data-access/api-types";
import { theme } from "@jobe/ui/theme";
import { Paragraph } from "@jobe/ui/typography";
import { MatchAnalysisListItem } from "./MatchAnalysisListItem";

type MatchAnalysisListProps = {
  requirements: JobSpecificationRequirement[];
  explanation: MatchExplanation;
};

export const MatchAnalysisList = ({
  requirements,
  explanation,
}: MatchAnalysisListProps) => {
  const findExplanation = (requirementId: string) => {
    return explanation.requirements.find(
      (r) => r.requirementId === requirementId
    );
  };

  if (!requirements.length) {
    return (
      <Paragraph textAlign="center" color={theme.colors.text.tertiary}>
        No requirements added
      </Paragraph>
    );
  }

  return (
    <Spacing>
      {requirements.map((requirement) => {
        const explanation = findExplanation(requirement.id);
        return (
          <MatchAnalysisListItem
            key={requirement.id}
            requirement={requirement}
            explanation={explanation}
          />
        );
      })}
    </Spacing>
  );
};
