import { ComponentProps } from "react";
import styled from "@emotion/styled";

type LogoPlaceholderProps = ComponentProps<"svg"> & { size?: string };

export const LogoPlaceholder = styled((props: LogoPlaceholderProps) => {
  return (
    <svg
      width={props.size ?? "40"}
      height={props.size ?? "40"}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2202_3697)">
        <path d="M0 40L0 0H40V40H0Z" fill="#F1F5F9" />
        <path
          d="M35 27.5C35 23.3579 31.6421 20 27.5 20C23.3579 20 20 23.3579 20 27.5C20 31.6421 23.3579 35 27.5 35C31.6421 35 35 31.6421 35 27.5Z"
          fill="#94A3B8"
        />
        <path
          d="M30 17.5C30 10.5964 24.4036 5 17.5 5C10.5964 5 5 10.5964 5 17.5C5 24.4036 10.5964 30 17.5 30C24.4036 30 30 24.4036 30 17.5Z"
          fill="#CBD5E1"
        />
        <path
          d="M20 27.5C19.998 28.2343 20.1033 28.9649 20.3125 29.6688C22.5913 29.1408 24.6762 27.9841 26.3301 26.3301C27.9841 24.6761 29.1409 22.5913 29.6688 20.3125C28.5477 19.9739 27.363 19.9025 26.2094 20.104C25.0558 20.3055 23.9654 20.7744 23.0256 21.473C22.0858 22.1717 21.3226 23.0807 20.7972 24.1273C20.2718 25.1739 19.9988 26.3289 20 27.5Z"
          fill="#475569"
        />
      </g>
      <defs>
        <clipPath id="clip0_2202_3697">
          <rect
            width="40"
            height="40"
            fill="white"
            transform="matrix(0 -1 1 0 0 40)"
          />
        </clipPath>
      </defs>
    </svg>
  );
})``;
