import { SerializedStyles, css } from "@emotion/react";
import { ButtonSize } from "../types/buttonSize.types";

const baseStyle = css`
  svg {
    width: 1.125em;
    height: 1.125em;
  }

  &.ant-btn-icon-only {
    width: auto;
    vertical-align: unset;
  }

  .anticon,
  .ant-btn-loading-icon {
    line-height: 0;
  }

  .anticon + span {
    margin-left: 0;
  }

  .ant-btn-loading-icon .anticon {
    padding: 0;
  }
`;

const tight = css`
  ${baseStyle};
  gap: 0.5rem;
`;

const normal = css`
  ${baseStyle};
  gap: 0.625rem;
`;

export const buttonIconStyles: Record<ButtonSize, SerializedStyles> = {
  xs: tight,
  sm: tight,
  md: normal,
  lg: normal,
  xl: normal,
};
