import { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, matchPath, useLocation, useParams } from "react-router-dom";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { ListMatchesResponse, Match } from "@jobe/data-access/api-types";
import { UUID } from "@jobe/data-access/shared-types";
import { useProfile } from "@jobe/ui/features/authentication";
import { useJobPosting } from "../../job-postings";
import { useLazyListMatchesQuery, useUpdateMatchMutation } from "../apis";
import { OutreachModal } from "../components";

const NEXT_MATCH_THRESHOLD = 8;

export interface MatchesContext {
  jobPostingId: string;
  loading: boolean;
  fetching: boolean;
  initialised: boolean;
  matches?: Match[];
  allMatchesFound: boolean;
  currentMatch?: Match;
  currentMatchIndex?: number;
  nextMatch?: () => void;
  previousMatch?: () => void;
  setCurrentMatch: (matchId: string) => void;
  likeMatch: (match: Match) => void;
  unlikeMatch: (match: Match) => void;
  openOutreachModal: (match: Match) => void;
  closeOutreachModal: () => void;
}

export const MatchesContext = createContext<MatchesContext>({
  jobPostingId: "",
  loading: true,
  fetching: true,
  initialised: false,
  matches: undefined,
  allMatchesFound: false,
  nextMatch: () => null,
  previousMatch: () => null,
  setCurrentMatch: () => null,
  likeMatch: () => null,
  unlikeMatch: () => null,
  openOutreachModal: () => null,
  closeOutreachModal: () => null,
});

export const MatchesProvider = () => {
  const profile = useProfile();
  const { jobPostingId = "" } = useParams();
  const { jobPosting, refetch: refetchJobPosting } = useJobPosting();
  const { pathname } = useLocation();
  const pathMatch = matchPath(
    {
      path: "/openings/:jobPostingId/:tab/:subtab?",
    },
    pathname
  );
  const [currentTab, setCurrentTab] = useState<string>(
    pathMatch?.params.tab as string
  );
  const [fetchMatches, { isLoading, isFetching, isSuccess, isUninitialized }] =
    useLazyListMatchesQuery();
  const [matches, setMatches] = useState<Record<UUID, Match>>({});
  const [paging, setPaging] = useState<ListMatchesResponse["pagination"]>({
    limit: 0,
    offset: 0,
    totalCount: 0,
    nextOffset: 0,
  });
  const orderedMatches = useMemo(
    () =>
      Object.values(matches).sort((a, b) => {
        const aExplanation = a.explanations.find(
          (e) => e.specificationId === jobPosting?.specificationId
        );
        const bExplanation = b.explanations.find(
          (e) => e.specificationId === jobPosting?.specificationId
        );
        if (!aExplanation?.matchedAt) {
          return 1;
        } else if (!bExplanation?.matchedAt) {
          return -1;
        }
        return (
          new Date(aExplanation.matchedAt).getTime() -
          new Date(bExplanation.matchedAt).getTime()
        );
      }),
    [jobPosting?.specificationId, matches]
  );
  const matchesCount = orderedMatches.length;
  const [outreachForMatch, setOutreachForMatch] = useState<Match>();
  const [preferredMessageStyleId, setPreferredMessageStyleId] =
    useState<string>();
  const [currentMatchId, setCurrentMatchId] = useState<UUID>();
  const [updateMatch] = useUpdateMatchMutation();
  const { track } = useMetrics();

  const [currentMatch, currentMatchIndex] = useMemo((): [
    Match | undefined,
    number
  ] => {
    const index = orderedMatches.findIndex(
      (match) => match.id === currentMatchId
    );
    if (index === -1) {
      setCurrentMatchId(orderedMatches[0]?.id);
      return [orderedMatches[0], 0];
    }
    return [orderedMatches[index], index];
  }, [orderedMatches, currentMatchId]);

  // When the user changes view, reload matches from the backend.
  useEffect(() => {
    if (!jobPosting?.specificationId) return;
    if (!pathMatch?.params.tab) return;
    if (pathMatch.params.tab === currentTab) return;
    setCurrentTab(pathMatch.params.tab);
    resetMatches();
  }, [pathMatch?.params.tab]);

  // Fetch matches on page load or when the user reaches near the end of the list
  useEffect(() => {
    if (!jobPosting?.specificationId) return;
    if (!currentMatchId) return;
    if (isFetching) return;

    // we have enough matches for them to be getting on with for now
    if (matchesCount - currentMatchIndex > NEXT_MATCH_THRESHOLD) return;

    // No more matches to find
    if (paging.nextOffset == null) return;

    fetch();
  }, [paging, currentMatchId, currentMatchIndex]);

  // When the job specification changes, reset the matches and pagination
  useEffect(() => {
    if (!jobPosting?.specificationId) return;
    resetMatches();
  }, [jobPosting?.specificationId]);

  // Mark matches as seen
  useEffect(() => {
    if (!currentMatch) return;
    if (currentMatch.seenAt) return; // If already seen, do nothing
    const seenAt = new Date().toISOString();
    track("MatchReview", "match_seen", {
      ...currentMatch,
      seenAt,
    });
    updateMatch({ ...currentMatch, seenAt });
    refetchJobPosting();
  }, [currentMatch]);

  // When the matches are loaded, set the current match to the first unseen match
  useEffect(() => {
    if (!isSuccess) return;
    if (!orderedMatches.length) return;
    const index = orderedMatches.findIndex((match) => !match.seenAt);
    if (index === -1) return;
    setCurrentMatchId(orderedMatches[index].id);
  }, [isSuccess]);

  // Set the preferred message style id when the profile is loaded
  useEffect(() => {
    if (!profile?.preferredMessageStyleId) return;
    setPreferredMessageStyleId(profile.preferredMessageStyleId);
  }, [profile?.preferredMessageStyleId]);

  const fetch = async () => {
    const filterSeen = pathname.includes("/seen");
    const filterLiked = pathname.includes("/likes");
    const isFiltering = filterSeen || filterLiked;

    const limit = isFiltering ? undefined : 5;
    const response = await fetchMatches({
      jobPostingId,
      specificationId: jobPosting?.specificationId || "",
      seen: filterSeen || undefined,
      liked: filterLiked || undefined,
      offset: paging.nextOffset ?? undefined,
      limit,
    }).unwrap();

    setMatches((prevMatches) => {
      const newMatches: Record<UUID, Match> = response.data.reduce(
        (map, match) => {
          return {
            ...map,
            [match.id]: match,
          };
        },
        {}
      );
      return {
        ...prevMatches,
        ...newMatches,
      };
    });

    setPaging(response.pagination);

    if (isFiltering) return;

    // Prevents loading more matches when there are no more matches to load
    if (
      response.pagination.totalCount <=
        response.pagination.offset + response.data.length &&
      response.pagination.nextOffset === null
    ) {
      return;
    }
  };

  const resetMatches = () => {
    setMatches({});
    setPaging({
      limit: 0,
      offset: 0,
      totalCount: 0,
      nextOffset: 0,
    });
    fetch();
  };

  const selectNextMatch = () => {
    if (!orderedMatches?.length) return;
    if (!currentMatch) return setCurrentMatchId(orderedMatches[0].id);
    const index = orderedMatches.findIndex(
      (match) => match.id === currentMatch.id
    );

    // If the match is not found, use the first match
    if (index === -1) return setCurrentMatchId(orderedMatches[0].id);

    // Ensure that the user can't go past the last match
    if (index === orderedMatches.length - 1) return;

    // Set the next match to be the current match
    setCurrentMatchId(orderedMatches[index + 1].id);
  };

  const selectPreviousMatch = () => {
    if (!orderedMatches?.length) return;
    if (!currentMatch) return setCurrentMatchId(orderedMatches[0].id);
    const index = orderedMatches.findIndex(
      (match) => match.id === currentMatchId
    );

    // If the match is not found, use the first match
    if (index === -1) return setCurrentMatchId(orderedMatches[0].id);

    // Ensure that the user can't go past the first match
    if (index === 0) return;

    // Set the previous match to be the current match
    setCurrentMatchId(orderedMatches[index - 1].id);
  };

  const handleLikeMatch = async (match: Match) => {
    const updatedMatch = await updateMatch({
      ...match,
      likedAt: new Date().toISOString(),
    }).unwrap();
    setMatches((prevMatches) => {
      return {
        ...prevMatches,
        [updatedMatch.data.id]: updatedMatch.data,
      };
    });
    track("MatchReview", "like_match_button_clicked", {
      ...match,
    });
    refetchJobPosting();
  };

  const handleUnlikeMatch = async (match: Match) => {
    const updatedMatch = await updateMatch({
      ...match,
      likedAt: null,
    }).unwrap();
    setMatches((prevMatches) => {
      return {
        ...prevMatches,
        [updatedMatch.data.id]: updatedMatch.data,
      };
    });

    track("MatchReview", "unlike_match_button_clicked", {
      ...match,
    });
    refetchJobPosting();
  };

  const openOutreachModal = (match: Match) => {
    track("MatchReview", "message_template_button_clicked", {
      ...match,
    });
    setOutreachForMatch(match);
  };

  const closeOutreachModal = () => setOutreachForMatch(undefined);

  return (
    <MatchesContext.Provider
      value={{
        jobPostingId,
        loading: isLoading,
        fetching: isFetching,
        initialised: !isUninitialized,
        matches: orderedMatches,
        allMatchesFound:
          matchesCount === paging.totalCount && paging.nextOffset === null,
        currentMatch,
        currentMatchIndex,
        nextMatch:
          currentMatchIndex < matchesCount - 1 ? selectNextMatch : undefined,
        previousMatch: currentMatchIndex > 0 ? selectPreviousMatch : undefined,
        setCurrentMatch: setCurrentMatchId,
        likeMatch: handleLikeMatch,
        unlikeMatch: handleUnlikeMatch,
        openOutreachModal,
        closeOutreachModal,
      }}
    >
      <Outlet />
      {outreachForMatch && jobPosting?.specificationId && (
        <OutreachModal
          isModalOpen={!!outreachForMatch}
          match={outreachForMatch}
          onClose={closeOutreachModal}
          specificationId={jobPosting.specificationId}
          jobPostingTitle={jobPosting.name}
          preferredMessageStyleId={preferredMessageStyleId}
          setPreferredMessageStyleId={setPreferredMessageStyleId}
        />
      )}
    </MatchesContext.Provider>
  );
};
