export const GoogleIcon = (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2199 6.8575H13.75V6.83329H8.50001V9.16663H11.7967C11.3158 10.5249 10.0234 11.5 8.50001 11.5C6.56713 11.5 5.00001 9.93283 5.00001 7.99996C5.00001 6.06708 6.56713 4.49996 8.50001 4.49996C9.39221 4.49996 10.2039 4.83654 10.822 5.38633L12.4719 3.73638C11.4301 2.76542 10.0365 2.16663 8.50001 2.16663C5.27855 2.16663 2.66667 4.7785 2.66667 7.99996C2.66667 11.2214 5.27855 13.8333 8.50001 13.8333C11.7215 13.8333 14.3333 11.2214 14.3333 7.99996C14.3333 7.60883 14.2931 7.22704 14.2199 6.8575Z"
      fill="#FFC107"
    />
    <path
      d="M3.33925 5.28483L5.25579 6.69038C5.77437 5.40646 7.03029 4.49996 8.5 4.49996C9.39221 4.49996 10.2039 4.83654 10.822 5.38633L12.4719 3.73638C11.4301 2.76542 10.0365 2.16663 8.5 2.16663C6.25941 2.16663 4.31633 3.43158 3.33925 5.28483Z"
      fill="#FF3D00"
    />
    <path
      d="M8.49999 13.8333C10.0067 13.8333 11.3758 13.2567 12.411 12.319L10.6055 10.7912C10.0199 11.2348 9.29187 11.5 8.49999 11.5C6.98274 11.5 5.69445 10.5325 5.20912 9.18237L3.30687 10.648C4.27229 12.5371 6.23287 13.8333 8.49999 13.8333Z"
      fill="#4CAF50"
    />
    <path
      d="M14.2199 6.85758H13.75V6.83337H8.5V9.16671H11.7967C11.5657 9.81917 11.146 10.3818 10.6047 10.7916C10.605 10.7913 10.6053 10.7913 10.6055 10.791L12.411 12.3187C12.2832 12.4348 14.3333 10.9167 14.3333 8.00004C14.3333 7.60892 14.2931 7.22712 14.2199 6.85758Z"
      fill="#1976D2"
    />
  </svg>
);
