import { ComponentProps } from "react";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@jobe/ui/typography";
import { useMatches } from "../hooks";
import { EducationQualificationItem } from "./EducationQualificationItem";

export const MatchCardEducationTab = ({ className }: ComponentProps<"div">) => {
  const { currentMatch } = useMatches();

  return (
    <Spacing gap="lg" className={className}>
      <Heading level="h5">Education</Heading>
      <Spacing>
        {currentMatch?.resume.educationHistory.map((education) => (
          <EducationQualificationItem
            key={`${education.institution}_${education.type}_${education.subject}`}
            qualification={education}
          />
        ))}
      </Spacing>
    </Spacing>
  );
};
