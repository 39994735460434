import {
  FunctionComponent,
  KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import { SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { eq } from "lodash";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors, screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { InputActionButtons } from "../InputActionButtons/InputActionButtons";
import { LoadingOverlay } from "../LoadingOverlay";

interface ContainerProps {
  containerWidth?: string;
  style?: SerializedStyles;
}

interface LabelProps {
  labelWidth?: string;
  style?: SerializedStyles;
}

interface ValueProps {
  editingEnabled?: boolean;
  style?: SerializedStyles;
}

interface ValueContainerProps {
  labelVisible: boolean;
  valueWidth?: string;
}

const EditableTextContainer = styled.div<ContainerProps>`
  width: ${({ containerWidth }) => `${containerWidth ?? "300px"}`};
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  @media (min-width: ${screenBreakpoints.md}) {
    flex-direction: row;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
  }

  ${({ style }) => style};
`;

export const Label = styled(Paragraph)<LabelProps>`
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  flex: 1;
  width: 100%;
  padding: 0.25rem;
  height: 1.5rem;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelWidth }) => `${labelWidth ?? "50%"}`};
  }

  ${({ style }) => style};
`;

const ValueContainer = styled.div<ValueContainerProps>`
  padding: 2px;
  position: relative;
  width: 100%;

  @media (min-width: ${screenBreakpoints.md}) {
    width: ${({ labelVisible, valueWidth }) =>
      labelVisible ? valueWidth ?? "50%" : "100%"};
  }
`;

export const Value = styled(Paragraph)<ValueProps>`
  display: flex;
  align-items: center;
  border-radius: 0.2rem;
  line-height: 1.5rem;
  flex: 1;
  font-weight: bolder;
  width: 100%;
  transition: background-color 0.2s ease;
  padding: 0.25rem;
  height: 1.5rem;

  :hover {
    background: ${({ editingEnabled }) =>
      `${editingEnabled ? `${colors.neutral["500"]}44` : ""}`};
  }

  ${({ style }) => style};
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  overflow-y: visible;
`;

const Input = styled.input`
  border: 0;
  box-shadow: inset 0 0 0 1px ${colors.primary};
  border-radius: 0.2rem;
  line-height: 1.5rem;
  flex: 1;
  font-weight: bolder;
  transition: background-color 0.2s ease;
  padding: 0.25rem;
  height: 1.5rem;
  width: 100%;

  :focus {
    border: 0;
    outline: 0;
  }
`;

/**
 * @typedef EditableTextInputProps
 * @property {string} label The label to display for the input element. If an empty string is
 * passed. no label is displayed and the value container takes 100% of the available width
 * @property {string | number} value The value to display for the input element
 * @property {boolean} editingEnabled Whether editing is enabled for the input element
 * @property {string} containerWidth The width of the container element. Defaults to 300px
 * @property {string} labelWidth The width of the label element. Defaults to 50%
 * @property {string} valueWidth The width of the value element. Defaults to 50%
 * @property {SerializedStyles} labelStyle Override styles for label container
 * @property {SerializedStyles} valueStyle Override styles for value container
 * @property {SerializedStyles} containerStyle Override styles for the parent container
 * @property {boolean} loading Whether the input element is in a loading state
 * @property {string} className The class name to apply to the container element
 * @property {(updatedValue: string | number | undefined) => void} onChange Callback function to
 * execute when the user clicks the OK button or presses enter on the keyboard
 */
type EditableTextInputProps = {
  label?: string;
  value: number | string;
  editingEnabled: boolean;
  containerWidth?: string;
  labelWidth?: string;
  valueWidth?: string;
  labelStyle?: SerializedStyles;
  valueStyle?: SerializedStyles;
  containerStyle?: SerializedStyles;
  loading?: boolean;
  className?: string;
  onChange?: (updatedValue: string | number | undefined) => void;
};

/**
 * The component displays a static value when editing mode is off. When editing is enabled,
 * the component displays an input element that allows the user to type a new value.
 * @param props {EditableTextInputProps}
 */
export const EditableTextInput: FunctionComponent<EditableTextInputProps> = (
  props: EditableTextInputProps
) => {
  // Define editing state for the component
  const [editing, setEditing] = useState(false);
  // Define a ref for the input element to allow the element to be accessed across the component
  const inputRef = useRef<HTMLInputElement>(null);

  // Set focus on the input element when editing is enabled
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const handleCompletion = () => {
    setEditing(false);
    if (!eq(inputRef.current?.value, props.value)) {
      props.onChange?.(inputRef.current?.value);
    }
  };

  const handleCancel = () => {
    setEditing(false);
  };

  /**
   * Handles key press events for the input element. If the enter key is pressed, the input value
   * is saved and editing is disabled. If the escape key is pressed, editing is disabled without
   * saving the input value.
   * @param event Key press event
   */
  const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleCompletion();
    } else if (event.key === "Escape") {
      handleCancel();
    }
  };

  // Define the static non-editable value element. This element can be clicked to enable editing
  // mode when editing is enabled for the component
  const StaticValue = (
    <Value
      editingEnabled={props.editingEnabled}
      onClick={() => (props.editingEnabled ? setEditing(true) : undefined)}
      style={props.valueStyle}
    >
      {props.value}
    </Value>
  );

  // Define the editable value element. This element is shown when editing is enabled and allows
  // the user to edit the value of the component
  const EditableValue = (
    <InputContainer onBlur={handleCompletion}>
      <Input
        ref={inputRef}
        defaultValue={props.value}
        onKeyUp={handleKeyPress}
      />
      {!props.loading && (
        <InputActionButtons
          onCancel={handleCancel}
          onSubmit={handleCompletion}
        />
      )}
    </InputContainer>
  );

  return (
    <EditableTextContainer
      className={props.className}
      containerWidth={props.containerWidth}
      style={props.containerStyle}
    >
      {props.label && (
        <Label
          color={colors.neutral["400"]}
          labelWidth={props.labelWidth}
          style={props.labelStyle}
        >
          {props.label}
        </Label>
      )}
      <ValueContainer
        labelVisible={!!props.label}
        valueWidth={props.valueWidth}
      >
        {props.loading ? <LoadingOverlay /> : null}
        {editing ? EditableValue : StaticValue}
      </ValueContainer>
    </EditableTextContainer>
  );
};
