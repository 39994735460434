import { useState } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Paragraph } from "@jobe/ui/typography";
import { ChatPrimaryButton } from "./ChatPrimaryButton";
import { ChatSecondaryButton } from "./ChatSecondaryButton";

const ResponseContainer = styled(Spacing)`
  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 1rem;
  border-color: ${({ theme }) => theme.colors.text.tertiary};
  border-radius: 0.5rem;

  ::placeholder {
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text.tertiary};
  }

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.mili.plum} !important;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.mili.plum + "20"} !important;
    outline: none;
  }
`;

const InputText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.red["500"]};
  padding-bottom: 0;
`;

const MAX_SPEC_LENGTH = 4000;

type TextResponseProps = {
  placeholder: string;
  cancelText?: string;
  submitText?: string;
  onCancel?: () => void;
  onSubmit: (text: string) => void;
  submitting?: boolean;
};

export const TextResponse = ({
  placeholder,
  cancelText = "Cancel",
  submitText = "Confirm",
  onCancel,
  onSubmit,
  submitting,
}: TextResponseProps) => {
  const [text, setText] = useState<string>("");

  const handleSubmit = () => {
    onSubmit(text);
  };

  return (
    <ResponseContainer gap="xs">
      <TextArea
        value={text}
        onChange={(event) => setText(event.target.value)}
        rows={5}
        placeholder={placeholder}
        maxLength={MAX_SPEC_LENGTH}
      />
      {text.length > MAX_SPEC_LENGTH && (
        <InputText small>
          This job specification {text.length} characters long, but my limit is{" "}
          {MAX_SPEC_LENGTH} characters!
        </InputText>
      )}
      <Spacing direction="horizontal" justify="left">
        {onCancel && (
          <ChatSecondaryButton onClick={onCancel}>
            {cancelText}
          </ChatSecondaryButton>
        )}
        <ChatPrimaryButton
          disabled={
            submitting || text.length === 0 || text.length > MAX_SPEC_LENGTH
          }
          loading={submitting}
          onClick={handleSubmit}
        >
          {submitText}
        </ChatPrimaryButton>
      </Spacing>
    </ResponseContainer>
  );
};
