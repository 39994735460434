import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import {
  databases as databaseList,
  frameworks as frameworkList,
  languages as languageList,
} from "../static";
import { identifyNonValue } from "./identifyNonValue";

export enum RequirementLevel {
  MUST_HAVE = 2,
  NICE_TO_HAVE = 1,
  NONE = 0,
}

export type Skill = {
  skill: string;
  requirementLevel: RequirementLevel;
};

type Skills = {
  languages: Skill[];
  frameworks: Skill[];
  databases: Skill[];
  others: Skill[];
};

type CategoriseSkillsProps = {
  skills: string[];
  requirements: JobSpecificationRequirement[];
};

const sortSkills = (skills: Skill[]) =>
  skills.sort((a, b) => b.requirementLevel - a.requirementLevel);

const standardiseSkill = (skill: string): string =>
  skill
    .toLowerCase()
    .replaceAll(" ", "")
    // remove a full stop at the end of the string if there is one
    .replace(/\.$/, "");

export const categoriseSkills = ({
  skills,
  requirements,
}: CategoriseSkillsProps): Skills => {
  const languages: Skill[] = [];
  const frameworks: Skill[] = [];
  const databases: Skill[] = [];
  const others: Skill[] = [];

  if (!skills.length) {
    return { languages, frameworks, databases, others };
  }

  const mustHavesBlock = requirements
    .filter((requirement) => requirement.mustHave)
    .flatMap(
      (requirement) =>
        " " +
        requirement.text
          .toLowerCase()
          .replaceAll("/", " ")
          .replace(/\.$/, " ") +
        " "
    )
    .join(" ");
  const niceToHavesBlock = requirements
    .filter((requirement) => !requirement.mustHave)
    .flatMap(
      (requirement) =>
        " " +
        requirement.text
          .toLowerCase()
          .replaceAll("/", " ")
          .replace(/\.$/, " ") +
        " "
    )
    .join(" ");

  // to avoid skills like "C" being matched with "C++" or "the candidate", we pad the skill we search for with spaces
  const getSkillRequirementLevel = (skill: string): RequirementLevel => {
    if (mustHavesBlock.includes(" " + skill + " ")) {
      return RequirementLevel.MUST_HAVE;
    }
    if (niceToHavesBlock.includes(" " + skill + " ")) {
      return RequirementLevel.NICE_TO_HAVE;
    }
    return RequirementLevel.NONE;
  };

  const allValidSkills = Array.from(
    skills
      .flatMap((skill) => skill.split("/"))
      .filter((skill) => !identifyNonValue(skill))
      .reduce((acc, originalSkill) => {
        const standardSkill = standardiseSkill(originalSkill);
        if (!acc.has(standardSkill)) {
          acc.set(standardSkill, originalSkill);
        }
        return acc;
      }, new Map())
      .values()
  );

  allValidSkills.forEach((skill) => {
    const skillStandardised = standardiseSkill(skill);

    if (languageList.includes(skillStandardised)) {
      languages.push({
        skill,
        requirementLevel: getSkillRequirementLevel(skillStandardised),
      });
      return;
    }
    if (frameworkList.includes(skillStandardised)) {
      frameworks.push({
        skill,
        requirementLevel: getSkillRequirementLevel(skillStandardised),
      });
      return;
    }
    if (databaseList.includes(skillStandardised)) {
      databases.push({
        skill,
        requirementLevel: getSkillRequirementLevel(skillStandardised),
      });
      return;
    }
    others.push({
      skill,
      requirementLevel: getSkillRequirementLevel(skillStandardised),
    });
  });

  return {
    languages: sortSkills(languages),
    frameworks: sortSkills(frameworks),
    databases: sortSkills(databases),
    others: sortSkills(others),
  };
};
