import { useNavigate } from "react-router-dom";
import { CreateJobPostingRequest } from "@jobe/data-access/api-types";
import { ChatMessages } from "@jobe/ui/features/chat";
import { useCreateJobPostingMutation, useListJobPostingsQuery } from "../apis";
import { CreateJobOpeningChatProvider } from "../providers";
import { JobPostingFormData } from "../types";

export const CreateJobOpeningChat = () => {
  const navigate = useNavigate();
  const [createJobPosting] = useCreateJobPostingMutation();
  const { data: response, refetch } = useListJobPostingsQuery({
    offset: 0,
    limit: 0,
  });
  const onSubmit = async (formData: JobPostingFormData) => {
    await refetch();
    const existingNames = response?.data?.map((job) => job.name) || [];
    const desiredName = formData.role || "My job opening";
    let jobPostingName = desiredName;
    let suffix = 1;
    while (existingNames.includes(jobPostingName)) {
      jobPostingName = `${desiredName} ${suffix}`;
      suffix++;
    }
    const request: CreateJobPostingRequest = {
      name: jobPostingName,
      description: "",
      requirements: formData.requirements,
      requirementSpecTextId: formData.requirementSpecTextId,
    };
    const { data } = await createJobPosting(request).unwrap();
    navigate(`/openings/${data.id}/matches`);
  };

  return (
    <CreateJobOpeningChatProvider onSubmit={onSubmit}>
      <ChatMessages />
    </CreateJobOpeningChatProvider>
  );
};
