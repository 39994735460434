import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { theme } from "@jobe/ui/theme";

export const ChatContainer = styled(({ children, ...props }: SpacingProps) => {
  const messagesEndRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    // Slight delay to wait for all components to render and animate before scrolling to the bottom
    // Without this we scroll before all components render, so we don't scroll to the bottom
    const timeoutId = setTimeout(() => {
      requestAnimationFrame(scrollToBottom);
    }, 100);

    return () => clearTimeout(timeoutId);
  }, [children]);

  return (
    <Spacing gap="3xl" {...props}>
      <Spacing gap="xl">{children}</Spacing>
      <div ref={messagesEndRef} />
    </Spacing>
  );
})`
  max-width: ${theme.screenBreakpoints.md};
  width: 100%;
  flex: 1;
`;
