import { ReactNode, createContext } from "react";
import { Outlet } from "react-router-dom";
import { ChatMessagesState, useChatMessages } from "../hooks";
import { Message, PushMessage, UpdateMessage } from "../types";

export interface ChatContext {
  messages: Message[];
  pushMessage: (message: PushMessage) => string;
  updateMessage: (message: UpdateMessage) => void;
  latestBotMessageId: string;
}

export const defaultChatContext: ChatContext = {
  messages: [],
  pushMessage: () => "",
  updateMessage: () => null,
  latestBotMessageId: "",
};

export const ChatContext = createContext<ChatContext>(defaultChatContext);

export interface ChatProviderProps {
  state?: ChatMessagesState;
  children?: ReactNode;
}

export const ChatProvider = ({
  state: parentState,
  children,
}: ChatProviderProps) => {
  const state = useChatMessages();
  return (
    <ChatContext.Provider
      value={{
        messages: (parentState || state).messages,
        pushMessage: (parentState || state).pushMessage,
        updateMessage: (parentState || state).updateMessage,
        latestBotMessageId: (parentState || state).latestBotMessageId,
      }}
    >
      {children || <Outlet />}
    </ChatContext.Provider>
  );
};
