import React, { KeyboardEvent, useState } from "react";
import styled from "@emotion/styled";
import { useMetrics } from "@unlikelyai-magic/metrics/mixpanel";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import {
  ExtractedRequirements,
  JobSpecificationRequirement,
} from "@jobe/data-access/api-types";
import { PrimaryButton } from "@jobe/ui/buttons";
import { useExtractRequirementsMutation } from "@jobe/ui/features/shared";
import { Paragraph } from "@jobe/ui/typography";

const MAX_SPEC_LENGTH = 4000;

const TextArea = styled.textarea`
  padding: 1rem;
  border-color: ${colors.gray["200"]};
  border-radius: 0.5rem;

  ::placeholder {
    font-weight: 400;
    color: ${colors.gray["400"]};
  }
`;

export type RequirementSpecTextInputProps = {
  onRequirementsExtracted: (value: {
    requirements: JobSpecificationRequirement[];
    requirementSpecTextId: string;
  }) => void;
};

export const RequirementSpecTextInput = styled(
  ({ onRequirementsExtracted }: RequirementSpecTextInputProps) => {
    const [specification, setSpecification] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [extractRequirements] = useExtractRequirementsMutation();
    const { track } = useMetrics();
    /**
     * Returns a set of requirements for the pasted spec text
     */
    const requirementsFromSpec = async (): Promise<ExtractedRequirements> => {
      setIsLoading(true);
      const result = (
        await extractRequirements({
          jobSpecText: specification,
        }).unwrap()
      ).data;

      setIsLoading(false);
      return result;
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      event.preventDefault();
    };

    const handleKeyUp = async (event: KeyboardEvent) => {
      if (event.key !== "Enter") return;
      event.preventDefault();

      await handleEnter();
    };

    const handleEnter = async () => {
      if (!specification) return;

      // If the user inputs something that we didn't suggest, we need to create a requirement for it
      const extractedRequirements = await requirementsFromSpec();
      track(
        "JobOpeningCreated",
        "job_specification_generate_requirements_button_clicked",
        {
          text: specification,
          extractedRequirements: extractedRequirements.requirements,
        }
      );
      setSpecification("");
      onRequirementsExtracted({
        requirements: extractedRequirements.requirements.map((requirement) => {
          return {
            id: requirement.id,
            text: requirement.text,
            mustHave: true,
          };
        }),
        requirementSpecTextId: extractedRequirements.requirementSpecTextId,
      });
    };

    return (
      <Spacing>
        <TextArea
          value={specification}
          onKeyUp={handleKeyUp}
          onKeyDown={handleKeyDown}
          onChange={(event) => setSpecification(event.target.value)}
          rows={5}
          placeholder="Paste your specification here..."
          disabled={isLoading}
          maxLength={MAX_SPEC_LENGTH}
        />
        <Paragraph small={true} color={colors.gray["400"]} textAlign={"right"}>
          {specification.length}/{MAX_SPEC_LENGTH}
        </Paragraph>
        {specification.length > 0 ? (
          <PrimaryButton size="xl" onClick={handleEnter} loading={isLoading}>
            Generate requirements
          </PrimaryButton>
        ) : null}
      </Spacing>
    );
  }
)``;
