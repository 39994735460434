import React, { ComponentProps } from "react";
import styled from "@emotion/styled";

export const MiliLogo = styled((props: ComponentProps<"svg">) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 57 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.0078 27.6821V31.9873H45.1345V27.6821H42.456V9.6257H35.0078V13.9309H37.6863V27.6821H35.0078Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11.0946H8.71491L11.7367 26.8983L14.7584 11.0946H23.4733V32H18.3761V16.6191L15.3442 32H8.1287L5.09726 16.4659V32H0V11.0946Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.0794 31.9872V20.3962H25.5465V16.1201H32.82V31.9872H28.0794Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2633 31.9883V20.3972H44.7304V16.1212H52.0039V31.9883H47.2633Z"
        fill="currentColor"
      />
      <path
        d="M27.5208 12.0951C27.5208 10.7321 28.6254 9.62724 29.9879 9.62724H30.0933C31.4558 9.62724 32.5603 10.7321 32.5603 12.0951C32.5603 13.458 31.4558 14.5629 30.0933 14.5629H29.9879C28.6254 14.5629 27.5208 13.458 27.5208 12.0951Z"
        fill="currentColor"
      />
      <path
        d="M43.8735 9.46093C46.6764 9.46093 48.9485 7.18806 48.9485 4.38433C48.9485 7.18806 51.2207 9.46093 54.0235 9.46093C51.2207 9.46093 48.9485 11.7338 48.9485 14.5375C48.9485 11.7338 46.6764 9.46093 43.8735 9.46093Z"
        fill="currentColor"
      />
      <path
        d="M42.1099 5.14364C43.6255 4.6653 44.4668 3.04983 43.9891 1.53537C44.4668 3.04983 46.0826 3.88977 47.5981 3.41144C46.0826 3.88977 45.2413 5.50525 45.719 7.0197C45.2413 5.50525 43.6255 4.6653 42.1099 5.14364Z"
        fill="currentColor"
      />
      <path
        d="M49.3232 2.4418C51.263 3.03167 53.3131 1.93844 53.9021 0C53.3131 1.93844 54.408 3.98804 56.3478 4.57791C54.408 3.98804 52.358 5.08127 51.7689 7.0197C52.358 5.08127 51.263 3.03167 49.3232 2.4418Z"
        fill="currentColor"
      />
    </svg>
  );
})``;
