import { StrictMode } from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { createMockServer } from "@unlikelyai-magic/shared/mocking";
import { store } from "@jobe/ui/store";
import { App } from "./App";
import "./App.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const render = async () => {
  if (process.env.NODE_ENV === "development") {
    const mockServer = createMockServer();
    await mockServer.listen();
  }

  root.render(
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </StrictMode>
  );
};

render();
