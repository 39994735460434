import { Select } from "@unlikelyai-magic/ui/inputs";
import roles from "../static/roles.json";

type RoleSelectorProps<Value> = {
  name: string;
  value?: Value;
  multi?: boolean;
  placeholder?: string;
  onChange: (value?: Value) => void;
};

export const RoleSelector = <Value extends object>({
  name,
  value,
  multi,
  placeholder = multi ? "Select roles" : "Select a role",
  onChange,
}: RoleSelectorProps<Value>) => {
  return (
    <Select
      name={name}
      value={value}
      isMulti={multi}
      options={roles}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};
