import { ReactElement, ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { setAccessToken } from "@jobe/ui/store";

type AuthTokenManagerProps = {
  children: ReactNode | ReactNode[];
};

/**
 * AuthTokenManager watches for changes in the JWT Auth token from Auth0 and injects it into
 * redux global state so that it can be used for making authenticated API requests.
 */
export const AuthTokenManager = ({
  children,
}: AuthTokenManagerProps): ReactElement => {
  const dispatch = useDispatch();
  const { isLoading, isAuthenticated, getAccessTokenSilently, logout } =
    useAuth0();

  useEffect(() => {
    // While the Auth0 client is loading, do nothing.
    if (isLoading) return;

    // If the user is not authenticated, clear the JWT token from redux global state.
    if (!isAuthenticated) {
      dispatch(setAccessToken(undefined));
      return;
    }

    getAccessToken();
  }, [isLoading, isAuthenticated]);

  /**
   * getAccessToken generates an access token from Auth0 and injects it into redux global state.
   */
  const getAccessToken = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      dispatch(setAccessToken(accessToken));
    } catch (err) {
      await logout({ logoutParams: { returnTo: window.location.origin } });
    }
  };

  // Pass the children through.
  return <>{children}</>;
};
