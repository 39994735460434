import { ReactNode } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { JobRoleCategory } from "@jobe/data-access/api-types";
import { TransparentButton } from "@jobe/ui/buttons";
import { Paragraph } from "@jobe/ui/typography";
import dsImageUrl from "../assets/ds_image.png";
import sweImageUrl from "../assets/swe_image.png";
import { useCreateJobOpeningChat } from "../hooks";
import { JobPostingFormData } from "../types";

const RoleImage = styled.div<{ imageUrl: string }>`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.75rem;
  width: 10rem;
  height: 5rem;
  transition: transform 0.2s;
`;

const Role = styled(Spacing)<{ children: ReactNode }>`
  cursor: pointer;

  &:hover ${RoleImage} {
    transform: scale(1.05);
  }
`;

const AnyRoleButton = styled(TransparentButton)`
  padding: 0;
`;

export const SelectRoleWidget = () => {
  const { formData, setFormData } = useCreateJobOpeningChat();
  const roles = [
    {
      name: "Software Engineer",
      imageUrl: sweImageUrl,
      value: JobRoleCategory.SoftwareEngineer,
    },
    {
      name: "Data Scientist",
      imageUrl: dsImageUrl,
      value: JobRoleCategory.DataScientist,
    },
    {
      name: "Engineering Manager",
      imageUrl: sweImageUrl,
      value: JobRoleCategory.EngineeringManager,
    },
  ];

  const handleRoleClick = async (role: JobPostingFormData["role"]) => {
    setFormData((prev) => ({
      ...prev,
      role,
    }));
  };

  if (formData.role !== undefined) {
    return null;
  }

  return (
    <Spacing gap="xl">
      <Spacing direction="horizontal">
        {roles.map((role) => (
          <Role key={role.value} onClick={() => handleRoleClick(role.value)}>
            <RoleImage imageUrl={role.imageUrl} />
            <Paragraph>{role.name}</Paragraph>
          </Role>
        ))}
      </Spacing>
      <Paragraph>
        <AnyRoleButton onClick={() => handleRoleClick(null)}>
          Or build requirements from scratch
        </AnyRoleButton>
      </Paragraph>
    </Spacing>
  );
};
