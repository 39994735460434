import { NavLink, NavLinkProps } from "react-router-dom";
import styled from "@emotion/styled";
import { Paragraph } from "@unlikelyai-magic/ui/typography";
import { colors } from "@unlikelyai-magic/ui/variables";

type TabsProps = NavLinkProps;

export const Tab = styled(({ to, className, children }: TabsProps) => {
  return (
    <NavLink to={to} className={className}>
      {(props) => (
        <Paragraph
          color={props.isActive ? colors.gray["900"] : colors.gray["400"]}
        >
          {typeof children === "function" ? children(props) : children}
        </Paragraph>
      )}
    </NavLink>
  );
})`
  font-weight: 500;
  padding: 1rem 0.25rem;
  border-bottom: 0.125rem solid;
  border-color: transparent;

  &.active {
    border-color: ${colors.blue["600"]};
  }
`;
