import { ReactElement } from "react";

export enum MessageType {
  Text = "text",
  Question = "question",
}

export enum MessageAuthor {
  Bot = "bot",
  User = "user",
}

export type Message = {
  id: string;
  author: MessageAuthor;
  profileImageUrl?: string;
  type: MessageType;
  text?: string | ReactElement;
  sentAt: string;
  widget?: ReactElement | null;
};

export type PushMessage = Omit<Message, "id" | "type" | "sentAt">;
export type UpdateMessage = Omit<
  Message,
  "type" | "sentAt" | "author" | "profileImageUrl" | "minTypingTimeMs"
>;
