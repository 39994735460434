//Note: entries are given in lowercase and without spaces, this is to help categorise skills when candidates have used unexpected syntax
export const frameworks = [
  "angular",
  "angular2.0",
  "asp.net",
  "awscdk",
  "awslambda",
  "activemq",
  "adonisjs",
  "ag-grid",
  "airflow",
  "akita",
  "akka",
  "allegro",
  "alpine.js",
  "altair",
  "amberframework",
  "amethyst",
  "angular",
  "ansible",
  "antdesign",
  "apacheairflow",
  "apachecordova",
  "apachedrill",
  "apacheflink",
  "apachehadoop",
  "apachehive",
  "apachekafka",
  "apachesolr",
  "apachespark",
  "apachewicket",
  "apollo",
  "appgamekit",
  "argocd",
  "aurelia",
  "bem",
  "babylon.js",
  "backbone.js",
  "bamboo",
  "basex",
  "basex",
  "beam",
  "beamstreaming",
  "beego",
  "bigquery",
  "blazor",
  "bootstrap",
  "bottle",
  "buffalo",
  "buildbox",
  "bulma",
  "cakephp",
  "cappuccino",
  "chai",
  "chakraui",
  "chef",
  "circleci",
  "clickteamfusion",
  "cloudformation",
  "cocos2d",
  "cocos2d-x",
  "codeigniter",
  "compass",
  "construct2/3",
  "cordova",
  "coronasdk",
  "cryengine",
  "cucumber",
  "cypress",
  "d3.js",
  "dvc(dataversioncontrol)",
  "dagster",
  "datatables",
  "derby",
  "django",
  "docker",
  "dropwizard",
  "easymock",
  "echo",
  "elasticsearch",
  "electron",
  "ember.js",
  "emotion",
  "express",
  "falcon",
  "famo.us",
  "fastapi",
  "feathersjs",
  "fiber",
  "finagle",
  "firebaserealtimedatabase",
  "firestore",
  "flask",
  "flinkstreaming",
  "flume",
  "flutter",
  "fomantic-ui",
  "foundation",
  "fuelphp",
  "gamemakerstudio",
  "gatsby",
  "gearpump",
  "gearpumpstreaming",
  "gin",
  "gitlabci/cd",
  "godot",
  "googlefirebase",
  "grails",
  "graphql",
  "greenplum",
  "hanami",
  "handsontable",
  "hapi",
  "harness",
  "hasura",
  "haxeflixel",
  "helidon",
  "heron",
  "heronstreaming",
  "hibernate",
  "highcharts",
  "hoodie",
  "hyperapp",
  "inferno",
  "ionic",
  "iris",
  "jhipster",
  "jpa(javapersistenceapi)",
  "jsf(javaserverfaces)",
  "junit",
  "jasmine",
  "jest",
  "kafkastreams",
  "karma",
  "keras",
  "keystonejs",
  "kibana",
  "kitura",
  "koa",
  "kubeflow",
  "kubernetes",
  "lagom",
  "laminas",
  "laravel",
  "less",
  "logstash",
  "lokijs",
  "love2d",
  "lovefield",
  "lucky",
  "luigi",
  "mlflow",
  "marionette.js",
  "marklogic",
  "marko",
  "material-ui",
  "matplotlib",
  "memsql",
  "meteor",
  "meteorite",
  "micronaut",
  "mikroorm",
  "milligram",
  "mithril",
  "moai",
  "mobx",
  "mobileangularui",
  "mocha",
  "mockito",
  "moleculer",
  "moon",
  "mysql",
  "nunit",
  "nanosql",
  "nativescript",
  "neo4j",
  "nestjs",
  "next.js",
  "ngrx",
  "nifi",
  "numpy",
  "nuxt.js",
  "objectbox",
  "octopusdeploy",
  "omi",
  "openfl",
  "openshift",
  "padrino",
  "panda3d",
  "pandas",
  "perfect",
  "phalcon",
  "phaser",
  "phoenix",
  "pinia",
  "pixijs",
  "play",
  "playwright",
  "plotly",
  "polymer",
  "powermock",
  "prefect",
  "presto",
  "primevue",
  "prisma",
  "protractor",
  "pulsar",
  "pulsarstreaming",
  "pulumi",
  "puppet",
  "puppeteer",
  "purecss",
  "pytorch",
  "pylons",
  "quark",
  "quarkus",
  "quasar",
  "qwik",
  "rspec",
  "rabbitmq",
  "ractive.js",
  "ratpack",
  "react",
  "reactnative",
  "reactquery",
  "reactivex",
  "reactor",
  "recoil",
  "redshift",
  "redwoodjs",
  "reframe",
  "ren'py",
  "revel",
  "robotframework",
  "rocketmq",
  "rubyonrails",
  "sfml",
  "sqlserver",
  "sqlite",
  "swr",
  "sails.js",
  "saltstack",
  "samza",
  "samzastreaming",
  "sanic",
  "sapper",
  "sass",
  "scikit-learn",
  "scipy",
  "seaborn",
  "selenium",
  "semanticui",
  "sequelize",
  "serverless",
  "serverlessframework",
  "servicestack",
  "silverlight",
  "sinatra",
  "skaffold",
  "skeleton",
  "slickgrid",
  "slim",
  "snowflake",
  "solidjs",
  "specflow",
  "spinnaker",
  "spring",
  "springboot",
  "springcloud",
  "springdata",
  "springsecurity",
  "sproutcore",
  "sqoop",
  "stencyl",
  "stimulus",
  "storm",
  "stormstreaming",
  "strapi",
  "struts",
  "styledcomponents",
  "stylus",
  "susy",
  "svelte",
  "sveltekit",
  "symfony",
  "tailwindcss",
  "tanstackquery",
  "teamcity",
  "tekton",
  "tensorflow",
  "teradata",
  "terraform",
  "testcafe",
  "testng",
  "three.js",
  "thymeleaf",
  "tornado",
  "torque3d",
  "total.js",
  "travisci",
  "turbogears",
  "typeorm",
  "uikit",
  "unity",
  "unrealengine",
  "vaadin",
  "vagrant",
  "vapor",
  "vega",
  "vega-lite",
  "vert.x",
  "voltron",
  "vue.js",
  "vuetify",
  "vuex",
  "waterline",
  "watermelondb",
  "watir",
  "web2py",
  "webdriverio",
  "wt",
  "xamarin",
  "yii",
  "zendframework",
  "zeromq",
  "zewo",
  "zustand",
  "behave",
  "jmonkeyengine",
  "nose",
  "pytest",
  "unittest",
];
