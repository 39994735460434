import { ComponentProps } from "react";

export const NeutralProfileIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2943_6786)">
        <path
          d="M2.5 0.5H13.5C13.5 0.5 14.5 0.5 14.5 1.5V14.5C14.5 14.5 14.5 15.5 13.5 15.5H2.5C2.5 15.5 1.5 15.5 1.5 14.5V1.5C1.5 1.5 1.5 0.5 2.5 0.5Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.25977 6.05543C5.25977 6.41525 5.33064 6.77155 5.46834 7.10398C5.60603 7.43641 5.80786 7.73847 6.06229 7.9929C6.31673 8.24734 6.61878 8.44916 6.95121 8.58686C7.28365 8.72456 7.63994 8.79543 7.99977 8.79543C8.35959 8.79543 8.71589 8.72456 9.04832 8.58686C9.38075 8.44916 9.68281 8.24734 9.93724 7.9929C10.1917 7.73847 10.3935 7.43641 10.5312 7.10398C10.6689 6.77155 10.7398 6.41525 10.7398 6.05543C10.7398 5.32874 10.4511 4.63181 9.93724 4.11796C9.42339 3.60411 8.72646 3.31543 7.99977 3.31543C7.27307 3.31543 6.57614 3.60411 6.06229 4.11796C5.54844 4.63181 5.25977 5.32874 5.25977 6.05543Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 12.6849C12.0682 11.8655 11.4209 11.1796 10.6278 10.7011C9.83481 10.2226 8.92619 9.96973 8 9.96973C7.07381 9.96973 6.16519 10.2226 5.37217 10.7011C4.57915 11.1796 3.93181 11.8655 3.5 12.6849"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2943_6786">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
