export const fontSizes = {
  xs: "0.75rem", // 12px
  sm: "0.875rem", // 14px
  md: "1rem", // 16px
  lg: "1.125rem", // 18px
  xl: "1.25rem", // 20px
  xxl: "1.5rem", // 24px
  xxxl: "1.875rem", // 30px
  xxxxl: "2.25rem", // 36px
};
