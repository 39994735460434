import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Switch, Tooltip } from "antd";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { PrimaryButton, SecondaryButton } from "@jobe/ui/buttons";
import { Input, TextBox } from "@jobe/ui/inputs";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useModal } from "./useModal";

const ModalContainer = styled.div`
  width: 28rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const ModalHeader = styled(Spacing)`
  justify-content: space-between;
  align-items: center;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  gap: 0.5rem;
`;

const CloseButton = styled(SecondaryButton)`
  &.ant-btn-icon-only {
    color: ${({ theme }) => theme.colors.gray["400"]};
    background: none;
    border: none;
    box-shadow: none !important;
    padding: 0.25rem;
    font-size: 1.5rem;

    &:hover,
    &:focus {
      color: ${({ theme }) => theme.colors.gray["600"]};
      background: none;
    }
  }
`;

const TextColumnsContainer = styled(Spacing)`
  justify-content: space-between;
`;

const OptionalText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  margin-top: 0.125rem;
`;

const MakeDefaultSwitch = styled(Switch)`
  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

const DisabledCreateButton = styled(PrimaryButton)`
  cursor: not-allowed !important;
`;

type AddMessageStyleModalProps = {
  handleConfirm: (
    name: string,
    description?: string,
    message?: string,
    makeStyleDefault?: boolean
  ) => void;
  messageStyles?: { id: string; label: string; value: string }[];
};

export const AddMessageStyleModal = ({
  handleConfirm,
  messageStyles,
}: AddMessageStyleModalProps) => {
  const { closeModal, isOpen } = useModal();
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [makeStyleDefault, setMakeStyleDefault] = useState<boolean>(false);
  const [nameError, setNameError] = useState<string | null>(
    "Name is required."
  );
  const [nameTouched, setNameTouched] = useState<boolean>(false);
  const [formValid, setFormValid] = useState<boolean>(false);

  useEffect(() => {
    if (nameTouched) {
      if (name) {
        const isDuplicate = messageStyles?.find(
          (style) => style.label === name
        );
        if (isDuplicate) {
          setFormValid(false);
          setNameError(
            "This style already exists, please choose a unique name."
          );
        } else {
          setFormValid(true);
          setNameError(null);
        }
      } else {
        setFormValid(false);
        setNameError("Name is required.");
      }
    }
  }, [name, messageStyles, nameTouched]);

  const handleChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(event.target.value);
    setNameTouched(true);
    if (!name) {
      setFormValid(false);
      setNameError("Name is required.");
    }
  };

  if (!isOpen) return null;

  return (
    <ModalContainer>
      <ModalHeader direction="horizontal">
        <Heading level="h4">Add New Message Style</Heading>
        <div>
          <CloseButton icon={<XMarkIcon />} onClick={closeModal} />
        </div>
      </ModalHeader>
      <Spacing gap="lg">
        <Spacing gap="sm">
          <Paragraph>Name</Paragraph>
          <Spacing gap="3xs">
            <Input
              type="text"
              name="name"
              placeholder="Give your style a unique name"
              value={name}
              onChange={handleChange}
              onBlur={handleChange}
              status={nameError && nameTouched ? "error" : ""}
            />
          </Spacing>
        </Spacing>
        <Spacing gap="sm">
          <TextColumnsContainer direction="horizontal">
            <Paragraph>Description</Paragraph>
            <OptionalText small>(Optional)</OptionalText>
          </TextColumnsContainer>
          <Input
            type="text"
            name="description"
            placeholder="Add a brief description for your style"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
        </Spacing>
        <Spacing gap="sm">
          <TextColumnsContainer direction="horizontal">
            <Paragraph>Example message</Paragraph>
            <OptionalText small>(Optional)</OptionalText>
          </TextColumnsContainer>
          <TextBox
            placeholder="Start your style off with an example message"
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            autoSize={true}
          />
        </Spacing>
      </Spacing>
      <ModalFooter>
        <Tooltip
          title="Make this the default message style Mili uses"
          mouseEnterDelay={0.5}
          mouseLeaveDelay={0}
        >
          <Spacing direction="horizontal">
            <MakeDefaultSwitch
              onChange={() => setMakeStyleDefault(!makeStyleDefault)}
            />
            <Paragraph>Default</Paragraph>
          </Spacing>
        </Tooltip>
        <Spacing direction="horizontal">
          <SecondaryButton onClick={closeModal}>Cancel</SecondaryButton>
          {formValid ? (
            <PrimaryButton
              onClick={() =>
                handleConfirm(name, description, message, makeStyleDefault)
              }
            >
              Create
            </PrimaryButton>
          ) : (
            <Tooltip
              title={
                nameError ||
                "There is an error in your inputs, please fix before creating"
              }
              mouseEnterDelay={0}
              mouseLeaveDelay={0}
            >
              <div>
                <DisabledCreateButton disabled>Create</DisabledCreateButton>
              </div>
            </Tooltip>
          )}
        </Spacing>
      </ModalFooter>
    </ModalContainer>
  );
};
