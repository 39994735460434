// Note: entries are given in lowercase and without spaces, this is to help categorise skills when candidates have used unexpected syntax
export const databases = [
  "4d",
  "4thdimension",
  "actianingres",
  "actianx",
  "actianzen",
  "aerospike",
  "akiban",
  "alibabacloudpolardb",
  "alibabacloudtablestore",
  "allegrograph",
  "altibase",
  "amazonaurora",
  "amazondynamodb",
  "amazonrds",
  "amazonredshift",
  "apachecassandra",
  "apachederby",
  "apachegeode",
  "apachehbase",
  "apacheignite",
  "apachekudu",
  "arangodb",
  "arraydb",
  "atlassianjira",
  "azurecosmosdb",
  "bangdb",
  "bergdb",
  "berkeleydb",
  "bigchaindb",
  "blazegraph",
  "cassandra",
  "citus",
  "clickhouse",
  "cloudfirestore",
  "cockroachdb",
  "couchdb",
  "couchbase",
  "cratedb",
  "deepdb",
  "dgraph",
  "druid",
  "ejdb",
  "eventstore",
  "eventstoredb",
  "exist-db",
  "faunadb",
  "filemaker",
  "firebird",
  "flockdb",
  "foundationdb",
  "frontbase",
  "gt.m",
  "gun",
  "galeracluster",
  "gemstone/s",
  "googlebigtable",
  "googlecloudsql",
  "googlecloudspanner",
  "googlefirestore",
  "graphdb",
  "h2database",
  "hammerdb",
  "harperdb",
  "hazelcast",
  "herokupostgres",
  "hibari",
  "hypertable",
  "ibmdb2",
  "ibminformix",
  "infinitydb",
  "influxdb",
  "informix",
  "intersystemscaché",
  "intersystemsiris",
  "jackrabbit",
  "jenafuseki",
  "jethrodata",
  "jungledisk",
  "lmdb(lightningmemory-mappeddatabase)",
  "leanxcale",
  "ledgersmb",
  "leveldb",
  "lowdb",
  "liquibase",
  "luxio",
  "mariadb",
  "matisse",
  "maxdbbysap",
  "memcached",
  "microsoftaccess",
  "microsoftazuresqldatabase",
  "microsoftsqlserver",
  "monetdb",
  "mongodb",
  "ncache",
  "nedb",
  "nuodb",
  "objectdb",
  "omnidb",
  "oracledatabase",
  "oraclenosqldatabase",
  "oracletimesten",
  "orbitdb",
  "orientdb",
  "perconaserverformongodb",
  "perconaserverformysql",
  "pinot",
  "pivotalgemfire",
  "planetscale",
  "postgresql",
  "pouchdb",
  "prestodb",
  "qizx",
  "questdb",
  "raptordb",
  "ravendb",
  "realm",
  "redis",
  "rethinkdb",
  "riak",
  "rockset",
  "rxdb",
  "sapadaptiveserver",
  "saphana",
  "sapsqlanywhere",
  "sqlbase",
  "scylladb",
  "sedna",
  "sequoiadb",
  "siaqodb",
  "singlestore(memsql)",
  "starcounter",
  "summitdb",
  "surrealdb",
  "sybase",
  "taffydb",
  "tarantool",
  "tidb",
  "tigergraph",
  "timesten",
  "titan",
  "tokudb",
  "tokumx",
  "trafodion",
  "unqlite",
  "valentinaserver",
  "velocitydb",
  "vertica",
  "virtuoso",
  "vitess",
  "voltdb",
  "wiredtiger",
  "yugabytedb",
  "zodb",
  "extremedb",
];
