import { ComponentProps, ComponentType } from "react";
import styled from "@emotion/styled";

export type IconProps = ComponentProps<"svg"> &
  ComponentProps<"span"> & {
    icon:
      | ComponentType<ComponentProps<"svg">>
      | ComponentType<ComponentProps<"span">>;
    color?: string;
    size?: string | number;
  };

export const Icon = styled(({ icon: Component, ...otherProps }: IconProps) => {
  return <Component {...otherProps} />;
})`
  width: ${(props) => props.size || "1em"};
  height: ${(props) => props.size || "1em"};
  color: ${(props) => props.color || "currentColor"};
  align-self: center;
`;
