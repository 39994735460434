import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { LoadingIcon } from "@unlikelyai-magic/ui/icons";
import { colors } from "@unlikelyai-magic/ui/variables";

export const PageLoading = styled((props: ComponentProps<"div">) => {
  return (
    <div {...props}>
      <LoadingIcon />
    </div>
  );
})`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  color: ${colors.neutral["900"]};
`;
