import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetUserProfileQuery } from "@jobe/ui/features/users";
import { selectAccessToken } from "../selectors";

export const Auth0CallbackPage = () => {
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken);
  const { data: userProfile, error } = useGetUserProfileQuery(undefined, {
    skip: !accessToken,
  });
  const [searchParams] = useSearchParams();
  const authError = searchParams.get("error");

  useEffect(() => {
    if (!userProfile) return;
    navigate("/waitlist", { replace: true });
  }, [userProfile]);

  if (authError || error) {
    return <div>Something went wrong</div>;
  }

  return <div>Loading...</div>;
};
