import { createContext } from "react";
import { Outlet, useParams } from "react-router-dom";
import { JobPosting } from "@jobe/data-access/api-types";
import { useGetJobPostingQuery } from "../apis";

export interface JobPostingProviderContext {
  jobPostingId: string;
  jobPosting?: JobPosting;
  loading: boolean;
  fetching: boolean;
  refetch: () => void;
}

export const JobPostingProviderContext =
  createContext<JobPostingProviderContext>({
    jobPostingId: "",
    loading: true,
    fetching: true,
    refetch: () => null,
  });

export const JobPostingProvider = () => {
  const { jobPostingId = "" } = useParams();
  const {
    data: response,
    isLoading,
    isFetching,
    refetch,
  } = useGetJobPostingQuery({ id: jobPostingId }, { skip: !jobPostingId });
  const jobPosting = response?.data || undefined;

  return (
    <JobPostingProviderContext.Provider
      value={{
        jobPostingId,
        jobPosting,
        refetch,
        loading: isLoading,
        fetching: isFetching,
      }}
    >
      <Outlet />
    </JobPostingProviderContext.Provider>
  );
};
