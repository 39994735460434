import React, { useEffect } from "react";
import Selector, {
  GroupBase,
  MenuListProps,
  OptionProps,
  Props as ReactSelectProps,
  components,
} from "react-select";
import styled from "@emotion/styled";
import {
  ChevronDownIcon,
  StarIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { StarIcon as FilledStarIcon } from "@heroicons/react/24/solid";
import { Tooltip } from "antd";
import { ErrorMessage } from "@unlikelyai-magic/ui/messages";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";
import {
  useCreateMessageStyleMutation,
  useDeleteMessageStyleMutation,
} from "@jobe/ui/features/outreach";
import { useUpdateUserProfileMutation } from "@jobe/ui/features/users";
import {
  AddMessageStyleModal,
  ConfirmDeleteModal,
  useModal,
} from "@jobe/ui/modals";
import { Note } from "@jobe/ui/typography";

const ToggleMenuIcon = styled(ChevronDownIcon)`
  width: 1.25em;
  height: 1.25em;
`;

const DefaultStarIcon = styled(FilledStarIcon)`
  padding-left: 0.25rem;
  width: 1.125rem;
  color: ${({ theme }) => theme.colors.brand.primary};
  visibility: visible;
  &:hover {
    padding-left: 0.125rem;
  }
`;

const ActionIcon = styled.svg<{ disabled?: boolean }>`
  padding-left: 0.25rem;
  width: 1.125rem;
  color: ${({ theme }) => theme.colors.text.tertiary};

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.tertiary : theme.colors.brand.primary};
    padding-left: ${({ disabled }) => (disabled ? "0.25rem" : "0.125rem")};
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  }
`;

const NewStyleOption = styled.div`
  margin: 0.25rem;
  display: flex;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background.primary};
  color: ${({ theme }) => theme.colors.text.primary};
  border-radius: 1rem;

  &:hover {
    color: ${({ theme }) => theme.colors.brand.accent};
  }
`;

const OptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  cursor: pointer;
`;

const OptionActions = styled.div`
  display: flex;
  visibility: hidden;

  ${OptionContainer}:hover & {
    visibility: visible;
  }
`;

const OptionText = styled.div<{ isSelected: boolean }>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12rem;
  cursor: ${({ isSelected }) => (isSelected ? "default" : "pointer")};
`;

const { Option, DropdownIndicator, MenuList } = components;

export type SelectOption = {
  id: string;
  label: string;
  value: string;
  description?: string;
};

type MessageStyleSelectProps<Value = any> = Omit<
  ReactSelectProps,
  "onChange"
> & {
  onChange: (value: Value) => void;
  refetchMessageStyles: () => void;
  preferredMessageStyleId?: string;
  setPreferredMessageStyleId: (id: string | undefined) => void;
};

type CustomOptionProps = OptionProps<unknown, false, GroupBase<unknown>> & {
  selectProps: MessageStyleSelectProps;
  innerProps: { onClick: (event: React.MouseEvent) => void };
  data: SelectOption;
};

type CustomMenuListProps = MenuListProps<unknown, false, GroupBase<unknown>> & {
  selectProps: MessageStyleSelectProps;
};

const CustomOption = (props: CustomOptionProps) => {
  const { openModal, closeModal } = useModal();
  const [updateUser] = useUpdateUserProfileMutation();
  const [deleteMessageStyle, deleteMessageStyleResult] =
    useDeleteMessageStyleMutation();

  useEffect(() => {
    if (!deleteMessageStyleResult.isLoading) {
      props.selectProps.refetchMessageStyles();
    }
  }, [deleteMessageStyleResult, props.selectProps.refetchMessageStyles]);

  const handleDelete = () => {
    deleteMessageStyle({ messageStyleId: props.data.id });
    if (props.selectProps.preferredMessageStyleId === props.data.id) {
      props.selectProps.setPreferredMessageStyleId(undefined);
      updateUser({ preferredMessageStyleId: undefined });
    }
    closeModal();
  };

  const showConfirmDeleteModal = () => {
    openModal(
      <ConfirmDeleteModal
        description={`This will delete your "${props.data.label}" messaging style and everything Mili has learned about it.`}
        handleConfirm={handleDelete}
      />
    );
  };

  const onDeleteClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!props.isSelected) {
      showConfirmDeleteModal();
    }
  };

  const handleMakeDefault = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.selectProps.setPreferredMessageStyleId(props.data.id);
    updateUser({ preferredMessageStyleId: props.data.id });
  };

  const handleRemoveDefault = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    props.selectProps.setPreferredMessageStyleId(undefined);
    updateUser({ preferredMessageStyleId: undefined });
  };

  const handleOptionClick = (event: React.MouseEvent) => {
    if (props.isSelected) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      props.innerProps.onClick(event);
    }
  };

  return (
    <Option
      {...props}
      innerProps={{ ...props.innerProps, onClick: handleOptionClick }}
    >
      <OptionContainer>
        {props.data.description ? (
          <Tooltip
            placement="left"
            title={props.data.description}
            mouseEnterDelay={0}
            mouseLeaveDelay={0}
          >
            <OptionText isSelected={props.isSelected}>
              {props.data.label}
            </OptionText>
          </Tooltip>
        ) : (
          <OptionText isSelected={props.isSelected}>
            {props.data.label}
          </OptionText>
        )}
        <OptionActions>
          <Tooltip
            placement="right"
            title={
              props.isSelected
                ? "Cannot delete the active style"
                : "Delete this style"
            }
            mouseEnterDelay={0.5}
            mouseLeaveDelay={0}
          >
            <ActionIcon
              as={TrashIcon}
              onClick={onDeleteClick}
              disabled={props.isSelected}
            />
          </Tooltip>
          {props.selectProps.preferredMessageStyleId === props.data.id ? (
            <Tooltip
              placement="right"
              title="Your default style. Click to remove"
              mouseEnterDelay={0.5}
              mouseLeaveDelay={0}
            >
              <DefaultStarIcon onClick={handleRemoveDefault} />
            </Tooltip>
          ) : (
            <Tooltip
              placement="right"
              title="Make this your default style"
              mouseEnterDelay={0.5}
              mouseLeaveDelay={0}
            >
              <ActionIcon as={StarIcon} onClick={handleMakeDefault} />
            </Tooltip>
          )}
        </OptionActions>
      </OptionContainer>
    </Option>
  );
};

const CustomMenuList = (props: CustomMenuListProps) => {
  const { openModal, closeModal } = useModal();
  const [createMessageStyle, createMessageStyleResult] =
    useCreateMessageStyleMutation();
  useEffect(() => {
    if (!createMessageStyleResult.isLoading) {
      props.selectProps.refetchMessageStyles();
    }
  }, [createMessageStyleResult, props.selectProps.refetchMessageStyles]);

  const handleAddStyle = async (
    name: string,
    description?: string,
    message?: string,
    makeStyleDefault?: boolean
  ) => {
    const createMessageStyleResponse = await createMessageStyle({
      name,
      description,
      messages: message
        ? [{ message, copiedAt: new Date().toISOString() }]
        : [],
    });
    if ("data" in createMessageStyleResponse) {
      const { id, name } = createMessageStyleResponse.data.data;
      if (makeStyleDefault) {
        props.selectProps.setPreferredMessageStyleId(id);
      }
      props.selectProps.onChange({ id, value: name, label: name, description });
    } else {
      ErrorMessage("Failed to create message style, please try again later");
    }
    closeModal();
  };

  const showAddMessageStyleModal = (event: React.MouseEvent) => {
    event.stopPropagation();
    openModal(
      <AddMessageStyleModal
        handleConfirm={handleAddStyle}
        messageStyles={[...props.options] as SelectOption[]}
      />
    );
  };

  return (
    <MenuList {...props}>
      {props.children}
      <NewStyleOption onClick={showAddMessageStyleModal}>
        <Note>+ New Style</Note>
      </NewStyleOption>
    </MenuList>
  );
};

export const MessageStyleSelect = styled((props: MessageStyleSelectProps) => {
  return (
    <Selector
      {...props}
      classNamePrefix="react-select"
      components={{
        DropdownIndicator: (props) => (
          <DropdownIndicator {...props}>
            <ToggleMenuIcon />
          </DropdownIndicator>
        ),
        Option: CustomOption as React.ComponentType<
          OptionProps<unknown, boolean, GroupBase<unknown>>
        >,
        MenuList: CustomMenuList as React.ComponentType<
          MenuListProps<unknown, boolean, GroupBase<unknown>>
        >,
      }}
    />
  );
})`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;

  .react-select__control {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border: 1px solid ${({ theme }) => theme.colors.background.secondary} !important;
    border-radius: 0.5rem;
    box-shadow: ${({ theme }) => theme.shadows.sm};
    padding: 0 0.25rem 0 0.5rem;
    min-height: unset;

    &--is-focused {
      border-color: ${({ theme }) =>
        theme.colors.background.secondary} !important;
      box-shadow: ${({ theme }) => theme.shadows.sm} !important;
    }
  }

  .react-select__indicator {
    color: ${({ theme }) => theme.colors.primary};
  }

  .react-select__single-value {
    color: ${({ theme }) => theme.colors.primary};
    ::selection {
      background-color: ${({ theme }) =>
        theme.colors.brand.primary + hexAlphaValue(80)};
      color: ${({ theme }) => theme.colors.text.dark};
    }
  }

  .react-select__option {
    padding: 0.25rem 0.5rem;
    background-color: ${({ theme }) =>
      theme.colors.background.secondary} !important;
    color: ${({ theme }) => theme.colors.text.primary};

    &--is-focused {
      color: ${({ theme }) => theme.colors.brand.primary};
    }

    &--is-selected {
      color: ${({ theme }) => theme.colors.text.secondary};
    }
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__menu {
    width: fit-content;
    margin-top: 0.25rem;
    border-radius: 0.5rem;
    box-shadow: ${({ theme }) => theme.shadows.sm};
  }

  .react-select__menu-list {
    padding: 0.25rem;
    border-radius: 0.5rem;
    min-width: 7rem;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding: 0.25rem;
    width: 1.375rem;
  }
`;
