import styled from "@emotion/styled";
import {
  ParagraphProps,
  Paragraph as UnlikelyAiParagraph,
} from "@unlikelyai-magic/ui/typography";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";

export const Paragraph = styled((props: ParagraphProps) => {
  return <UnlikelyAiParagraph {...props} />;
})`
  ::selection {
    background-color: ${({ theme }) =>
      theme.colors.brand.primary + hexAlphaValue(80)};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;
