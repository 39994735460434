import indefinite from "indefinite";
import { useProfile } from "@jobe/ui/features/authentication";
import { MessageAuthor, MinMaxResponse, useChat } from "@jobe/ui/features/chat";
import { useFindRequirementByNaturalLanguageOrCreateMutation } from "@jobe/ui/features/shared";
import { useCreateJobOpeningChat } from "../hooks";
import { JobRequirementsWidget } from "./JobRequirementsWidget";

export const SelectExperienceWidget = () => {
  const profile = useProfile();
  const { formData, setFormData } = useCreateJobOpeningChat();
  const { pushMessage } = useChat();
  const [getOrCreateRequirement] =
    useFindRequirementByNaturalLanguageOrCreateMutation();

  const handleSubmit = async ([min, max]: [number, number]) => {
    setFormData((prev) => ({
      ...prev,
      experience: {
        min,
        max,
      },
    }));

    pushMessage({
      author: MessageAuthor.User,
      text: `I'm looking for candidates with ${min} to ${max} years of experience.`,
    });

    const [minExperienceRequirement, maxExperienceRequirement] =
      await Promise.all([
        getOrCreateRequirement({
          natLanguageRequirement: `The candidate has at least ${min} year${
            min === 1 ? "" : "s"
          } of experience as ${indefinite(formData.role || "")}`,
        }).unwrap(),
        getOrCreateRequirement({
          natLanguageRequirement: `The candidate has at most ${max} year${
            max === 1 ? "" : "s"
          } of experience as ${indefinite(formData.role || "")}`,
        }).unwrap(),
      ]);

    setFormData((prev) => ({
      ...prev,
      requirements: [
        ...prev.requirements,
        {
          ...minExperienceRequirement.data,
          mustHave: true,
        },
        {
          ...maxExperienceRequirement.data,
          mustHave: true,
        },
      ],
    }));

    pushMessage({
      author: MessageAuthor.Bot,
      text: `Thanks${
        profile ? " " + profile.firstName : ""
      }, here are the requirements I've added, feel free to modify them.`,
      widget: <JobRequirementsWidget />,
    });
  };

  return <MinMaxResponse onSubmit={handleSubmit} />;
};
