import { ReactNode, createContext, useEffect } from "react";
import { PlusIcon } from "@heroicons/react/24/outline";
import { PlumButton } from "@jobe/ui/buttons";
import { useProfile } from "@jobe/ui/features/authentication";
import {
  ChatProvider,
  MessageAuthor,
  useChatMessages,
} from "@jobe/ui/features/chat";

export interface OnboardingChatContext {
  onSubmit: () => void;
}

const defaultChatContext: OnboardingChatContext = {
  onSubmit: () => null,
};

export const OnboardingChatContext =
  createContext<OnboardingChatContext>(defaultChatContext);

export interface OnboardingChatProviderProps {
  onSubmit: () => void;
  children?: ReactNode;
}

export const OnboardingChatProvider = ({
  onSubmit,
  children,
}: OnboardingChatProviderProps) => {
  const state = useChatMessages();
  const profile = useProfile();

  useEffect(() => {
    // Timeout is here because in React strict mode, hooks are executed twice
    // The timeout ensures this is only executed once
    const timeout = setTimeout(() => {
      state.pushMessage({
        author: MessageAuthor.User,
        text: "Hey Mili, I'm new here!",
      });
      state.pushMessage({
        author: MessageAuthor.Bot,
        text: `Nice to meet you${
          profile ? ", " + profile.firstName : ""
        }! Let's get started by creating a job opening to find great matches.`,
        widget: (
          <PlumButton icon={<PlusIcon />} onClick={onSubmit}>
            Create job opening
          </PlumButton>
        ),
      });
    }, 10);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <OnboardingChatContext.Provider
      value={{
        onSubmit,
      }}
    >
      <ChatProvider state={state}>{children}</ChatProvider>
    </OnboardingChatContext.Provider>
  );
};
