import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { LogoPlaceholder } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { EducationQualification } from "@jobe/data-access/api-types";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { resolveFallbackSchoolImage } from "../utils/resolveFallbackSchoolImage";

const InstitutionName = styled(Heading)`
  line-height: 1;
`;

const InstitutionLogo = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: contain;
`;

type EducationQualificationItemProps = ComponentProps<"div"> & {
  qualification: EducationQualification;
};

export const EducationQualificationItem = styled(
  ({ qualification, className }: EducationQualificationItemProps) => {
    const educationDegreeHeader = (
      education: EducationQualification
    ): string => {
      if (!education) return "";
      return [education.type, education.subject].filter(Boolean).join(" - ");
    };

    const schoolImageUrl =
      qualification.institutionLogoUrl ||
      resolveFallbackSchoolImage(qualification.institution);

    return (
      <Spacing gap="lg" direction="horizontal" className={className}>
        {schoolImageUrl ? (
          <InstitutionLogo
            src={schoolImageUrl}
            alt={qualification.institution}
          />
        ) : (
          <LogoPlaceholder />
        )}
        <Spacing gap="3xs" justify="center">
          <InstitutionName level="h5">
            {qualification.institution ?? "unknown institution"}
          </InstitutionName>
          <Paragraph>{educationDegreeHeader(qualification)}</Paragraph>
          <Paragraph small color={colors.slate["400"]}>
            {qualification.fromYear
              ? [
                  qualification.fromYear,
                  qualification.toYear || "present",
                ].join("-")
              : ""}
          </Paragraph>
          {qualification.descriptionSentences.length > 0 && (
            <Paragraph>
              {qualification.descriptionSentences.join("\n")}
            </Paragraph>
          )}
        </Spacing>
      </Spacing>
    );
  }
)`
  padding: 0.375rem 0;
`;
