import styled from "@emotion/styled";
import {
  HeadingProps,
  Heading as UnlikelyAiHeading,
} from "@unlikelyai-magic/ui/typography";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";

export const Heading = styled((props: HeadingProps) => {
  return <UnlikelyAiHeading {...props} />;
})`
  ::selection {
    background-color: ${({ theme }) =>
      theme.colors.brand.primary + hexAlphaValue(80)};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;
