import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "../../types";
import { Button } from "../Button/Button";

export type PrimaryButtonProps = ButtonProps;

export const PrimaryButton = styled(
  ({ size, ...props }: PrimaryButtonProps) => (
    <Button {...props} type="primary" />
  )
)`
  ${({ theme }) => css`
    color: ${theme.colors.text.dark};
    background: ${theme.colors.buttons.primary.default};
    --antd-wave-shadow-color: ${theme.colors.buttons.primary.default};
    &:hover,
    &:focus,
    &:active,
    &.ant-btn-loading {
      color: ${theme.colors.text.dark};
      background: ${theme.colors.buttons.primary.focus};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.dark};
      background: ${theme.colors.buttons.primary.disabled};
    }
  `}
`;
