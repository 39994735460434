import React, { ComponentProps, ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { ProfileDropdownMenu } from "@jobe/ui/features/authentication";
import { useConfig } from "@jobe/ui/features/configs";
import { MiliAiLogo } from "@jobe/ui/mili-logos";
import { theme } from "@jobe/ui/theme";
import { Paragraph } from "@jobe/ui/typography";

const FeedbackLink = styled.a`
  color: ${({ theme }) => theme.colors.text.tertiary};
  text-decoration: underline;

  &:active,
  &:hover,
  &:focus {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.text.primary};
  }
`;

const LeftContent = styled(Spacing)`
  flex: 1;
  display: flex;
  align-items: center;
`;

const RightContent = styled(Spacing)`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const CenterContent = styled(Spacing)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
  z-index: 1;
`;

type AppNavbarProps = ComponentProps<"nav"> & {
  leftContent?: ReactNode;
  centerContent?: ReactNode;
  rightContent?: ReactNode;
};

export const AppNavbar = styled(
  ({
    className,
    leftContent,
    centerContent,
    rightContent,
    ...otherProps
  }: AppNavbarProps) => {
    const { FEEDBACK_FORM_URL } = useConfig();
    return (
      <nav {...otherProps} className={className}>
        <LeftContent direction="horizontal" gap="xl">
          <Link to="/">
            <MiliAiLogo color={theme.colors.brand.primary} />
          </Link>
          {leftContent}
        </LeftContent>
        <CenterContent direction="horizontal">{centerContent}</CenterContent>
        <RightContent direction="horizontal" gap="xl">
          {rightContent}
          {FEEDBACK_FORM_URL && (
            <FeedbackLink
              href={FEEDBACK_FORM_URL}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Paragraph>Leave feedback</Paragraph>
            </FeedbackLink>
          )}
          <ProfileDropdownMenu />
        </RightContent>
      </nav>
    );
  }
)`
  position: sticky;
  display: flex;
  top: 0;
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
  background: ${({ theme }) => theme.colors.background.secondary};
  width: 100%;
  align-items: center;
  z-index: 50;
  box-shadow: ${({ theme }) => theme.shadows.md};
  height: 3.75rem;

  ${MiliAiLogo} {
    height: 1.5rem;
  }
`;
