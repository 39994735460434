import {
  GetMatchSummaryRequest,
  GetMatchSummaryResponse,
  GetOutreachMessageRequest,
  GetOutreachMessageResponse,
  ListMatchesRequest,
  ListMatchesResponse,
  UpdateMatchRequest,
  UpdateMatchResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const matchesApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    listMatches: build.query<ListMatchesResponse, ListMatchesRequest>({
      query: ({ jobPostingId, ...params }) => ({
        url: `/job-postings/${jobPostingId}/matches`,
        params,
      }),
    }),
    getMatchSummary: build.query<
      GetMatchSummaryResponse,
      GetMatchSummaryRequest
    >({
      query: ({ jobPostingId, matchId }) => ({
        url: `/job-postings/${jobPostingId}/matches/${matchId}/summary`,
      }),
    }),
    updateMatch: build.mutation<UpdateMatchResponse, UpdateMatchRequest>({
      query: ({ id, jobPostingId, ...body }) => ({
        url: `/job-postings/${jobPostingId}/matches/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getOutreachMessage: build.query<
      GetOutreachMessageResponse,
      GetOutreachMessageRequest
    >({
      query: ({ match, messageStyleId }) => ({
        url: `/job-postings/${match.jobPostingId}/matches/${match.id}/outreach-message/${messageStyleId}`,
        method: "GET",
        match,
      }),
    }),
  }),
});

export const {
  useListMatchesQuery,
  useLazyListMatchesQuery,
  useGetMatchSummaryQuery,
  useLazyGetMatchSummaryQuery,
  useUpdateMatchMutation,
  useGetOutreachMessageQuery,
} = matchesApi;
