import {
  CreateJobPostingRequest,
  CreateJobPostingResponse,
  GetJobPostingRequest,
  GetJobPostingResponse,
  ListJobPostingsRequest,
  ListJobPostingsResponse,
  UpdateJobPostingRequest,
  UpdateJobPostingResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const jobPostingsApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    listJobPostings: build.query<
      ListJobPostingsResponse,
      ListJobPostingsRequest
    >({
      query: (params) => ({
        url: "/job-postings",
        params,
      }),
      providesTags: [],
    }),
    getJobPosting: build.query<GetJobPostingResponse, GetJobPostingRequest>({
      query: (params) => ({
        url: `/job-postings/${params.id}`,
      }),
      providesTags: ["JobSpecification"],
    }),
    createJobPosting: build.mutation<
      CreateJobPostingResponse,
      CreateJobPostingRequest
    >({
      query: (body) => ({
        url: "/job-postings",
        method: "POST",
        body,
      }),
    }),
    updateJobPosting: build.mutation<
      UpdateJobPostingResponse,
      UpdateJobPostingRequest
    >({
      query: (body) => ({
        url: `/job-postings/${body.id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteJobPosting: build.mutation<void, { jobPostingId: string }>({
      query: (body) => ({
        url: `/job-postings/${body.jobPostingId}`,
        method: "DELETE",
        body,
      }),
    }),
  }),
});

export const {
  useListJobPostingsQuery,
  useLazyListJobPostingsQuery,
  useLazyGetJobPostingQuery,
  useGetJobPostingQuery,
  useCreateJobPostingMutation,
  useUpdateJobPostingMutation,
  useDeleteJobPostingMutation,
} = jobPostingsApi;
