import styled from "@emotion/styled";
import { InputProps } from "antd";
import { Input as UnlikelyAiInput } from "@unlikelyai-magic/ui/inputs";
import { hexAlphaValue } from "@unlikelyai-magic/ui/variables";

export const Input = styled((props: InputProps) => (
  <UnlikelyAiInput {...props} />
))`
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.brand.primary} !important;
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.brand.primary} !important;
    box-shadow: 0 0 0 2px
      ${({ theme }) => theme.colors.brand.primary + hexAlphaValue(20)} !important;
    outline: none;
  }
  ::selection {
    background-color: ${({ theme }) =>
      theme.colors.brand.primary + hexAlphaValue(80)};
    color: ${({ theme }) => theme.colors.text.dark};
  }
`;
