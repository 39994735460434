import accentureLogo from "./accenture_logo.jpeg";
import amazonLogo from "./amazon_logo.jpeg";
import appleLogo from "./apple_logo.jpeg";
import columbiaUniversityLogo from "./columbia_university_logo.jpeg";
import durhamUniversityLogo from "./durham_university_logo.jpeg";
import facebookLogo from "./facebook_logo.jpeg";
import googleLogo from "./google_logo.jpeg";
import harvardUniversityLogo from "./harvard_university.jpeg";
import ibmLogo from "./ibm_logo.jpeg";
import imperialUniversityLogo from "./imperial_college_london_logo.jpeg";
import microsoftLogo from "./microsoft_logo.jpeg";
import mitUniversityLogo from "./mit_logo.jpeg";
import oracleLogo from "./oracle_logo.jpeg";
import oxfordUniversityLogo from "./oxforduni_logo.jpeg";
import princetonUniversityLogo from "./princeton_university_logo.jpeg";
import salesforceLogo from "./salesforce_logo.jpeg";
import stanfordUniversityLogo from "./stanford_university_logo.jpeg";
import ucBerkeleyLogo from "./uc_berkeley_logo.jpeg";
import chicagoUniversityLogo from "./uchicago_logo.jpeg";
import uclUniversityLogo from "./university_college_london_logo.jpeg";
import cambridgeUniversityLogo from "./university_of_cambridge_logo.jpeg";
import stAndrewsUniversityLogo from "./university_of_st_andrews_logo.jpeg";
import edinburghUniversityLogo from "./univesity_of_edinburgh.jpeg";
import yaleUniversityLogo from "./yale_university.jpeg";

export const logos = {
  companies: {
    accenture: accentureLogo,
    amazon: amazonLogo,
    apple: appleLogo,
    facebook: facebookLogo,
    google: googleLogo,
    ibm: ibmLogo,
    microsoft: microsoftLogo,
    oracle: oracleLogo,
    salesforce: salesforceLogo,
  },
  schools: {
    columbia: columbiaUniversityLogo,
    durham: durhamUniversityLogo,
    harvard: harvardUniversityLogo,
    imperial: imperialUniversityLogo,
    mit: mitUniversityLogo,
    oxford: oxfordUniversityLogo,
    princeton: princetonUniversityLogo,
    stanford: stanfordUniversityLogo,
    ucBerkeley: ucBerkeleyLogo,
    chicago: chicagoUniversityLogo,
    ucl: uclUniversityLogo,
    cambridge: cambridgeUniversityLogo,
    stAndrews: stAndrewsUniversityLogo,
    edinburgh: edinburghUniversityLogo,
    yale: yaleUniversityLogo,
  },
};
