import { ComponentProps } from "react";
import styled from "@emotion/styled";

const Dot = styled.div`
  float: left;
  width: 0.375rem;
  height: 0.375rem;
  background: ${({ theme }) => theme.colors.text.tertiary};
  border-radius: 50%;
  opacity: 0;
  animation: loadingFade 1s infinite;
  :nth-of-type(1) {
    animation-delay: 0s;
  }
  :nth-of-type(2) {
    animation-delay: 0.2s;
  }
  :nth-of-type(3) {
    animation-delay: 0.4s;
  }
  @keyframes loadingFade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0;
    }
  }
`;

export const ChatTyping = styled((props: ComponentProps<"div">) => {
  return (
    <div {...props}>
      <Dot />
      <Dot />
      <Dot />
    </div>
  );
})`
  display: flex;
  gap: 0.25rem;
  width: fit-content;
  height: 1.5rem;
  border-radius: 0.25rem;
  align-items: center;
`;
