import {
  CreateJobSpecificationRequest,
  CreateJobSpecificationResponse,
} from "@jobe/data-access/api-types";
import { jobeServiceApi } from "@jobe/ui/store";

export const jobPostingSpecificationsApi = jobeServiceApi.injectEndpoints({
  endpoints: (build) => ({
    replaceJobPostingSpecification: build.mutation<
      CreateJobSpecificationResponse,
      CreateJobSpecificationRequest
    >({
      query: (body) => ({
        url: `/job-postings/${body.jobPostingId}/specification`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["JobSpecification", "Match"],
    }),
  }),
});

export const { useReplaceJobPostingSpecificationMutation } =
  jobPostingSpecificationsApi;
