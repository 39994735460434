import React from "react";
import styled from "@emotion/styled";
import { LogoPlaceholder } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Note, Paragraph } from "@jobe/ui/typography";
import { identifyNonValue } from "../utils";

const Logo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  object-fit: contain;
`;

const CompanyName = styled(Paragraph)`
  line-height: ${({ theme }) => theme.lineHeights.sm};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const JobTitle = styled(Paragraph)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const YearRange = styled(Note)`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

type ItemHighlightsProps = {
  institution: string;
  experience?: string;
  fromYear?: number;
  toYear?: number;
  logoUrl?: string;
};

export const HighlightItem = ({
  institution,
  experience,
  fromYear,
  toYear,
  logoUrl,
}: ItemHighlightsProps) => {
  const yearRange = identifyNonValue(fromYear?.toString())
    ? null
    : `${fromYear} - ${
        identifyNonValue(toYear?.toString()) ? "present" : toYear
      }`;
  return (
    <Spacing gap="sm">
      {logoUrl ? (
        <Logo src={logoUrl} alt={institution} />
      ) : (
        <LogoPlaceholder size="24" />
      )}
      <Spacing gap="3xs">
        <CompanyName>{institution}</CompanyName>
        {!identifyNonValue(experience) && (
          <JobTitle small>{experience}</JobTitle>
        )}
        {yearRange && <YearRange>{yearRange}</YearRange>}
      </Spacing>
    </Spacing>
  );
};
