import React, { ComponentProps } from "react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@jobe/ui/typography";
import { MatchSkillCategories } from "../components";
import { useMatches } from "../hooks";
import { identifyNonValue } from "../utils";
import { resolveFallbackCompanyImage } from "../utils/resolveFallbackCompanyImage";
import { resolveFallbackSchoolImage } from "../utils/resolveFallbackSchoolImage";
import { HighlightItem } from "./HighlightItem";

const HighlightItems = styled(Spacing)`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  width: 100%;
`;

export const TalentHighlights = ({ className }: ComponentProps<"div">) => {
  const { currentMatch } = useMatches();
  const {
    languages,
    employmentHistory,
    educationHistory,
    courses,
    certifications,
  } = currentMatch?.resume || {
    educationHistory: [],
    employmentHistory: [],
    languages: [],
    courses: [],
    certifications: [],
  };
  const allSkills = currentMatch?.resume
    ? Array.from(
        new Set(
          languages
            .map((language) => language.name)
            .concat(
              employmentHistory.flatMap((employment) => employment.skills)
            )
            .concat(courses.map((course) => course.name))
            .concat(certifications.map((certification) => certification.name))
        )
      )
    : [];

  return (
    <Spacing gap="xl" className={className}>
      {employmentHistory.length !== 0 && (
        <Spacing>
          <Heading level="h5">Experience</Heading>
          <HighlightItems gap="xl" direction="horizontal">
            {employmentHistory
              .slice(0, 3)
              .map(({ title, company, fromYear, toYear, companyLogoUrl }) => (
                <HighlightItem
                  key={uuidv4()}
                  institution={company}
                  experience={title}
                  fromYear={fromYear}
                  toYear={toYear}
                  logoUrl={
                    companyLogoUrl || resolveFallbackCompanyImage(company)
                  }
                />
              ))}
          </HighlightItems>
        </Spacing>
      )}
      {educationHistory.length !== 0 && (
        <Spacing>
          <Heading level="h5">Education</Heading>
          <HighlightItems gap="xl" direction="horizontal">
            {educationHistory
              .slice(0, 2)
              .map(
                ({
                  type,
                  subject,
                  institution,
                  fromYear,
                  toYear,
                  institutionLogoUrl,
                }) => (
                  <HighlightItem
                    key={uuidv4()}
                    institution={institution}
                    experience={
                      identifyNonValue(type)
                        ? identifyNonValue(subject)
                          ? ""
                          : subject
                        : identifyNonValue(subject)
                        ? type
                        : type + " - " + subject
                    }
                    fromYear={fromYear}
                    toYear={toYear}
                    logoUrl={
                      institutionLogoUrl ||
                      resolveFallbackSchoolImage(institution)
                    }
                  />
                )
              )}
          </HighlightItems>
        </Spacing>
      )}
      {allSkills.length !== 0 && (
        <Spacing>
          <Heading level="h5">Skills</Heading>
          <MatchSkillCategories skills={allSkills} />
        </Spacing>
      )}
    </Spacing>
  );
};
