import styled from "@emotion/styled";
import {
  CheckCircleIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import {
  JobSpecificationRequirement,
  MatchRequirementExplanation,
} from "@jobe/data-access/api-types";
import { formatReasoning } from "@jobe/data-access/api-types";
import { Paragraph } from "@jobe/ui/typography";

const CheckIcon = styled(CheckCircleIcon)`
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.brand.primary};
`;

const UnsureIcon = styled(QuestionMarkCircleIcon)`
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.brand.accent};
`;

const Explanation = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

type MatchAnalysisListItemProps = {
  requirement: JobSpecificationRequirement;
  explanation?: MatchRequirementExplanation;
};

export const MatchAnalysisListItem = ({
  requirement,
  explanation,
}: MatchAnalysisListItemProps) => {
  const reasons = explanation?.reasoning || [];
  return (
    <Spacing gap="sm" direction="horizontal">
      {explanation ? <CheckIcon /> : <UnsureIcon />}
      <Spacing gap="xs">
        <Paragraph>{requirement.text}</Paragraph>
        {!explanation || reasons.length > 0 ? (
          <Explanation small>
            {explanation
              ? formatReasoning(reasons)
              : "I didn't find enough evidence for this"}
          </Explanation>
        ) : null}
      </Spacing>
    </Spacing>
  );
};
