export const config = {
  production: true,
  JOBE_API_URL: "https://api.jobe.unlikely.ai/api/v1",
  AUTH0_CLIENT_ID: "zj2j84xHv4TgcyrUekiYS9mwAMMisSLY",
  AUTH0_DOMAIN: "auth.mili.ai",
  AUTH0_API_IDENTIFIER: "jobe-prod",
  DARKLY_CLIENT_TOKEN: "609bfeb54a15770c83d293f0",
  DEEPGRAM_ASR_TOKEN: "dc852f8fd8d4809eeb5966929695f259cbfbfa4e",
  DATADOG_APPLICATION_ID: "5c6712d7-ac70-493b-853a-8e067df2464c",
  DATADOG_CLIENT_TOKEN: "pub6664db3fb0edd53198b78d1d517d4703",
  DATADOG_APP_VERSION: process.env.NX_DATADOG_APP_VERSION,
  DATADOG_ENVIRONMENT: "prod",
  MIXPANEL_TOKEN: "1e038e59d744e1698b1f74341f4d2e58",
  HOTJAR_SITE_ID: 3820101,
  FEEDBACK_FORM_URL:
    "https://docs.google.com/forms/d/e/1FAIpQLSfXBdJU4itFpFPR5zgv0NBGwCw2nJvxIk2JF8LbYwSXLfxBKA/viewform",
  GEONAMES_API_BASE_PATH: "https://secure.geonames.org/searchJSON",
  GEONAMES_USERNAME: "mili_chat_ui",
};
