import styled from "@emotion/styled";
import { Spacing, SpacingProps } from "@unlikelyai-magic/ui/layouts";
import { spacings } from "@unlikelyai-magic/ui/variables";
import { PageLayout } from "@jobe/ui/layouts";
import { StarsNoBackground } from "@jobe/ui/mili-logos";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useReplaceJobPostingSpecificationMutation } from "../apis";
import { useJobPosting } from "../hooks";
import { EditRequirementsSideBar } from "./EditRequirementsSideBar";

const PageContent = styled(PageLayout)`
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  height: calc(100vh - 3.75rem);
  overflow: hidden;
`;

const NoMatchesCard = styled(Spacing)`
  display: flex;
  margin: 6rem auto;
  padding: ${spacings["2xl"]} ${spacings["2xl"]};
  background: ${({ theme }) => theme.colors.background.secondary};
  height: fit-content;
  border-radius: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xl};
`;

const TitleContainer = styled(Spacing)`
  justify-content: space-between;
  flex-direction: row;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const SupportText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.secondary};
`;

const Tip = styled(Spacing)<SpacingProps>`
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.text.highlightBackground};
  flex-direction: row;
  color: ${({ theme }) => theme.colors.text.highlight};
  ${Heading} {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
`;

const Email = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.brand.accent};
`;

export const NoMatches = () => {
  const { jobPosting } = useJobPosting();
  const [replaceJobPostingSpecification] =
    useReplaceJobPostingSpecificationMutation();

  if (!jobPosting) return;

  return (
    <PageContent>
      <NoMatchesCard>
        <TitleContainer>
          <Heading level="h2">No matches found!</Heading>
        </TitleContainer>
        <Paragraph>
          I can't find any candidates that match all your 'must have'
          requirements.
        </Paragraph>
        <Tip gap="sm">
          <StarsNoBackground size="1.5rem" />
          <Heading level="h5">Top tip</Heading>
          <Paragraph>
            Try removing some 'must have' requirements, or moving some to 'nice
            to have'.
          </Paragraph>
        </Tip>
        <Spacing gap="xs" direction="horizontal">
          <SupportText>
            If you’re still having trouble, please contact us at
          </SupportText>
          <Email>mili-support@unlikely.ai</Email>
        </Spacing>
      </NoMatchesCard>
      <EditRequirementsSideBar
        jobPosting={jobPosting}
        onSubmit={async (requirements) => {
          await replaceJobPostingSpecification({
            name: jobPosting.name,
            jobPostingId: jobPosting.id,
            requirements,
          }).unwrap();
        }}
      />
    </PageContent>
  );
};
