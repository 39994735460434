import { ComponentProps, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "@emotion/styled";
import { Tab, Tabs } from "@unlikelyai-magic/ui/tabs";
import { screenBreakpoints } from "@unlikelyai-magic/ui/variables";
import { Card } from "@jobe/ui/cards";
import { Paragraph } from "@jobe/ui/typography";
import { useJobPosting } from "../../job-postings";
import { useGetMatchSummaryQuery, useUpdateMatchMutation } from "../apis";
import { useMatches } from "../hooks";
import { MatchCardEducationTab } from "./MatchCardEducationTab";
import { MatchCardExperienceTab } from "./MatchCardExperienceTab";
import { MatchCardTalentInsightTab } from "./MatchCardTalentInsightTab";
import { MatchHeader } from "./MatchHeader";

const CardTab = styled(Tab)`
  &.active {
    border-color: ${({ theme }) => theme.colors.brand.primary};
  }
`;

export const MatchCard = styled(({ className }: ComponentProps<"div">) => {
  const { jobPosting } = useJobPosting();
  const { currentMatch, likeMatch, unlikeMatch, openOutreachModal } =
    useMatches();
  const [updateMatch] = useUpdateMatchMutation();

  // Eagerly generate the match summary so that its ready when the user opens the match analysis
  // sidebar but also so that one is generated for the viewed and liked page.
  useGetMatchSummaryQuery(
    {
      jobPostingId: jobPosting?.id ?? "",
      matchId: currentMatch?.id ?? "",
    },
    {
      skip: !jobPosting || !currentMatch,
    }
  );

  useEffect(() => {
    if (!currentMatch) return;
    if (currentMatch.seenAt) return;
    updateMatch({
      ...currentMatch,
      seenAt: new Date().toISOString(),
    });
  }, [currentMatch]);

  if (!currentMatch) return null;

  return (
    <Card className={className}>
      <MatchHeader
        match={currentMatch}
        onLike={(event) => {
          currentMatch?.likedAt
            ? unlikeMatch(currentMatch)
            : likeMatch(currentMatch);
          event.currentTarget.blur();
        }}
        onMessage={() => openOutreachModal(currentMatch)}
      />
      <Tabs>
        <CardTab to="insights">
          <Paragraph>Talent insight</Paragraph>
        </CardTab>
        <CardTab to="experience">
          <Paragraph>Work experience</Paragraph>
        </CardTab>
        <CardTab to="education">
          <Paragraph>Education</Paragraph>
        </CardTab>
      </Tabs>
      <Routes>
        <Route path="insights" element={<MatchCardTalentInsightTab />} />
        <Route path="experience" element={<MatchCardExperienceTab />} />
        <Route path="education" element={<MatchCardEducationTab />} />
        <Route index element={<Navigate to="insights" />} />
      </Routes>
    </Card>
  );
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  border-radius: 1.5rem;
  box-shadow: ${({ theme }) => theme.shadows.xl};

  @media (min-width: ${screenBreakpoints.sm}) {
    padding: 2.5rem 2rem;
  }

  @media (min-width: ${screenBreakpoints.lg}) {
    max-width: 64rem;
  }
`;
