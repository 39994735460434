import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { colors } from "@unlikelyai-magic/ui/variables";

export type LoadingIconProps = ComponentProps<"svg"> & {
  color?: string;
  size?: string;
};

export const LoadingIcon = styled((props: LoadingIconProps) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
      <path
        fill="none"
        strokeWidth="5"
        d="M25 5
           m-20,20
           a 20,20 0 1,0 40,0
           a 20,20 0 1,0 -40,0"
        strokeLinecap="round"
        strokeDasharray="90"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 25 25"
          to="360 25 25"
          dur="1s"
          repeatCount="indefinite"
        />
      </path>
    </svg>
  );
})`
  width: ${({ size }) => size || "1rem"};
  height: ${({ size }) => size || "1rem"};

  path {
    stroke: ${({ color }) => color || colors.unlikelyai.orange};
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
