const parseTranslation = (item: string): string => {
  let parsed;
  try {
    const reasonsObject = JSON.parse(item);
    parsed = reasonsObject["translation"].replaceAll("\n", ". ");
  } catch (e) {
    parsed = item;
  }
  // Remove excessive spaces and full stops
  const sanitised = parsed
    .replaceAll(/\s{2,}/g, " ")
    .replaceAll(/\.{2,}/g, ".")
    .trim();
  if (sanitised.endsWith(".")) {
    return sanitised;
  }
  return sanitised + ".";
};

export const formatReasoning = (reasons: string[]) => {
  const items = reasons
    .map(parseTranslation)
    .filter((item) => /^.*[a-zA-Z].*$/g.test(item)); // remove items that don't contain any letters
  if (!items.length)
    return "I was able to derive this from the candidate's LinkedIn profile.";
  if (items[0].startsWith("Therefore, ")) {
    items[0] = items[0].replace("Therefore, ", "");
  }
  const joined = items.join(" ").trim();
  return joined.charAt(0).toUpperCase() + joined.slice(1);
};
