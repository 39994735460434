import React, { ComponentProps, useMemo } from "react";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { Heading } from "@jobe/ui/typography";
import { useMatches } from "../hooks";
import { Employment } from "../types";
import { JobExperienceListItem } from "./JobExperienceListItem";

export const MatchCardExperienceTab = ({
  className,
}: ComponentProps<"div">) => {
  const { currentMatch } = useMatches();

  const employments: Employment[] = useMemo(() => {
    if (!currentMatch?.resume.employmentHistory.length) return [];
    const employments = currentMatch?.resume.employmentHistory.reduce<
      Record<string, Employment>
    >((companies, job) => {
      if (!companies[job.company]) {
        companies[job.company] = {
          company: job.company,
          companyLogoUrl: job.companyLogoUrl,
          positions: [],
        };
      }
      companies[job.company].positions.push(job);
      return companies;
    }, {});
    return Object.values(employments) as Employment[];
  }, [currentMatch?.resume.employmentHistory]);

  return (
    <Spacing gap="lg" className={className}>
      <Heading level="h5">Work experience</Heading>
      {employments.map((employment) => (
        <JobExperienceListItem employment={employment} />
      ))}
    </Spacing>
  );
};
