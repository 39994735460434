import { ReactElement, useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { selectAccessToken } from "../selectors";

/**
 * Restricted layout component that redirects to the login page if the user is not authenticated.
 * @usage <Route path="/secure/route" element={<Restricted />} />
 */
export const Restricted = (): ReactElement | null => {
  const accessToken = useSelector(selectAccessToken);
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) return;
    // If the user is not authenticated and auth0 has been loaded, redirect to the login page.
    loginWithRedirect();
  }, [isLoading, isAuthenticated]);

  // Wait for auth0 to load
  if (isLoading) return null;

  // When an access token is available, render the children.
  return accessToken ? <Outlet /> : null;
};
