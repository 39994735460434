import React, { ComponentProps } from "react";
import styled from "@emotion/styled";
import { v4 as uuidv4 } from "uuid";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { StarsNoBackground } from "@jobe/ui/mili-logos";
import { Paragraph } from "@jobe/ui/typography";

const StarsIcon = styled(StarsNoBackground)`
  flex-shrink: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-top: 0.25rem;
  color: ${({ theme }) => theme.colors.brand.primary};
`;

const BoldParagraph = styled(Paragraph)`
  font-weight: bold;
`;

type MatchSummaryKeyPointsProps = ComponentProps<"div"> & {
  summary: string;
  maxSummaryItems?: number;
};

// Merge summaries that end with a colon with the next summary, so that titles are kept together with their content
const mergeSummariesWithColon = (summaries: string[]): string[] => {
  const result: string[] = [];
  let i = 0;

  while (i < summaries.length) {
    let current = summaries[i];

    if (current.endsWith(":") && i < summaries.length - 1) {
      current += " " + summaries[i + 1];
      i++; // skip the next string since it's merged with the current
    }

    result.push(current);
    i++;
  }

  return result;
};

export const MatchSummaryKeyPoints = styled(
  ({ summary, maxSummaryItems = 4, className }: MatchSummaryKeyPointsProps) => {
    const summaryBulletPoints = mergeSummariesWithColon(
      summary
        .split("\n")
        .map((s) => s.trim().replace(/^-\s/, "").replaceAll("**", ""))
        .filter(
          (s) => s && !s.startsWith("Requirement") && !s.startsWith("Match")
        )
        .slice(0, maxSummaryItems)
    );

    return (
      <Spacing className={className}>
        {summaryBulletPoints.slice(0, maxSummaryItems).map((summaryItem) => {
          const itemSections = summaryItem.split(":");
          let itemTitle = itemSections[0].trim();
          let itemText = itemSections[1]?.trim();
          if (!itemText) {
            itemText = itemTitle;
            itemTitle = "";
          }
          return (
            <Spacing gap="sm" direction="horizontal" key={uuidv4()}>
              <StarsIcon />
              <div>
                <BoldParagraph>{itemTitle}</BoldParagraph>
                <Paragraph>{itemText}</Paragraph>
              </div>
            </Spacing>
          );
        })}
      </Spacing>
    );
  }
)``;
