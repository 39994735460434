import { Select } from "@unlikelyai-magic/ui/inputs";
import countries from "../static/countries.json";

type CountrySelectorProps<Value> = {
  name: string;
  value?: Value;
  multi?: boolean;
  placeholder?: string;
  onChange: (value?: Value) => void;
};

export const CountrySelector = <Value extends object>({
  name,
  value,
  multi,
  placeholder = multi ? "Select countries" : "Select a country",
  onChange,
}: CountrySelectorProps<Value>) => {
  return (
    <Select
      name={name}
      value={value}
      isMulti={multi}
      options={countries.map((country) => ({
        ...country,
        label: country.emoji + " " + country.label,
      }))}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};
