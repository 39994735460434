import { DropdownOption, DropdownResponse } from "@jobe/ui/features/chat";
import { useConfig } from "@jobe/ui/features/configs";
import { useFindRequirementByNaturalLanguageOrCreateMutation } from "@jobe/ui/features/shared";
import { useCreateJobOpeningChat } from "../hooks";

export const SelectLocationWidget = () => {
  const { formData, setFormData } = useCreateJobOpeningChat();
  const [getOrCreateRequirement] =
    useFindRequirementByNaturalLanguageOrCreateMutation();
  const configData = useConfig();
  const fuzzyFindThreshold = 0.3;

  const fetchOptions = async (query: string): Promise<DropdownOption[]> => {
    const response = await fetch(
      `${(configData as any).GEONAMES_API_BASE_PATH}?name=${encodeURIComponent(
        query
      )}&featureClass=P&featureClass=A&fuzzy=${fuzzyFindThreshold}&username=${
        configData.GEONAMES_USERNAME
      }`
    );

    const data = await response.json();
    if (!data || !data["geonames"]) {
      return [];
    }

    const seen = new Set();
    const locations: DropdownOption[] = [];

    data["geonames"].forEach(
      (location: { name: string; countryName: string }) => {
        const isCountry = location.name === location.countryName;
        const fullLocation = isCountry
          ? location.name
          : `${location.name}, ${location.countryName}`;
        if (!seen.has(fullLocation)) {
          seen.add(fullLocation);
          locations.push({
            value: fullLocation,
            label: location.name,
            subLabel: isCountry ? undefined : location.countryName,
            disabled: false,
          });
        }
      }
    );

    return locations;
  };

  const handleSubmit = async (value?: string) => {
    if (value) {
      const response = await getOrCreateRequirement({
        natLanguageRequirement: `The candidate is based in ${value}`,
      }).unwrap();

      setFormData((prev) => ({
        ...prev,
        requirements: [
          ...prev.requirements,
          {
            ...response.data,
            mustHave: true,
          },
        ],
      }));
    }

    setFormData((prev) => ({ ...prev, location: value || "" }));
  };

  if (formData.location !== undefined) {
    return null;
  }

  return (
    <DropdownResponse
      cancelText="No preference"
      submitText="Continue"
      onSubmit={handleSubmit}
      fetchDropdownOptions={fetchOptions}
    />
  );
};
