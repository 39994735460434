import { createMockDatabase } from "./createMockDatabase";

export class MockDatabase {
  private static instance: Loki;

  private constructor() {
    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
  }

  /**
   * The static method that controls the access to the singleton instance.
   */
  public static getInstance(): Loki {
    if (!MockDatabase.instance) {
      MockDatabase.instance = createMockDatabase();
    }
    return MockDatabase.instance;
  }
}
