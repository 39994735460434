import { ComponentProps } from "react";
import { CSSObject } from "@emotion/react";
import styled from "@emotion/styled";
import { fontSizes, lineHeights } from "@unlikelyai-magic/ui/variables";

export type ParagraphProps = ComponentProps<"p"> & {
  small?: boolean;
  color?: string;
  textAlign?: "left" | "center" | "right";
};

export const Paragraph = styled(({ small, ...props }: ParagraphProps) => {
  return <p {...props} />;
})`
  font-size: ${({ small }) => (small ? fontSizes.sm : fontSizes.md)};
  line-height: ${({ small }) => (small ? lineHeights.sm : lineHeights.md)};
  color: ${(props) => props.color || "currentColor"};
  margin-bottom: 0;
  white-space: pre-wrap;
  ${({ textAlign }): CSSObject => ({
    textAlign,
  })};
`;
