import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AuthenticationSliceState, RootState } from "./store";

export const jobeServiceApi = createApi({
  reducerPath: "jobeServiceApi",
  baseQuery: async (args, api, extraOptions) => {
    const baseUrl = (api.getState() as RootState).config.jobeApiBaseUrl;
    const fetch = fetchBaseQuery({
      baseUrl,
      prepareHeaders: (headers, { getState }) => {
        const state = getState() as {
          authentication: AuthenticationSliceState;
        };
        headers.set("Content-Type", "application/json");

        // Add authentication token to each request
        if (state.authentication.accessToken) {
          headers.set(
            "Authorization",
            `Bearer ${state.authentication.accessToken}`
          );
        }
        return headers;
      },
    });
    return fetch(args, api, extraOptions);
  },
  tagTypes: ["JobSpecification", "Match"],
  endpoints: () => ({}),
});
