import styled from "@emotion/styled";
import { Input as BaseInput, InputProps } from "antd";
import { inputStyles } from "../../styles/input.styles";

export const Input = styled((props: InputProps) => {
  return <BaseInput {...props} />;
})`
  ${inputStyles.text};
  ${inputStyles.border};
  padding: 0.4375rem 0.5rem;

  &:focus {
    ${inputStyles["border:focus"]};
  }

  ::placeholder {
    ${inputStyles.placeholder};
  }
`;
