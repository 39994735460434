import AWS from "aws-sdk";

const AWS_SECRETS_MANAGER = new AWS.SecretsManager();

export const getAWSSecretValue = async (secretARN: string) => {
  const { SecretString } = await AWS_SECRETS_MANAGER.getSecretValue({
    SecretId: secretARN,
  }).promise();

  if (!SecretString) throw Error(`Unable to find secret with ARN ${secretARN}`);
  return SecretString;
};
