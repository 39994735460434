import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "../../types/buttonProps.types";
import { Button } from "../Button/Button";

export type RedButtonProps = ButtonProps;

export const RedButton = styled(({ size, ...props }: RedButtonProps) => (
  <Button {...props} />
))`
  ${({ theme }) => css`
    color: ${theme.colors.red["500"]};
    background: ${theme.colors.red["100"]};
    --antd-wave-shadow-color: ${theme.colors.red["500"]};

    &:hover,
    &:focus,
    &.ant-btn-loading {
      color: ${theme.colors.red["500"]};
      background: ${theme.colors.red["200"]};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.red["300"]};
      background: ${theme.colors.red["50"]};
    }
  `}
`;
