import { ComponentProps } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { MessageAuthor } from "@unlikelyai-magic/ui/chat";
import { MiliTheme } from "@jobe/ui/theme";

const botBubbleStyles = (theme: MiliTheme) => css`
  background: ${theme.colors.background.secondary};
  color: ${theme.colors.text.primary};
  border-bottom-left-radius: 0;
`;

const userBubbleStyles = (theme: MiliTheme) => css`
  background: ${theme.colors.mili.plum};
  color: ${theme.colors.background.secondary};
  border-bottom-right-radius: 0;
`;

export type ChatBubbleProps = ComponentProps<"div"> & {
  author: MessageAuthor;
};

export const ChatBubble = styled(({ children, ...props }: ChatBubbleProps) => {
  return <div {...props}>{children}</div>;
})`
  ${({ theme, author }) => css`
    display: flex;
    position: relative;
    gap: 0.5rem;
    border-radius: 1rem;
    padding: 0.5rem 0.75rem;
    box-shadow: ${theme.shadows.sm};
    max-width: 80%;

    ${author === MessageAuthor.Bot
      ? botBubbleStyles(theme)
      : userBubbleStyles(theme)};

    @media (min-width: ${theme.screenBreakpoints.md}) {
      max-width: 75%;
    }
  `};
`;
