import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "../../types/buttonProps.types";
import { Button } from "../Button/Button";

export type WhiteButtonProps = ButtonProps;

export const WhiteButton = styled(({ size, ...props }: WhiteButtonProps) => (
  <Button {...props} />
))`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    background: ${theme.colors.background.secondary};
    border: 1px solid ${theme.colors.mili.plum};
    --antd-wave-shadow-color: ${theme.colors.brand.primary};

    &:hover,
    &:focus,
    &:active,
    &.ant-btn-loading {
      color: ${theme.colors.text.dark};
      background: ${theme.colors.mili.plum + 90};
      border: 1px solid ${theme.colors.mili.plum + 10};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.tertiary};
      background: ${theme.colors.buttons.secondary.focus};
    }
  `}
`;
