import { ComponentProps, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Avatar } from "@unlikelyai-magic/ui/avatars";
import { Message, MessageAuthor } from "@unlikelyai-magic/ui/chat";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { useProfile } from "@jobe/ui/features/authentication";
import { StarsWithBackground } from "@jobe/ui/mili-logos";
import { theme } from "@jobe/ui/theme";
import { Paragraph } from "@jobe/ui/typography";
import { ChatBubble } from "./ChatBubble";
import { ChatTyping } from "./ChatTyping";

const BotAvatar = styled(StarsWithBackground)`
  width: 2.25rem;
  height: 2.25rem;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.text.dark};
`;

const ChatWidgetContainer = styled.div<{ message: Message }>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ message }) =>
    message.author === MessageAuthor.Bot ? "flex-start" : "flex-end"};

  &:empty {
    display: none;
  }

  ${({ message }) => ({
    paddingLeft: message.author === MessageAuthor.User ? "0" : "3.125rem",
    paddingRight: message.author === MessageAuthor.Bot ? "0" : "3.125rem",
  })};
`;

const UserAvatar = styled(Avatar)`
  flex-shrink: 0;
  border-radius: 50%;
  object-position: center center;
  object-fit: cover;
  background-color: ${({ theme }) => theme.colors.brand.primary};
`;

type ChatMessageProps = ComponentProps<"div"> & {
  message: Message;
};

export const ChatMessage = styled(
  ({ message, className }: ChatMessageProps) => {
    const profile = useProfile();
    const [isExpanded, setIsExpanded] = useState(true);

    const MAX_LENGTH_BEFORE_COLLAPSE = 300;

    useEffect(() => {
      if (message.author !== MessageAuthor.User) return;
      if (typeof message.text !== "string") return;
      if ((message.text?.length || 0) > MAX_LENGTH_BEFORE_COLLAPSE) {
        setIsExpanded(false);
      }
    }, [message.text]);

    const toggleExpand = () => {
      if (message.author !== MessageAuthor.User) return;
      if (typeof message.text !== "string") return;
      if ((message.text?.length || 0) <= MAX_LENGTH_BEFORE_COLLAPSE) return;
      setIsExpanded(!isExpanded);
    };

    const initials = profile
      ? profile.firstName.slice(0, 1).toUpperCase() +
        profile.lastName.slice(0, 1).toUpperCase()
      : "me";

    return (
      <Spacing>
        <div className={className}>
          {message.author === MessageAuthor.Bot && (
            <BotAvatar backgroundColor={theme.colors.brand.primary} />
          )}
          <ChatBubble author={message.author} onClick={toggleExpand}>
            {!message.text && message.author === MessageAuthor.Bot ? (
              <ChatTyping />
            ) : typeof message.text !== "string" ? (
              message.text
            ) : (
              <Paragraph>
                {isExpanded
                  ? message.text
                  : `${message.text?.slice(
                      0,
                      MAX_LENGTH_BEFORE_COLLAPSE
                    )}  ...More`}
              </Paragraph>
            )}
          </ChatBubble>
          {message.author === MessageAuthor.User && (
            <UserAvatar
              src={message.profileImageUrl || profile?.profileImageUrl}
              alt={initials}
              size="sm"
            />
          )}
        </div>
        {message.widget && (
          <ChatWidgetContainer message={message}>
            {message.widget}
          </ChatWidgetContainer>
        )}
      </Spacing>
    );
  }
)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: ${({ message }) =>
    message.author === MessageAuthor.Bot ? "flex-start" : "flex-end"};
  gap: 0.625rem;
  align-self: ${({ message }) =>
    message.author === MessageAuthor.Bot ? "flex-start" : "flex-end"};
`;
