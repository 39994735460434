import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ButtonProps } from "../../types/buttonProps.types";
import { Button } from "../Button/Button";

export type SecondaryButtonProps = ButtonProps;

export const SecondaryButton = styled(
  ({ size, ...props }: SecondaryButtonProps) => <Button {...props} />
)`
  ${({ theme }) => css`
    color: ${theme.colors.text.primary};
    background: ${theme.colors.buttons.secondary.default};
    --antd-wave-shadow-color: ${theme.colors.buttons.secondary.default};

    &:hover,
    &:focus,
    &:active,
    &.ant-btn-loading {
      color: ${theme.colors.text.primary};
      background: ${theme.colors.buttons.secondary.focus};
    }

    &:disabled,
    &:disabled:hover {
      color: ${theme.colors.text.tertiary};
      background: ${theme.colors.buttons.secondary.disabled};
    }
  `}
`;
