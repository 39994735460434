import { ReactElement, ReactNode, useEffect } from "react";
import mixpanel, { Dict } from "mixpanel-browser";
import { UIAction, UXFlow } from "../actions";
import { Context, MetricsContext } from "../hooks";

type MetricsProviderProps = {
  mixpanelToken: string;
  children?: ReactNode | ReactNode[];
};

export const MetricsProvider = ({
  mixpanelToken,
  children,
}: MetricsProviderProps): ReactElement => {
  useEffect(() => {
    if (!mixpanelToken) return;
    mixpanel.init(mixpanelToken, {
      track_pageview: "full-url",
    });
  }, [mixpanelToken]);

  const metricsRecorder: MetricsContext = {
    /**
     * Identify a user with a unique ID to track user activity across devices, tie a user to their events, and create a user profile. If you never call this method, unique visitors are tracked using a UUID generated the first time they visit the site.
     *
     * Call identify when you know the identity of the current user, typically after login or signup. We recommend against using identify for anonymous visitors to your site.
     * @param id user id
     */
    identify: (id?: string) => {
      mixpanel.identify(id);
    },
    /**
     * Track an event. This is the most important and frequently used Mixpanel function.
     * @param flow - UX flow that the action is part of
     * @param action - UI action being performed
     * @param properties A set of properties to include with the event you're sending. These describe the user who did the event or details about the event itself. We tend to use the value property for most events.
     */
    track: <F extends UXFlow, A extends UIAction<F>>(
      flow: F,
      action: A,
      properties?: Dict
    ) => {
      mixpanel.track(action, properties);
    },
    people: {
      /**
       * Set properties on a user record.
       * @param props If a string, this is the name of the property. If an object, this is an associative array of names and values.
       */
      set: (props: Dict) => {
        mixpanel.people.set(props);
      },
    },
  };
  return (
    <Context.Provider value={metricsRecorder}>{children}</Context.Provider>
  );
};
