import { Link, LinkProps } from "react-router-dom";
import styled from "@emotion/styled";
import { Button } from "antd";
import { colors } from "@unlikelyai-magic/ui/variables";
import { buttonStyles } from "../../styles";
import { ButtonProps } from "../../types";

export type LinkButtonProps = ButtonProps &
  Pick<LinkProps, "to" | "replace" | "state">;

export const LinkButton = styled(
  ({ to, replace, state, size, ...props }: LinkButtonProps) => (
    <Link to={to} replace={replace} state={state}>
      <Button {...props} size="middle" type="link" />
    </Link>
  )
)`
  ${({ size = "md" }) => buttonStyles[size]};
  color: ${colors.blue["500"]};
  background: ${colors.transparent};
  --antd-wave-shadow-color: ${colors.blue["500"]};

  &:hover,
  &.ant-btn-loading {
    color: ${colors.blue["500"]};
    background: ${colors.blue["50"]};
  }

  &:focus {
    color: ${colors.blue["500"]};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.blue["300"]};
    background: ${colors.transparent};
  }
`;
