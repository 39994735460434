import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import {
  JobPosting,
  JobSpecificationRequirement,
} from "@jobe/data-access/api-types";
import { StarsNoBackground } from "@jobe/ui/mili-logos";
import { Heading } from "@jobe/ui/typography";
import { EditRequirementsContent } from "../../job-requirements";

const SideBarContainer = styled(Spacing)`
  width: 31.25rem;
  height: 100%;
  overflow: hidden;
  box-shadow: ${({ theme }) => theme.shadows.xl};
`;

const Panel = styled(Spacing)`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.background.secondary};
`;

const HeaderContainer = styled(Spacing)`
  color: ${({ theme }) => theme.colors.text.dark};
  background-color: ${({ theme }) => theme.colors.mili.plum};
  padding: 2rem;
  justify-content: space-between;
  width: 100%;
`;

const ContentContainer = styled(Spacing)`
  padding: 1rem 2rem;
  overflow: hidden;
  height: 100%;
`;

type EditRequirementsSideBarProps = {
  jobPosting: JobPosting;
  onSubmit: (requirements: JobSpecificationRequirement[]) => Promise<void>;
};

export const EditRequirementsSideBar = ({
  jobPosting,
  onSubmit,
}: EditRequirementsSideBarProps) => {
  const [requirements, setRequirements] = useState<
    JobSpecificationRequirement[]
  >([]);

  useEffect(() => {
    if (!jobPosting.specification) return;
    setRequirements(jobPosting.specification.requirements);
  }, [jobPosting.specification]);

  return (
    <SideBarContainer>
      <Panel>
        <HeaderContainer>
          <Spacing gap="sm" direction="horizontal">
            <StarsNoBackground size="2rem" />
            <Heading level="h3">Edit requirements</Heading>
          </Spacing>
        </HeaderContainer>
        <ContentContainer>
          <EditRequirementsContent
            onSubmit={onSubmit}
            requirements={requirements}
            setRequirements={setRequirements}
          />
        </ContentContainer>
      </Panel>
    </SideBarContainer>
  );
};
