import { ComponentProps } from "react";
import styled from "@emotion/styled";

export const MiliAiLogo = styled((props: ComponentProps<"svg">) => {
  return (
    <svg
      viewBox="0 0 67 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8326 20.5237V23.7136H33.3052V20.5237H31.3287V7.14498H25.8326V10.3349H27.8091V20.5237H25.8326Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.23334H6.43083L8.66062 19.943L10.8904 8.23334H17.3212V23.7231H13.5599V12.3267L11.3227 23.7231H5.99826L3.76133 12.2132V23.7231H0V8.23334Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7201 23.7136V15.1253H18.851V11.957H24.2182V23.7136H20.7201Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.8762 23.7144V15.1261H33.0071V11.9577H38.3743V23.7144H34.8762Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.0105 19.7047C40.7876 19.7047 39.9499 20.6108 39.9499 21.7096C39.9499 22.8085 40.7876 23.7146 42.0105 23.7146C43.2394 23.7146 44.0281 22.8024 44.0281 21.7096C44.0281 20.6169 43.2394 19.7047 42.0105 19.7047Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.1913 11.9628H56.711V23.7195H54.6504C54.6069 23.7195 54.564 23.7177 54.5219 23.7143H53.217V22.5771C52.9202 22.9669 52.5527 23.3073 52.0749 23.5574C51.5379 23.8386 50.8726 24 50.0314 24C47.0043 24 44.6194 21.3236 44.6194 17.8304C44.6194 14.3152 46.9831 11.6823 50.0099 11.6823C51.4346 11.6823 52.487 12.2139 53.1913 12.9745V11.9628ZM52.4821 15.8166C52.0246 15.3572 51.3929 15.1096 50.6974 15.1096C49.306 15.1096 48.1819 16.1602 48.1819 17.852C48.1819 19.5416 49.3256 20.5943 50.7189 20.5943C52.2253 20.5943 53.2343 19.3645 53.2343 17.852C53.2343 16.9576 52.9385 16.2751 52.4821 15.8166Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.1411 15.1261H58.272V11.9577H63.6392V23.7143H60.1411V15.1261Z"
        fill="currentColor"
      />
      <path
        d="M20.3079 8.97464C20.3079 7.96477 21.123 7.14612 22.1284 7.14612H22.2062C23.2116 7.14612 24.0266 7.96477 24.0266 8.97464C24.0266 9.9845 23.2116 10.8031 22.2062 10.8031H22.1284C21.123 10.8031 20.3079 9.9845 20.3079 8.97464Z"
        fill="currentColor"
      />
      <path
        d="M36.2845 7.14612C35.2791 7.14612 34.464 7.96477 34.464 8.97464C34.464 9.9845 35.2791 10.8031 36.2845 10.8031H36.3623C37.3677 10.8031 38.1828 9.9845 38.1828 8.97464C38.1828 7.96477 37.3677 7.14612 36.3623 7.14612H36.2845Z"
        fill="currentColor"
      />
      <path
        d="M57.1941 7.07345C59.2624 7.07345 60.939 5.38939 60.939 3.31199C60.939 5.38939 62.6157 7.07345 64.6839 7.07345C62.6157 7.07345 60.939 8.75752 60.939 10.8349C60.939 8.75752 59.2624 7.07345 57.1941 7.07345Z"
        fill="currentColor"
      />
      <path
        d="M55.8937 3.81114C57.012 3.45672 57.6328 2.25975 57.2803 1.13762C57.6328 2.25975 58.8252 2.8821 59.9435 2.52768C58.8252 2.8821 58.2043 4.07907 58.5568 5.2012C58.2043 4.07907 57.012 3.45672 55.8937 3.81114Z"
        fill="currentColor"
      />
      <path
        d="M61.2164 1.80923C62.6478 2.24629 64.1606 1.43627 64.5953 0C64.1606 1.43627 64.9686 2.95491 66.4 3.39196C64.9686 2.95491 63.4558 3.76493 63.0211 5.2012C63.4558 3.76493 62.6478 2.24629 61.2164 1.80923Z"
        fill="currentColor"
      />
    </svg>
  );
})``;
