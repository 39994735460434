import React, { ComponentProps, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { ArrowPathIcon, Square2StackIcon } from "@heroicons/react/24/solid";
import { Modal } from "antd";
import { LinkedInLogo } from "@unlikelyai-magic/ui/illustrations";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { ErrorMessage } from "@unlikelyai-magic/ui/messages";
import { Match } from "@jobe/data-access/api-types";
import { UUID } from "@jobe/data-access/shared-types";
import { PlumButton, WhiteButton } from "@jobe/ui/buttons";
import { useProfile } from "@jobe/ui/features/authentication";
import {
  useAddMessageToMessageStyleMutation,
  useListMessageStylesQuery,
} from "@jobe/ui/features/outreach";
import { TextBox, TextBoxProps } from "@jobe/ui/inputs";
import { LoadingIcon } from "@jobe/ui/loading";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { useGetOutreachMessageQuery } from "../apis";
import { getDefaultMessageStyle } from "../utils";
import { MessageStyleSelect, SelectOption } from "./MessageStyleSelect";

const MessageModal = styled(Modal)`
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 1rem;
  }
  .ant-modal-header {
    padding: 1rem;
    background: none;
  }
  .ant-modal-body {
    padding: 0;
  }
`;

const ContentContainer = styled.div`
  padding: 1rem;
`;

type OutreachMessageBoxProps = TextBoxProps & {
  isLoading: boolean;
};

const OutreachMessageBox = styled(
  ({ isLoading, ...props }: OutreachMessageBoxProps) => <TextBox {...props} />
)`
  min-height: 5.25rem !important;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 1rem;
  padding: 0.75rem 1rem;
  &:hover {
    border-color: ${({ theme }) => theme.colors.background.primary};
  }
  ${({ isLoading }) =>
    isLoading &&
    `
    filter: blur(0.25rem);
    pointer-events: none;
  `}
`;

const Footer = styled.div`
  text-align: left;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 0.5rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const CopyButton = styled(PlumButton)`
  flex-grow: 1;
  width: 100%;
`;

const LinkedInButton = styled(WhiteButton)`
  flex-grow: 1;
  width: 100%;
`;

const Subtitle = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  font-weight: 400;
`;

const MessageBoxContainer = styled.div`
  position: relative;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${({ theme }) => theme.colors.background.primary}80;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
`;

const RegenerateButton = styled.div`
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  color: ${({ theme }) => theme.colors.brand.accent};
  background-color: ${({ theme }) => theme.colors.background.secondary};
  border-radius: 0.5rem;
  cursor: pointer;
  width: 2rem;
  padding: 0.375rem;
  display: flex;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const ErrorText = styled(Paragraph)`
  padding: 0.5rem;
  color: ${({ theme }) => theme.colors.red[600]};
`;

interface MessageTemplateModalProps extends ComponentProps<typeof Modal> {
  isModalOpen: boolean;
  match: Match;
  onClose: () => void;
  specificationId: UUID;
  jobPostingTitle: string;
  preferredMessageStyleId?: string;
  setPreferredMessageStyleId: (id: string | undefined) => void;
}

export const OutreachModal = ({
  isModalOpen,
  onClose,
  match,
  specificationId,
  jobPostingTitle,
  preferredMessageStyleId,
  setPreferredMessageStyleId,
  ...props
}: MessageTemplateModalProps) => {
  const profile = useProfile();
  const { data: messageStylesResponse, refetch: refetchMessageStyles } =
    useListMessageStylesQuery();
  const [addMessageToMessageStyle] = useAddMessageToMessageStyleMutation();
  const [currentMessageStyle, setCurrentMessageStyle] = useState<
    SelectOption | undefined
  >(undefined);
  const {
    data: getOutreachMessageResponse,
    isFetching: outreachMessageFetching,
    isError,
    refetch: refetchOutreachMessage,
  } = useGetOutreachMessageQuery({
    match,
    messageStyleId: currentMessageStyle?.id || "",
  });
  const [outreachMessage, setOutreachMessage] = useState<string>();
  const [currentMessageCopied, setCurrentMessageCopied] = useState(false);

  const messageStyleMenu = (messageStylesResponse?.data ?? []).map(
    ({ name, id, description }) => ({
      label: name,
      value: name,
      id,
      description,
    })
  );

  useEffect(() => {
    if (!currentMessageStyle) {
      if (messageStylesResponse?.data) {
        const defaultMessageStyle = getDefaultMessageStyle(
          preferredMessageStyleId,
          messageStylesResponse.data
        );
        setCurrentMessageStyle(defaultMessageStyle);
      }
    }
  }, [messageStylesResponse]);

  useEffect(() => {
    if (!outreachMessageFetching && getOutreachMessageResponse) {
      setOutreachMessage(isError ? undefined : getOutreachMessageResponse.data);
      setCurrentMessageCopied(false);
    }
  }, [outreachMessageFetching, getOutreachMessageResponse, match, profile]);

  const handleCancel = () => {
    onClose();
  };

  const handleCopyMessage = () => {
    if (!outreachMessage) return;
    navigator.clipboard
      .writeText(outreachMessage)
      .then(() => setCurrentMessageCopied(true))
      .catch(() => {
        ErrorMessage("Failed to copy, please try again");
      });
    handleAddOutreachMessage(outreachMessage);
  };

  const handleAddOutreachMessage = async (message: string) => {
    if (currentMessageStyle) {
      await addMessageToMessageStyle({
        id: currentMessageStyle.id,
        message: { message, copiedAt: new Date().toISOString() },
      });
    }
  };

  const handleNavigateToLinkedIn = (linkedInId: string) => () => {
    window.open(
      `https://www.linkedin.com/in/${linkedInId}`,
      "_blank",
      "noopener"
    );
  };

  const handleOutreachMessageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setOutreachMessage(e.target.value);
    setCurrentMessageCopied(false);
  };

  const handleMessageStyleSelectChange = (value: SelectOption) => {
    setCurrentMessageCopied(false);
    setCurrentMessageStyle(value);
    refetchOutreachMessage();
    if (!isError && !outreachMessageFetching && getOutreachMessageResponse) {
      setOutreachMessage(getOutreachMessageResponse.data);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        isModalOpen &&
        (event.key === "ArrowLeft" || event.key === "ArrowRight")
      ) {
        event.stopPropagation();
      }
    };

    window.addEventListener("keydown", handleKeyDown, true);

    return () => {
      window.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [isModalOpen]);

  // the resume source includes where we mined it from (e.g. BrightData) followed by the LinkedIn id
  // resume source will always have format: "[A-Za-z]+: <LinkedInID>"
  const linkedInId = match.resume.source?.split(":")[1]?.trim() || null;

  return (
    <MessageModal
      title={
        <Spacing gap="3xs">
          <Heading level="h5">Compose the perfect message</Heading>
          <Subtitle small>
            When you edit and copy the message, Mili learns about your writing
            style to improve the next suggestion.
          </Subtitle>
        </Spacing>
      }
      open={isModalOpen}
      onCancel={handleCancel}
      footer={
        <Footer>
          <ButtonContainer>
            <CopyButton
              onClick={handleCopyMessage}
              disabled={
                outreachMessageFetching ||
                !outreachMessage ||
                currentMessageCopied
              }
            >
              <Square2StackIcon />
              {currentMessageCopied ? "Copied" : "Copy message"}
            </CopyButton>
          </ButtonContainer>
          {linkedInId && (
            <ButtonContainer>
              <LinkedInButton onClick={handleNavigateToLinkedIn(linkedInId)}>
                <LinkedInLogo />
                Open LinkedIn
              </LinkedInButton>
            </ButtonContainer>
          )}
        </Footer>
      }
      {...props}
    >
      <ContentContainer>
        <MessageBoxContainer>
          <OutreachMessageBox
            value={outreachMessage}
            placeholder={isError ? "Create an outreach message..." : undefined}
            onChange={handleOutreachMessageChange}
            autoSize={true}
            isLoading={outreachMessageFetching}
          />
          {outreachMessageFetching && (
            <LoadingOverlay>
              <Spacing
                gap="sm"
                direction="horizontal"
                items="center"
                justify="center"
              >
                <LoadingIcon />
                <Paragraph>Mili is crafting an outreach message...</Paragraph>
              </Spacing>
            </LoadingOverlay>
          )}
          <MessageStyleSelect
            isSearchable={false}
            options={messageStyleMenu}
            value={currentMessageStyle}
            onChange={handleMessageStyleSelectChange}
            refetchMessageStyles={refetchMessageStyles}
            preferredMessageStyleId={preferredMessageStyleId}
            setPreferredMessageStyleId={setPreferredMessageStyleId}
          />
          <RegenerateButton onClick={refetchOutreachMessage}>
            <ArrowPathIcon />
          </RegenerateButton>
        </MessageBoxContainer>
        {isError && (
          <ErrorText small>
            Apologies, something went wrong. Please try regenerating or create a
            message from scratch.
          </ErrorText>
        )}
      </ContentContainer>
    </MessageModal>
  );
};
