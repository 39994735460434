import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { AppNavbar } from "@jobe/ui/navbars";
import { PageLayout } from "./PageLayout";

const Page = styled(PageLayout)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
`;

/**
 * AppNavbarLayout provides a consistent layout for the app with a navbar
 */
export const AppNavbarLayout = () => {
  return (
    <Page>
      <AppNavbar />
      <Outlet />
    </Page>
  );
};
