import React from "react";
import { Outlet } from "react-router-dom";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { spacings } from "@unlikelyai-magic/ui/variables";
import { PageLayout } from "@jobe/ui/layouts";
import { LoadingPage } from "@jobe/ui/loading";
import { DiscoverMatchesNavbar, useMatches } from "../../matches";
import { useJobPosting } from "../hooks";

const Body = styled(Spacing)`
  flex: 1;
  height: 100%;
  gap: ${spacings.xl};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 75vh;
`;

/**
 * JobPostingPageLayout provides a consistent layout for the match pages. It renders
 * the navbar and sidebar.
 */
export const JobPostingPageLayout = () => {
  const { jobPosting } = useJobPosting();
  const { loading } = useMatches();

  if (!jobPosting?.specification) return null;

  return (
    <PageLayout>
      <DiscoverMatchesNavbar />
      <Container>
        {loading ? (
          <CenteredContainer>
            <LoadingPage message="Working on finding your next hire..." />
          </CenteredContainer>
        ) : (
          <Body>
            <Outlet />
          </Body>
        )}
      </Container>
    </PageLayout>
  );
};
