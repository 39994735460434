import React, { useState } from "react";
import styled from "@emotion/styled";
import { TrashIcon } from "@heroicons/react/24/outline";
import { HeartIcon } from "@heroicons/react/24/solid";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { Icon } from "@unlikelyai-magic/ui/icons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { SecondaryButton } from "@jobe/ui/buttons";
import { Card } from "@jobe/ui/cards";
import { Input } from "@jobe/ui/inputs";
import { ConfirmDeleteModal, useModal } from "@jobe/ui/modals";
import { Paragraph } from "@jobe/ui/typography";
import { useUpdateJobPostingMutation } from "../apis";

dayjs.extend(localizedFormat);

const CardText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.tertiary};
`;

const DeleteButton = styled(SecondaryButton)`
  &.ant-btn-icon-only {
    flex-shrink: 0;
    border-radius: 0.25rem;
    padding: 0.5rem;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    margin-top: -0.5rem;
    margin-right: -0.5rem;
  }
`;

const ItemCard = styled(Card)`
  height: 100%;

  &:hover {
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: ${({ theme }) => theme.shadows.md};

    ${DeleteButton} {
      opacity: 1;
    }
  }
`;

const LikedMatchesText = styled(Paragraph)`
  color: ${({ theme }) => theme.colors.text.tertiary};
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const LikeIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.brand.primary};
`;

const TitleInput = styled(Input)`
  font-size: 1rem;
  font-weight: 600;
  border: 1px solid white;
  width: fit-content;
  margin-left: -0.5rem;
  margin-top: -0.5rem;
  height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

type JobPostingsGridItemProps = {
  id: string;
  name: string;
  matchesLiked: number;
  createdAt: string;
  handleDelete: () => void;
  onLinkClick: () => void;
};

export const JobPostingsGridItem = ({
  id,
  name,
  matchesLiked,
  createdAt,
  handleDelete,
  onLinkClick,
}: JobPostingsGridItemProps) => {
  const { openModal } = useModal();
  const [openingName, setOpeningName] = useState(name);
  const [updateJobPosting] = useUpdateJobPostingMutation();

  const handleSaveName = async (
    event:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.FocusEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (!openingName) {
      setOpeningName(name);
      return;
    }
    if (openingName === name) return;
    await updateJobPosting({ id, name: openingName }).unwrap();
    event.currentTarget?.blur();
  };

  const showConfirmDeleteModal = (event: React.MouseEvent) => {
    event.preventDefault();
    openModal(
      <ConfirmDeleteModal
        description={`This will permanently delete your "${name}" job opening${
          matchesLiked > 0 ? ` and ${matchesLiked} liked candidates.` : "."
        }`}
        handleConfirm={handleDelete}
      />
    );
  };

  const handleInputFocus = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleCardClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
    await handleSaveName(event);
    onLinkClick();
  };

  return (
    <ItemCard onClick={handleCardClick}>
      <Card.Header>
        <Spacing
          direction="horizontal"
          items="flex-start"
          justify="space-between"
        >
          <Spacing gap="3xs">
            <TitleInput
              value={openingName}
              onChange={(event) => setOpeningName(event.target.value)}
              placeholder="Enter opening name..."
              onBlur={handleSaveName}
              onPressEnter={handleSaveName}
              onClick={handleInputFocus}
            />
            <CardText small>Created {dayjs(createdAt).format("L")}</CardText>
          </Spacing>
          <DeleteButton icon={<TrashIcon />} onClick={showConfirmDeleteModal} />
        </Spacing>
      </Card.Header>
      <Card.Body>
        {!matchesLiked ? (
          <CardText small>No candidates liked yet</CardText>
        ) : (
          <LikedMatchesText small>
            <LikeIcon icon={HeartIcon} />
            {matchesLiked} liked candidate{matchesLiked > 1 ? "s" : ""}
          </LikedMatchesText>
        )}
      </Card.Body>
    </ItemCard>
  );
};
