import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { HandThumbUpIcon } from "@heroicons/react/24/outline";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { WaitlistStatus } from "@jobe/data-access/api-types";
import {
  useGetUserProfileQuery,
  useUpdateUserProfileMutation,
} from "@jobe/ui/features/users";
import { LoadingPage } from "@jobe/ui/loading";
import { theme } from "@jobe/ui/theme";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { WaitlistForm, WaitlistFormData } from "../components";

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 1rem;

  @media (min-width: ${theme.screenBreakpoints.md}) {
    align-items: center;
    height: 100vh;
  }
`;

const Card = styled.div`
  display: flex;
  max-width: 32rem;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border-radius: 1.5rem;
  background: ${colors.white};
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);

  & > ${Spacing} {
    width: 100%;
  }
`;

const PageTitle = styled(Heading)`
  text-align: center;
`;

const ThumbsUp = styled(HandThumbUpIcon)`
  width: 3.125rem;
  height: 3.125rem;
`;

const PendingCard = styled(Spacing)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 1.5rem;
  border-radius: 1.5rem;
  background: ${colors.gray["800"]};
  color: ${colors.white};
  max-width: 22rem;
  text-align: center;
`;

export const WaitlistSignupPage = () => {
  const navigate = useNavigate();
  const { data, isUninitialized, error, refetch } = useGetUserProfileQuery();
  const [updateUser, { isLoading }] = useUpdateUserProfileMutation();
  const userProfile = data?.data;

  useEffect(() => {
    // If the waitlist status is approved, redirect to onboarding
    if (userProfile?.waitlistStatus !== WaitlistStatus.Approved) return;
    navigate("/");
  }, [data]);

  useEffect(() => {
    // Poll for changes in the waitlist status every 5 seconds
    const interval = setInterval(() => {
      refetch();
    }, 5000);
    return () => clearInterval(interval);
  }, [refetch]);

  if (isUninitialized || !userProfile) {
    return <LoadingPage />;
  }

  if (error) {
    return <div>Something went wrong</div>;
  }

  const handleSubmit = async (formData: WaitlistFormData) => {
    await updateUser({
      ...formData,
      rolesOfInterest: formData.rolesOfInterest.map((role) => role.value),
      locationsOfInterest: formData.locationsOfInterest.map(
        (location) => location.value
      ),
    });
    refetch();
  };

  return (
    <Container>
      {userProfile.waitlistStatus === WaitlistStatus.OnWaitlist ? (
        <PendingCard gap="xl">
          <ThumbsUp />
          <PageTitle level="h2">
            {userProfile.firstName}, you are on the waitlist!
          </PageTitle>
          <Paragraph>
            We will send an email to <strong>{userProfile.email}</strong> when
            we are ready.
          </Paragraph>
          <Paragraph>We look forward to seeing you soon!</Paragraph>
        </PendingCard>
      ) : (
        <Card>
          <Spacing gap="xl">
            <PageTitle level="h2">
              <Spacing>
                <span role="img" aria-label="wave">
                  👋
                </span>
                {userProfile.firstName
                  ? `Join the waitlist, ${userProfile.firstName}!`
                  : "Join the waitlist!"}
              </Spacing>
            </PageTitle>
            <WaitlistForm
              submitting={isLoading}
              initialFormData={{
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
              }}
              onSubmit={handleSubmit}
            />
          </Spacing>
        </Card>
      )}
    </Container>
  );
};
