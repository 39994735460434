import React, { forwardRef, useState } from "react";
import { usePopper } from "react-popper";
import styled from "@emotion/styled";
import { Popover } from "@headlessui/react";
import { SecondaryButton } from "@unlikelyai-magic/ui/buttons";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { colors } from "@unlikelyai-magic/ui/variables";
import { JobSpecificationRequirement } from "@jobe/data-access/api-types";
import { SecondaryButtonProps } from "@jobe/ui/buttons";
import { StrictModeDroppable } from "@jobe/ui/layouts";
import { Heading, Paragraph } from "@jobe/ui/typography";
import { RequirementInput } from "./RequirementInput";
import { RequirementListItem } from "./RequirementListItem";

const StyledPopover = styled(Popover)`
  position: relative;
  display: flex;
  justify-content: flex-end;
`;

const Panel = styled(Popover.Panel)`
  z-index: 10;
  width: 30rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 7.5rem;
`;

const TitleRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const EmptyListItem = styled.li`
  flex: 1;
  text-align: center;

  ${Paragraph} {
    width: 100%;
  }
`;

type SplitListProps = {
  title: string;
  droppableId: string;
  requirements: JobSpecificationRequirement[];
  onChange: (value: JobSpecificationRequirement[]) => void;
  onCreate: (value: JobSpecificationRequirement) => void;
  disabled?: boolean;
};

const AddButton = forwardRef<HTMLButtonElement, SecondaryButtonProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  (props, ref) => <SecondaryButton {...props} />
);

export const SplitList = ({
  title,
  droppableId,
  requirements,
  onChange,
  onCreate,
  disabled,
}: SplitListProps) => {
  const [referenceElement, setReferenceElement] = useState<any>();
  const [popperElement, setPopperElement] = useState<any>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  return (
    <Spacing gap="xs">
      <StyledPopover>
        <TitleRow>
          <Heading level="h5">{title}</Heading>
          <div ref={setReferenceElement}>
            <Popover.Button as={AddButton}>Add</Popover.Button>
          </div>
        </TitleRow>
        <Panel
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {({ close }) => (
            <RequirementInput
              autoFocus
              placement="bottom-start"
              value={requirements}
              onCreate={(value) => {
                onCreate(value);
                close();
              }}
            />
          )}
        </Panel>
      </StyledPopover>
      <StrictModeDroppable droppableId={droppableId} isDropDisabled={disabled}>
        {(provided) => (
          <List {...provided.droppableProps} ref={provided.innerRef}>
            {requirements.length === 0 && (
              <EmptyListItem>
                <Paragraph color={colors.gray["400"]}>
                  Add a requirement
                </Paragraph>
              </EmptyListItem>
            )}
            {requirements.map((entity, index) => (
              <RequirementListItem
                key={entity.id}
                mode="split"
                value={entity}
                index={index}
                disabled={disabled}
                onRemove={() =>
                  onChange(requirements.filter((item) => item.id !== entity.id))
                }
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </StrictModeDroppable>
    </Spacing>
  );
};
