import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import {
  ChatSecondaryButton,
  MessageAuthor,
  TextResponse,
  useChat,
} from "@jobe/ui/features/chat";
import { useExtractRequirementsMutation } from "@jobe/ui/features/shared";
import { useCreateJobOpeningChat } from "../hooks";
import { JobRequirementsWidget } from "./JobRequirementsWidget";
import { SelectExperienceWidget } from "./SelectExperienceWidget";

const Container = styled(Spacing)`
  width: 100%;
`;

export const JobSpecificationWidget = () => {
  const { formData, setFormData } = useCreateJobOpeningChat();
  const { pushMessage } = useChat();
  const [extractRequirements, { isLoading }] = useExtractRequirementsMutation();

  const handleSubmit = async (jobSpecification: string) => {
    try {
      const { data } = await extractRequirements({
        jobSpecText: jobSpecification,
      }).unwrap();

      pushMessage({
        author: MessageAuthor.User,
        text: jobSpecification,
      });

      // Add the extracted requirements to the form data
      const requirements = data.requirements.map((requirement) => {
        return {
          id: requirement.id,
          text: requirement.text,
          mustHave: true,
        };
      });
      setFormData((prev) => ({
        ...prev,
        requirementSpecTextId: data.requirementSpecTextId,
        requirements: [...prev.requirements, ...requirements],
      }));

      const experienceMissing =
        !formData.experience &&
        !data.requirements.find((requirement) =>
          requirement.text.match("years")
        );
      if (experienceMissing) {
        pushMessage({
          author: MessageAuthor.Bot,
          text: "One more thing. What level of experience are you looking for?",
          widget: <SelectExperienceWidget />,
        });
      } else {
        pushMessage({
          author: MessageAuthor.Bot,
          text:
            data.requirements.length > 0
              ? `I pulled out ${data.requirements.length} requirements from the job specification that I can use to match you with the best candidates.`
              : "That job specification doesn't look quite right. Let's create the requirements manually.",
          widget: <JobRequirementsWidget />,
        });
      }
    } catch (err) {
      pushMessage({
        author: MessageAuthor.Bot,
        text: "I'm sorry, something went wrong. I feel confident I can still help you find the right candidates. What should we do next?",
        widget: (
          <Spacing direction="horizontal">
            <ChatSecondaryButton onClick={() => handleSubmit(jobSpecification)}>
              Retry
            </ChatSecondaryButton>
            <ChatSecondaryButton
              onClick={() => {
                pushMessage({
                  author: MessageAuthor.Bot,
                  text: "Great, tell me about your job requirements",
                  widget: <JobRequirementsWidget />,
                });
              }}
            >
              Continue
            </ChatSecondaryButton>
          </Spacing>
        ),
      });
    }
  };

  if (formData.requirementSpecTextId) {
    return null;
  }

  return (
    <Container>
      <TextResponse
        placeholder="Paste your job specification..."
        cancelText="I don't have a specification"
        submitText="Generate requirements"
        onSubmit={handleSubmit}
        submitting={isLoading}
      />
    </Container>
  );
};
