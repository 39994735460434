import { ComponentProps } from "react";
import styled from "@emotion/styled";
import { colors } from "@unlikelyai-magic/ui/variables";

type TabsProps = ComponentProps<"nav">;

export const Tabs = styled((props: TabsProps) => {
  return <nav {...props} />;
})`
  display: flex;
  border-bottom: 1px solid ${colors.gray["200"]};
  gap: 1.5rem;
`;
