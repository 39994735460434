import React from "react";
import styled from "@emotion/styled";
import { Spacing } from "@unlikelyai-magic/ui/layouts";
import { spacings } from "@unlikelyai-magic/ui/variables";
import { PlumButton } from "@jobe/ui/buttons";
import { PageLayout } from "@jobe/ui/layouts";
import { StarsNoBackground } from "@jobe/ui/mili-logos";
import { useSlideOver } from "@jobe/ui/slide-overs";
import { Heading, Paragraph } from "@jobe/ui/typography";
import {
  NoMatches,
  useJobPosting,
  useReplaceJobPostingSpecificationMutation,
} from "../../job-postings";
import { EditRequirementsSlideOver } from "../../job-requirements";
import { MatchAvatarList, MatchCard } from "../components";
import { useMatches } from "../hooks";

const CardHeader = styled(Spacing)`
  max-width: 64rem;
  width: 100%;
`;

const MatchProfileContainer = styled(Spacing)`
  display: flex;
  padding: ${spacings.lg} ${spacings.lg} 5rem;
  width: 100%;
  align-items: center;
`;

const EmptyState = styled.div`
  text-align: center;
`;

export const DiscoverMatchesPage = () => {
  const { openSlideOver } = useSlideOver();
  const { matches, fetching, allMatchesFound } = useMatches();
  const { jobPosting } = useJobPosting();
  const [replaceJobPostingSpecification] =
    useReplaceJobPostingSpecificationMutation();

  const showRequirements = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (!jobPosting) return;
    openSlideOver(
      <EditRequirementsSlideOver
        jobPosting={jobPosting}
        onSubmit={async (requirements) => {
          await replaceJobPostingSpecification({
            name: jobPosting.name,
            jobPostingId: jobPosting.id,
            requirements,
          }).unwrap();
        }}
      />
    );
    event.currentTarget.blur();
  };

  if (!jobPosting) {
    return (
      <EmptyState>
        <Spacing gap="xl">
          <Heading level="h3">Oops! Something went wrong.</Heading>
          <Paragraph>
            We couldn't load your job opening. Please try again later.
          </Paragraph>
        </Spacing>
      </EmptyState>
    );
  }

  if (!fetching && !matches?.length && allMatchesFound) {
    return <NoMatches />;
  }

  return (
    <PageLayout>
      <MatchProfileContainer gap="lg">
        <CardHeader
          direction="horizontal"
          items="center"
          justify="space-between"
        >
          <MatchAvatarList isLoading={fetching} />
          <PlumButton icon={<StarsNoBackground />} onClick={showRequirements}>
            Edit requirements
          </PlumButton>
        </CardHeader>
        <MatchCard />
      </MatchProfileContainer>
    </PageLayout>
  );
};
