import { SerializedStyles, css } from "@emotion/react";
import { fontSizes, lineHeights } from "@unlikelyai-magic/ui/variables";
import { ButtonSize } from "../types/buttonSize.types";

const baseText = css`
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-shadow: none;
  letter-spacing: 0.5px;
`;

const xsText = css`
  ${baseText};
  font-size: ${fontSizes.xs};
  line-height: ${lineHeights.xs};
`;

const smText = css`
  ${baseText};
  font-size: ${fontSizes.sm};
  line-height: ${lineHeights.sm};
`;

export const buttonTextStyles: Record<ButtonSize, SerializedStyles> = {
  xs: xsText,
  sm: smText,
  md: smText,
  lg: smText,
  xl: smText,
};
