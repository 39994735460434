import React, { ComponentProps } from "react";
import styled from "@emotion/styled";

type StarsWithBackgroundProps = ComponentProps<"svg"> & {
  backgroundColor: string;
};

export const StarsWithBackground = styled(
  ({ backgroundColor, ...props }: StarsWithBackgroundProps) => {
    return (
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
        {...props}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.60163C0 1.16479 1.16479 0 2.60163 0H29.3984C30.8352 0 32 1.16479 32 2.60163V29.3984C32 30.8352 30.8352 32 29.3984 32H2.60163C1.16479 32 0 30.8352 0 29.3984V2.60163Z"
          fill={backgroundColor}
        />
        <path
          d="M5.63115 20.367C11.0672 20.367 15.4741 15.9602 15.4741 10.5241C15.4741 15.9602 19.8809 20.367 25.317 20.367C19.8809 20.367 15.4741 24.7738 15.4741 30.2099C15.4741 24.7738 11.0672 20.367 5.63115 20.367Z"
          fill="currentColor"
        />
        <path
          d="M2.20992 11.9247C5.14925 10.9972 6.78097 7.86502 5.85448 4.92867C6.78097 7.86502 9.91483 9.49357 12.8542 8.56614C9.91483 9.49357 8.28311 12.6258 9.2096 15.5621C8.28311 12.6258 5.14925 10.9972 2.20992 11.9247Z"
          fill="currentColor"
        />
        <path
          d="M16.1999 6.68612C19.9621 7.82981 23.9382 5.71017 25.0807 1.95177C23.9382 5.71017 26.0619 9.68409 29.8241 10.8278C26.0619 9.68409 22.0858 11.8037 20.9433 15.5621C22.0858 11.8037 19.9621 7.82981 16.1999 6.68612Z"
          fill="currentColor"
        />
      </svg>
    );
  }
)``;
