import styled from "@emotion/styled";
import { Button } from "antd";
import { colors } from "@unlikelyai-magic/ui/variables";
import { buttonStyles } from "../../styles";
import { ButtonProps } from "../../types";

export type SecondaryButtonProps = ButtonProps;

export const SecondaryButton = styled(
  ({ size, ...props }: SecondaryButtonProps) => (
    <Button {...props} size="middle" type="default" />
  )
)`
  ${({ size = "md" }) => buttonStyles[size]};
  color: ${colors.gray["800"]};
  background: ${colors.white};
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015),
    inset 0 0 0 calc(1px) ${colors.gray["300"]} !important;
  --antd-wave-shadow-color: ${colors.gray["300"]};

  &:hover,
  &:focus,
  &.ant-btn-loading {
    color: ${colors.gray["800"]};
    background: ${colors.gray["50"]};
  }

  &:disabled,
  &:disabled:hover {
    color: ${colors.gray["400"]};
    background: ${colors.gray["50"]};
  }
`;
